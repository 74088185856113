import { useState, useEffect, useCallback, useMemo, useRef, HTMLAttributes } from 'react'
import {
  Popconfirm,
  Form,
  Table,
  Tooltip,
  message,
} from 'antd'
import { IconCheck, IconPencil, IconX } from '@tabler/icons-react'
import './styles.scss'
import EditableCell from '../../atoms/editable-table-cell'
import {
  getItemCategories,
  addItemCategory,
  updateItemCategory,
} from '../../../services/api/item.api'
import Card from '../../atoms/card'
import EditableImageWithText from '../../molecules/editable-image-with-text'
import ContentWithAction from '../../templates/content-with-action'
import { isImageTypePdfCompatible, getImageTypeNotSupportedMessage } from '../../../services/helpers/images'
import IconButton from '../../atoms/icon-button'
import { Category } from '../../../types/category'

const CategoriesManagement = () => {
  const [activeItem, setActiveItem] = useState<Category & { key?: number, isNew?: boolean }>({})
  const [itemCategories, setItemCategories] = useState([])
  const [file, setFile] = useState(null)
  const [form] = Form.useForm()
  const newCategoryRowRef = useRef(null)

  useEffect(() => {
    getItemCategories().then(items => setItemCategories(items))
  }, [])

  useEffect(() => {
    if (itemCategories[itemCategories.length - 1]?.isNew) {
      newCategoryRowRef.current.scrollIntoView(true)
    }
  }, [itemCategories])

  const refreshItemCategories = useCallback(async () => {
    const newItemCategories = await getItemCategories()
    setItemCategories(newItemCategories)
  }, [])

  const isItemEdited = useCallback(
    itemId => itemId === activeItem.id,
    [activeItem]
  )

  const edit = useCallback(
    (item) => {
      form.setFieldsValue({
        name: '',
        ...item,
      })
      setActiveItem(item)
    },
    [form]
  )

  const cancel = useCallback(() => {
    if (!activeItem.id) {
      setItemCategories(itemCategories.slice(0, -1))
    }
    setActiveItem({})
    setFile(null)
  }, [activeItem, itemCategories])

  const addItem = useCallback(
    async (itemData) => {
      const formData = new FormData()
      formData.append('name', itemData.name)
      formData.append('image', file)

      const newItems = [...itemCategories]
      const newCategoryRes = await addItemCategory(formData)

      if (newCategoryRes.hasErrors) {
        throw new Error(`Failed to create the new category. ${newCategoryRes.message}`)
      }

      newItems[newItems.length - 1] = newCategoryRes

      setItemCategories(newItems)
      message.success('The category has been added successfully')
    },
    [file, itemCategories]
  )

  const updateItem = useCallback(
    async (itemData) => {
      const formData = new FormData()
      const originalCategory = itemCategories.find(
        category => category.id === itemData.id
      )
      if (originalCategory.name !== itemData.name) {
        formData.append('name', itemData.name)
      }

      if (file) {
        formData.append('image', file)
      }

      const updatedItemCategoryRes = await updateItemCategory({ id: itemData.id, formData, })
      if (updatedItemCategoryRes.hasErrors) {
        throw new Error('Could not update the category')
      }

      const newItemCategoriess = [...itemCategories]
      const itemIndex = newItemCategoriess.findIndex(
        item => itemData.id === item.id
      )
      newItemCategoriess.splice(itemIndex, 1, updatedItemCategoryRes)
      setItemCategories(newItemCategoriess)
      message.success('The category has been updated successfully')
    },
    [file, itemCategories]
  )

  const save = useCallback(
    async () => {
      try {
        const tableRowData = await form.validateFields()
        const itemData = {
          ...tableRowData,
          id: activeItem.id,
        }
        if (!activeItem.image) {
          throw new Error('Please select an image for the category')
        }

        itemData.id
          ? await updateItem(itemData)
          : await addItem(itemData)

        setActiveItem({})
        await refreshItemCategories()
        setFile(null)
      } catch (e) {
        message.error(e.message)
      }
    },
    [form, activeItem, updateItem, addItem, refreshItemCategories]
  )

  const editableFields = useMemo(() => {
    return {
      name: (
        <EditableImageWithText
          isImageEditable
          isTextEditable
          imageFile={file}
          currentImage={activeItem.image}
          beforeUpload={(imageFile) => {
            if (!isImageTypePdfCompatible(imageFile.type)) {
              message.error(getImageTypeNotSupportedMessage(imageFile.name));
              return false;
            }

            setActiveItem({
              ...activeItem,
              image: URL.createObjectURL(imageFile),
            })
            setFile(imageFile)
            return false
          }}
          title={{
            formFieldTitle: 'Category name',
            formPropertyName: 'name',
            onInputChange: (e) => {
              setActiveItem({
                ...activeItem,
                name: e.target.value,
              })
            },
          }}
        />
      ),
    }
  }, [activeItem, file])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'Category name',
        key: 'name',
        dataIndex: 'name',
        width: '40%',
        editable: true,
        render: (text, record) => (
          <EditableImageWithText
            currentImage={record.image}
            title={{ value: text }}
          />
        ),
      },
      {
        title: 'Actions',
        key: 'operation',
        dataIndex: 'operation',
        width: '10%',
        render: (_, record) => {
          return (
            <div className="categories-management__action-buttons">
              {isItemEdited(record.id) ? (
                <>
                  <Form.Item>
                    <Tooltip title="Cancel">
                      <Popconfirm
                        title="Cancel editing?"
                        onConfirm={cancel}
                      >
                        <IconButton
                          shape="circle"
                          icon={IconX}
                        />
                      </Popconfirm>
                    </Tooltip>
                  </Form.Item>

                  <Form.Item>
                    <Tooltip title="Save">
                      <IconButton
                        htmlType="submit"
                        shape="circle"
                        type="primary"
                        icon={IconCheck}
                      />
                    </Tooltip>
                  </Form.Item>
                </>
              ) : (
                <Tooltip title="Edit">
                  <IconButton
                    ghost={false}
                    shape="circle"
                    icon={IconPencil}
                    disabled={!!(activeItem.id || activeItem.id === null)}
                    onClick={() => edit(record)}
                  />
                </Tooltip>
              )}
            </div>
          )
        },
      },
    ]

    return columns.map((column) => {
      if (!column.editable) {
        return column
      }

      return {
        ...column,
        onCell: (record) => {
          return {
            record,
            dataIndex: column.dataIndex,
            title: column.title,
            isInEditMode: record.id === activeItem.id,
            cellContent: editableFields[column.key],
          }
        },
      }
    })
  }, [activeItem, editableFields, cancel, edit, isItemEdited])

  return (
    <div className="categories-management">
      <Card>
        <ContentWithAction
          actionButtonLabel="Add category"
          actionButtonProps={{
            disabled: Object.keys(activeItem).length !== 0,
            onClick: async () => {
              const newItem = {
                key: itemCategories.length,
                isNew: true,
              }
              form.setFieldsValue({
                name: '',
                image: '',
                ...newItem,
              })
              setActiveItem(newItem)
              setItemCategories([...itemCategories, newItem])
            }
          }}
        >
          <Form
            form={form}
            onFinish={save}
            initialValues={Object.keys(activeItem).length ? activeItem : null}
          >
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              onRow={(_, index) => {
                if (index === itemCategories.length - 1 && activeItem.isNew) {
                  return {
                    ref: newCategoryRowRef,
                  } as HTMLAttributes<unknown>
                }
              }}
              bordered
              dataSource={itemCategories}
              columns={tableColumns}
              rowClassName="categories-management__editable-row"
              rowKey={record => record.id || itemCategories.length}
              pagination={false}
            />
          </Form>
        </ContentWithAction>
      </Card>
    </div>
  )
}

export default CategoriesManagement
