import { FC, PropsWithChildren, ReactNode, TdHTMLAttributes } from 'react'
import { Input } from 'antd'
import FormItem from '../ant-form-item'

type EditableCellProps = TdHTMLAttributes<HTMLTableCellElement> & PropsWithChildren<{
  cellContent: ReactNode
  isInEditMode: boolean
  dataIndex: string
  title: string
}>
const EditableCell: FC<EditableCellProps> = ({
  cellContent,
  isInEditMode,
  dataIndex,
  title,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {isInEditMode
        ? cellContent || (
          <FormItem
            propertyName={dataIndex}
            fieldTitle={title}
          >
            <Input placeholder={title} />
          </FormItem>
        )
        : children}
    </td>
  )
}

export default EditableCell
