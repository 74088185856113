import { FC } from 'react'
import './styles.scss'
import Table, { TableProps } from '../../atoms/table'

const ProductTable: FC<TableProps<unknown>> = props => (
  <div className="product-table">
    <Table {...props} />
  </div>
)

export default ProductTable
