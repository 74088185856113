import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { CombinedReducers } from '../redux/stores/reducers'

const GuestRoute = () => {
  const { pathname } = useLocation()
  const isLoggedIn = useSelector<CombinedReducers, boolean>(state => state.user.isLoggedIn)

  if (isLoggedIn) {
    return (
      <Navigate
        to="/"
        state={{ from: pathname }}
      />
    )
  }

  return <Outlet />
}

export default GuestRoute
