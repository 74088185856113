/* eslint-disable default-param-last */
import { ViewModes } from '../../../constants/app.constants'
import { SET_VIEW_MODE, RESET_STATE } from './app.types'

export type AppReducerState = {
  viewMode: ViewModes
}
const initialState: AppReducerState = {
  viewMode: ViewModes.noProgramme,
}

export default function appReducer(state = initialState, action): AppReducerState {
  switch (action.type) {
    case SET_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload,
      }
    case RESET_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
