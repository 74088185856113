import { message } from 'antd'
import { userStatuses } from '../../../constants/user.constants'
import { getFieldworkers } from '../../../services/api/fieldworker.api'
import { getProgrammeManagers } from '../../../services/api/manager.api'
import { LabelValue } from '../../../types/generic'
import { getFullName } from '../../../services/helpers/helpers'

export const getFieldworkerPickerOptions = async (
  searchText: string,
  activeProgrammeId: string
): Promise<LabelValue[]> => {
  const fieldworkersRes = await getFieldworkers(activeProgrammeId, {
    status: userStatuses.active.key,
    search: searchText,
  })
  if (fieldworkersRes.hasErrors) {
    message.error('Could not get the fieldworkers')
    return
  }
  return fieldworkersRes.fieldWorkers.map(({ id, firstName, lastName }) => {
    return {
      label: getFullName({ firstName, lastName }),
      value: id,
    }
  })
}

export const getProgrammeManagerPickerOptions: (searchText: string) => Promise<LabelValue[]> = async (searchText: string) => {
  const PMsRes = await getProgrammeManagers({
    name: searchText
  })
  if (PMsRes.hasErrors) {
    message.error('Could not get the programme managers')
    return
  }

  return PMsRes.map(({ id, firstName, lastName }) => {
    return {
      label: getFullName({ firstName, lastName }),
      value: id,
    }
  })
}
