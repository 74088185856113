import { useState, useEffect, FC } from 'react'
import './styles.scss'
import InfoBox, { InfoBoxProps } from '../../atoms/info-box'
import { useSelector } from 'react-redux'
import DocumentsModule from '../../atoms/documents-module'
import { getMerchantFile } from '../../../services/api/merchant.api'
import { saveAs } from 'file-saver'
import { Descriptions } from 'antd'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'
import { Merchant } from '../../../types/user'

const MerchantBusinessDetails: FC<{
  merchantDetails: Merchant,
  infoBoxProps: InfoBoxProps,
}> = ({
  merchantDetails,
  infoBoxProps,
}) => {
  const [businessName, setBusinessName] = useState('')
  const [owner, setOwner] = useState('')
  const [address, setAddress] = useState('')
  const [postcode, setPostcode] = useState('')
  const [iban, setIban] = useState('')
  const [swiftCode, setSwiftCode] = useState('')
  const [sortCode, setSortCode] = useState('')
  const [city, setCity] = useState('')
  const [bankName, setBankName] = useState('')
  const [registrationNumber, setRegistrationNumber] = useState('')
  const [bankAccount, setBankAccount] = useState('')
  const [mobileWalletAccount, setMobileWalletAccount] = useState('')
  const [files, setFiles] = useState<string[]>([])
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  useEffect(() => {
    if (merchantDetails) {
      const {
        businessDetails,
        owner,
        name,
        address,
        city,
        postcode,
        iban,
        swiftCode,
        sortCode,
        bankName,
        bankAccount,
        files,
        mobileWalletAccount,
      } = merchantDetails
      setBusinessName(name)
      setOwner(owner)
      setAddress(address)
      setCity(city)
      setPostcode(postcode)
      setIban(iban)
      setSwiftCode(swiftCode)
      setSortCode(sortCode)
      setBankName(bankName)
      setBankAccount(bankAccount)
      setRegistrationNumber(businessDetails)
      setMobileWalletAccount(mobileWalletAccount)
      setFiles(files)
    }
  }, [merchantDetails])

  const downloadFile = async (fileName: string) => {
    const res = await getMerchantFile(
      activeProgramme.id,
      merchantDetails.id,
      fileName
    )
    saveAs(res, fileName)
  }

  return (
    <div className="merchant-business-details">
      <div className="first-column">
        <Descriptions
          column={1}
          layout="vertical"
          colon={null}
        >
          <Descriptions.Item label="Business Name">
            {businessName || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Owner">
            {owner || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {address || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="City">
            {city || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Postcode">
            {postcode || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Registration Number">
            {registrationNumber || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Bank Account">
            {bankAccount || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Bank Name">
            {bankName || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="IBAN">
            {iban || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Swift Code/BIC">
            {swiftCode || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Sort Code">
            {sortCode || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile Wallet Account">
            {mobileWalletAccount || '–'}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="second-column">
        <div className="info-box-container">
          <InfoBox {...infoBoxProps} />

          <InfoBox className="info-box--documents">
            <DocumentsModule
              files={files}
              downloadFile={downloadFile}
            />
          </InfoBox>
        </div>
      </div>
    </div>
  )
}

export default MerchantBusinessDetails
