import { IconHelp } from '@tabler/icons-react'
import { Typography } from 'antd'
import Icon from '../../../atoms/icon'

const { Title, Text, Link } = Typography

const SupportSection = () => (
  <section className="help-section">
    <Icon
      component={IconHelp}
      componentProps={{ strokeWidth: 1.5 }}
    />
    <div>
      <Title level={4}>Need help?</Title>
      <Text>
        Please email our support at{' '}
        {
          <Link
            href="mailto:support@f4id.org"
            title="support@f4id.org"
          >
            {' '}
            support@f4id.org{' '}
          </Link>
        }{' '}
      </Text>
    </div>
  </section>
)

export default SupportSection
