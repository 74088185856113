import { FC, useEffect, useState } from 'react'
import StrengthCriterion from './strength-criterion.component'

const PasswordStrengthComponent: FC<{
  onChange: (validity: boolean) => void,
  value: string
}> = ({ onChange = () => {}, value }) => {
  const [lengthCheck, setLengthCheck] = useState(false)
  const [specialCharCheck, setSpecialCharCheck] = useState(false)
  const [numberCheck, setNumberCheck] = useState(false)
  const [upperCharCheck, setUpperCharCheck] = useState(false)
  const [lowerCharCheck, setLowerCharCheck] = useState(false)

  useEffect(() => {
    const validity
      = lengthCheck
      && specialCharCheck
      && numberCheck
      && upperCharCheck
      && lowerCharCheck
    onChange(validity)
  }, [
    lengthCheck,
    specialCharCheck,
    numberCheck,
    upperCharCheck,
    lowerCharCheck,
    onChange,
  ])

  return (
    <div className="password-strength">
      <StrengthCriterion
        onChange={setLengthCheck}
        type="length"
        value={value}
      />
      <StrengthCriterion
        onChange={setSpecialCharCheck}
        type="specialChar"
        value={value}
      />
      <StrengthCriterion
        onChange={setNumberCheck}
        type="number"
        value={value}
      />
      <StrengthCriterion
        onChange={setUpperCharCheck}
        type="upperChar"
        value={value}
      />
      <StrengthCriterion
        onChange={setLowerCharCheck}
        type="lowerChar"
        value={value}
      />
    </div>
  )
}

export default PasswordStrengthComponent
