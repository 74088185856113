import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CombinedReducers } from '../../../../redux/stores/reducers'
import { BaseUser } from '../../../../types/user'
import { Button } from 'antd'
import { logout } from '../../../../services/api/auth.api'
import { getFullName } from '../../../../services/helpers/helpers'

const UserDetails = () => {
  const [name, setName] = useState('')
  const userDetails = useSelector<CombinedReducers, BaseUser>(state => state.user.userDetails)

  useEffect(() => {
    if (userDetails) {
      const userName = getFullName(userDetails)
      if (userName !== name) {
        setName(userName)
      }
    }
  }, [userDetails, name])

  return (
    <div className="user">
      <div className="profile-link">
        <Link to="/profile">
          <h2>{name}</h2>
        </Link>
      </div>
      <Button
        className="log-out-button"
        type="ghost"
        onClick={() => logout()}
      >
        Log out
      </Button>
    </div>
  )
}

export default UserDetails
