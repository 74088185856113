export type ProgrammeDetailsFieldsConfig = {
  programmeName: boolean
  programmeManager: boolean
  startDate: boolean
  endDate: boolean
  currency: boolean
  programmeValue: boolean
  isOnline: boolean
  isBiometricEnabled: boolean
  isEnrollmentEnabled: boolean
  isInvoicingEnabled: boolean
  countryAndTimezone: boolean
}

export const defaultFieldsConfig: ProgrammeDetailsFieldsConfig = {
  programmeName: false,
  programmeManager: false,
  startDate: false,
  endDate: false,
  currency: false,
  programmeValue: false,
  isOnline: false,
  isBiometricEnabled: false,
  isEnrollmentEnabled: false,
  isInvoicingEnabled: false,
  countryAndTimezone: false
}

export const activeFieldsConfig: ProgrammeDetailsFieldsConfig = {
  ...defaultFieldsConfig,
  isInvoicingEnabled: true,
  endDate: true,
  programmeManager: true,
  programmeValue: true,
  countryAndTimezone: true
}

export const scheduledFieldsConfig: ProgrammeDetailsFieldsConfig = {
  ...defaultFieldsConfig,
  programmeName: true,
  programmeManager: true,
  startDate: true,
  endDate: true,
  programmeValue: true,
  isOnline: true,
  isBiometricEnabled: true,
  isEnrollmentEnabled: true,
  isInvoicingEnabled: true,
  countryAndTimezone: true
}

export const draftFieldsConfig: ProgrammeDetailsFieldsConfig = {
  programmeName: true,
  programmeManager: true,
  startDate: true,
  endDate: true,
  currency: true,
  programmeValue: true,
  isOnline: true,
  isBiometricEnabled: true,
  isEnrollmentEnabled: true,
  isInvoicingEnabled: true,
  countryAndTimezone: true
}

export const archivedFieldsConfig: ProgrammeDetailsFieldsConfig = {
  ...defaultFieldsConfig,
}
