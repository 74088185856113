import { FC, ReactNode } from 'react'
import { Tabs as AntTabs } from 'antd'
import './styles.scss'

const Tabs: FC<{
  tabsContent: {
    title: string
    component: ReactNode
  }[]
}> = ({ tabsContent }) => (
  <div className="tabs-wrapper">
    <AntTabs
      destroyInactiveTabPane
      items={tabsContent.map(tabContent => ({
        key: (tabContent.title).replaceAll(' ', '_'),
        label: tabContent.title,
        className: 'tabs-content',
        children: tabContent.component
      }))}
    >
    </AntTabs>
  </div>
)

export default Tabs
