import { useState, useEffect, useMemo, useCallback, useContext, FC } from 'react'
import {
  Button,
  message,
  Form,
  Descriptions,
  Typography,
  Radio,
  Modal,
  Switch,
} from 'antd'
import { RcFile } from 'antd/es/upload'
import { useSelector } from 'react-redux'
import dayjs, { Dayjs } from 'dayjs'
import './styles.scss'
import InfoBox from '../../atoms/info-box'
import {
  defaultFieldsConfig,
  activeFieldsConfig,
  draftFieldsConfig,
  scheduledFieldsConfig,
  archivedFieldsConfig,
} from './programme-details-form.config'
import {
  updateProgramme,
  updateManager,
  assignManager,
  disableProgramme,
  disableProgrammeRedemptions,
  enableProgrammeRedemptions,
  setGpsCoordinatesCapturing,
  setNgoLogo as setNgoLogoReq,
  setL20LogoVisibility as setL20LogoVisibilityReq,
  removeNgoLogo,
} from '../../../services/api/programme.api'
import {
  programmeStatuses,
  programmeRedemptionStatuses,
  IsOnline,
  IsEnrollmentEnabled,
  IsBiometricEnabled,
  IsInvoicingEnabled,
} from '../../../constants/programme.constants'
import {
  currencies,
  apiRequestDateFormat,
} from '../../../constants/general'
import { isAdmin } from '../../../services/helpers/user'
import StatusPill from '../../atoms/status-pill'
import { getProgrammeManagerPickerOptions } from '../../atoms/select-user/optionsMappers'
import Spinner from '../../atoms/spinner'
import DateIntervalReadonly from '../../atoms/date-interval-readonly'
import { getFullName, numberInputFormatter, numberInputParser } from '../../../services/helpers/helpers'
import { getImageTypeNotSupportedMessage, isImageTypePdfCompatible } from '../../../services/helpers/images'
import { getFormattedCountryAndTimezone } from '../../../services/helpers/internationalization'
import EditableUser from '../../molecules/editable-fields/editable-user'
import EditableDatepicker from '../../molecules/editable-fields/editable-datepicker'
import { ProgrammeDetailsContext } from '../../views/ProgrammeDetails'
import EditableInput from '../../molecules/editable-fields/editable-input'
import EditableSelect from '../../molecules/editable-fields/editable-select'
import InfoButton from '../../molecules/info-button'

import GpsDataCapturingControl from './gpsDataCapturingControl'
import NgoLogoControl from './ngoLogoControl'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { UserRoles } from '../../../constants/userRoles.constants'
import EditableCountryAndTimezone from '../../molecules/editable-fields/editable-country-and-timezone'
import { Programme } from '../../../types/programme'
import { serverDateToString } from '../../../services/helpers/date'

const { Text } = Typography

export type EditProgrammeFormData = {
  programmeName: string
  programmeManager: {
      value: string
  }
  programmeDuration: {
      startDate: dayjs.Dayjs
      endDate: dayjs.Dayjs
  }
  currency: string
  programmeValue: string
  isOnline: boolean
  isBiometricEnabled: boolean
  isEnrollmentEnabled: boolean
  isInvoicingEnabled: boolean
  countryAndTimezone: [string, string]
}

const ProgrammeDetailsForm: FC<{ editable: boolean }> = ({ editable = false }) => {
  const { programme, updateProgrammeData } = useContext(ProgrammeDetailsContext)
  const [form] = Form.useForm<EditProgrammeFormData>()
  const [showSpinner, setShowSpinner] = useState(true)
  const [startDate, setStartDate] = useState<Dayjs>(null)
  const [endDate, setEndDate] = useState<Dayjs>(null)
  const [countryAndTimezone, setCountryAndTimezone] = useState<[string, string]>()
  const [formFieldsStatuses, setFormFieldsStatuses] = useState(defaultFieldsConfig)
  const [status, setStatus] = useState('')
  const [gpsDataCapturing, setGpsDataCapturing] = useState({ onRedemption: true, onEnrollment: true })
  const [ngoLogo, setNgoLogo] = useState(null)
  const [createdBy, setCreatedBy] = useState('')
  const [createdOn, setCreatedOn] = useState('')
  const [lastEditOn, setLastEditOn] = useState('')
  const [lastEditBy, setLastEditBy] = useState('')
  const isOnline = Form.useWatch<EditProgrammeFormData>('isOnline', form)
  const isBiometricEnabled = Form.useWatch<EditProgrammeFormData>('isBiometricEnabled', form)
  const isEnrollmentEnabled = Form.useWatch<EditProgrammeFormData>('isEnrollmentEnabled', form)
  const isInvoicingEnabled = Form.useWatch<EditProgrammeFormData>('isInvoicingEnabled', form)

  const currentUserRoles = useSelector<CombinedReducers, UserRoles[]>(state => state.user.userRoles)

  const setProgrammeEditableFields = useCallback((status) => {
    switch (status) {
      case programmeStatuses.archived.key:
        setFormFieldsStatuses(archivedFieldsConfig)
        break
      case programmeStatuses.active.key:
        setFormFieldsStatuses(activeFieldsConfig)
        break
      case programmeStatuses.scheduled.key:
        setFormFieldsStatuses(scheduledFieldsConfig)
        break
      case programmeStatuses.draft.key:
        setFormFieldsStatuses(draftFieldsConfig)
        break
      default:
        setFormFieldsStatuses(defaultFieldsConfig)
        break
    }
  }, [])

  useEffect(() => {
    if (!programme) {
      return
    }

    const {
      startDate,
      endDate,
      isRedemptionGpsEnabled,
      isEnrollmentGpsEnabled,
      status,
      CreatedBy,
      createdAt,
      UpdatedBy,
      updatedAt,
      country,
      timezone,
    } = programme
    if (!CreatedBy || !UpdatedBy) {
      return
    }

    setStartDate(dayjs.utc(startDate))
    setEndDate(dayjs.utc(endDate))
    setCountryAndTimezone([country, timezone])
    setStatus(status as string)
    setGpsDataCapturing({
      onEnrollment: isEnrollmentGpsEnabled,
      onRedemption: isRedemptionGpsEnabled
    })
    setCreatedOn(serverDateToString(createdAt))
    setCreatedBy(getFullName(CreatedBy))
    setLastEditOn(serverDateToString(updatedAt))
    setLastEditBy(getFullName(UpdatedBy))

    if (editable) {
      setProgrammeEditableFields(programme.status)
    } else {
      setFormFieldsStatuses(defaultFieldsConfig)
    }

    setShowSpinner(false)
  },
  [programme, editable, setProgrammeEditableFields])

  const archiveProgramme = useCallback(async () => {
    const res = await disableProgramme(programme.id)
    if (res.hasErrors) {
      return
    }
    setProgrammeEditableFields(res.status)
    setStatus(res.status)
  }, [programme, setProgrammeEditableFields])

  const onArchiveProgramme = useCallback(() => {
    Modal.confirm({
      onOk: () => archiveProgramme(),
      okText: 'Yes',
      cancelText: 'No',
      title: 'Are you sure you want to archive this programme?'
    })
  },
  [archiveProgramme]
  )

  const onToggleRedemptions = useCallback(async () => {
    const res = programme.isRedemptionEnabled
      ? await disableProgrammeRedemptions(programme.id)
      : await enableProgrammeRedemptions(programme.id)

    if (res.hasErrors) {
      message.error('Failed to toggle the certificates redemptions status')
      return
    }
    message.success('The certificates redemptions status has been updated')
    await updateProgrammeData()
  }, [programme, updateProgrammeData])

  const onToggleGpsCoordinatesCapturing = useCallback(async (isEnrollment, isChecked) => {
    const res = await setGpsCoordinatesCapturing(programme.id, isEnrollment, isChecked)
    if (res.hasErrors) {
      message.error('Failed to update the GPS coordinates capturing status')
      return
    }

    await updateProgrammeData()
    message.success('The GPS coordinates capturing status has been updated')
  },
  [programme, updateProgrammeData])

  const infoBoxActions = useMemo(() => {
    const actions = []

    if (
      isAdmin(currentUserRoles)
      && (status === programmeStatuses.active.key
        || status === programmeStatuses.scheduled.key)
    ) {
      actions.push(
        <Button
          type="text"
          onClick={onArchiveProgramme}
          danger
          key="Archive Programme"
        >
          Archive Programme
        </Button>
      )
    }

    return actions
  }, [currentUserRoles, status, onArchiveProgramme])

  const initialValues = useMemo(
    () => {
      if (programme) {
        return ({
          programmeName: programme.name,
          programmeManager: { value: programme.Manager?.id },
          programmeDuration: { startDate, endDate },
          currency: programme.currency,
          programmeValue: programme.entitlements,
          isOnline: programme.isOnline,
          isBiometricEnabled: programme.isBiometricEnabled,
          isEnrollmentEnabled: programme.isEnrollmentEnabled,
          isInvoicingEnabled: programme.isInvoicingEnabled,
          countryAndTimezone
        })
      }
    },
    [programme, startDate, endDate, countryAndTimezone]
  )

  const onUpdateProgrammeManager = useCallback(
    async (programmeManagerId: string) => {
      const updatedProgramme = programme.Manager?.id
        ? await updateManager(programme.id, programmeManagerId)
        : await assignManager(programme.id, programmeManagerId)

      if (updatedProgramme.hasErrors) {
        message.error(updatedProgramme.message)
        return
      }
      await updateProgrammeData()
      message.success('Programme manager updated successfully.')
    },
    [programme, updateProgrammeData],
  )

  const updateField = useCallback(
    async (data: Partial<Programme>) => {
      const updateProgrammeRes = await updateProgramme(programme.id, data)
      if (updateProgrammeRes.hasErrors) {
        message.error(updateProgrammeRes.message)
        return
      }

      setProgrammeEditableFields(updateProgrammeRes.status)
      await updateProgrammeData()
      setStatus(updateProgrammeRes.status as string)
      message.success('Programme details updated successfully.')
    },
    [programme, setProgrammeEditableFields, updateProgrammeData],
  )

  const setL20LogoVisibility = useCallback(
    async (checked) => {
      const res = await setL20LogoVisibilityReq(programme.id, checked)
      if (res.hasErrors) {
        message.error('Failed to update the L20 logo visibility.')
        return
      }

      await updateProgrammeData()
      message.success('Succesfully updated the L20 logo visibility.')
    },
    [programme, updateProgrammeData],
  )

  const beforeNgoLogoUpload = useCallback(
    (imageFile: RcFile) => {
      if (!isImageTypePdfCompatible(imageFile.type)) {
        message.error(getImageTypeNotSupportedMessage(imageFile.name));
        return false;
      }

      setNgoLogo(imageFile)
      return false
    },
    []
  )

  const onNgoLogoSave = useCallback(
    async () => {
      const formData = new FormData()
      formData.append('image', ngoLogo)

      const res = await setNgoLogoReq(programme.id, formData)
      if (res.hasErrors) {
        message.error('Failed to update the organisation logo.')
        return
      }
      await updateProgrammeData()
      setNgoLogo(null)
      message.success('The item has been added successfully.')
    },
    [programme, ngoLogo, updateProgrammeData]
  )

  const onNgoLogoDelete = useCallback(
    async () => {
      const res = await removeNgoLogo(programme.id)
      if (res.hasErrors) {
        message.error('Failed to delete the organisation logo.')
        return
      }

      await updateProgrammeData()
      message.success('Successfully deleted the organisation logo.')
    },
    [programme, updateProgrammeData]
  )
  return (
    <div className="programme-details__wrapper">
      {showSpinner ? (
        <Spinner />
      ) : (
        <>
          <div className="programme-details">
            <div className="first-column">
              {editable ? (
                <Form
                  form={form}
                  name="editProgramme"
                  autoComplete="on"
                  labelWrap
                  size="large"
                  layout="vertical"
                  initialValues={initialValues}
                >
                  <Form.Item
                    label="Programme name"
                    name="programmeName"
                  >
                    <EditableInput
                      defaultValue={programme.name}
                      isEditable={formFieldsStatuses.programmeName}
                      onSave={data => updateField({ name: data })}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Programme manager"
                    name="programmeManager"
                  >
                    <EditableUser
                      userId={programme.Manager?.id}
                      userName={programme.Manager
                        ? getFullName(programme.Manager)
                        : 'Not assigned'}
                      isEditable={formFieldsStatuses.programmeManager}
                      onSave={onUpdateProgrammeManager}
                      getOptions={searchText => getProgrammeManagerPickerOptions(searchText)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Programme duration"
                    name="programmeDuration"
                  >
                    <EditableDatepicker
                      startDate={startDate}
                      endDate={endDate}
                      rangePickerDateDisabledDate={currentDate => currentDate < dayjs().startOf('day')}
                      rangePickerDisabled={[
                        !formFieldsStatuses.startDate,
                        !formFieldsStatuses.endDate,
                      ]}
                      onSave={async (data) => {
                        await updateField({
                          startDate: data.startDate.format(apiRequestDateFormat),
                          endDate: data.endDate.format(apiRequestDateFormat),
                        })
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Currency"
                    name="currency"
                  >
                    <EditableSelect
                      options={currencies}
                      defaultValue={programme.currency}
                      isEditable={formFieldsStatuses.currency}
                      onSave={data => updateField({ currency: data })}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Programme budget"
                    name="entitlements"
                  >
                    <EditableInput
                      isNumber
                      defaultValue={programme.entitlements}
                      currency={programme.currency}
                      isEditable={formFieldsStatuses.programmeValue}
                      onSave={data => updateField({ entitlements: data })}
                      min={1}
                      max={Number.MAX_SAFE_INTEGER}
                      formatter={numberInputFormatter}
                      parser={numberInputParser}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Programme type"
                    name="isOnline"
                  >
                    {formFieldsStatuses.isOnline
                      ? (
                        <Radio.Group
                          buttonStyle="solid"
                          optionType="button"
                          onChange={e => updateField({ isOnline: e.target.value })}
                          options={[
                            {
                              label: IsOnline.online,
                              value: true,
                            },
                            {
                              label: IsOnline.offline,
                              value: false,
                            },
                          ]}
                        />
                      )
                      : <Text>{isOnline ? IsOnline.online : IsOnline.offline}</Text>}
                  </Form.Item>

                  {programme.isOnline && (
                    <Form.Item
                      label="Certificates enrollment"
                      name="isEnrollmentEnabled"
                    >
                      {formFieldsStatuses.isEnrollmentEnabled
                        ? (
                          <Radio.Group
                            buttonStyle="solid"
                            optionType="button"
                            onChange={e => updateField({ isEnrollmentEnabled: e.target.value })}
                            options={[
                              {
                                label: IsEnrollmentEnabled.mandatory,
                                value: true,
                              },
                              {
                                label: IsEnrollmentEnabled.optional,
                                value: false,
                              },
                            ]}
                          />
                        )
                        : (<Text>{isEnrollmentEnabled ? IsEnrollmentEnabled.mandatory : IsEnrollmentEnabled.optional}</Text>)}
                    </Form.Item>
                  )}

                  <Form.Item
                    label="Biometric verification"
                    name="isBiometricEnabled"
                  >
                    {formFieldsStatuses.isBiometricEnabled
                      ? (
                        <Radio.Group
                          buttonStyle="solid"
                          optionType="button"
                          onChange={e => updateField({ isBiometricEnabled: e.target.value })}
                          options={[
                            {
                              label: IsBiometricEnabled.enabled,
                              value: true,
                            },
                            {
                              label: IsBiometricEnabled.disabled,
                              value: false,
                            },
                          ]}
                        />
                      )
                      : (<Text>{isBiometricEnabled ? IsBiometricEnabled.enabled : IsBiometricEnabled.disabled}</Text>)}
                  </Form.Item>

                  <Form.Item
                    label="Invoicing"
                    name="isInvoicingEnabled"
                  >
                    {formFieldsStatuses.isInvoicingEnabled
                      ? (
                        <Radio.Group
                          buttonStyle="solid"
                          optionType="button"
                          onChange={e => updateField({ isInvoicingEnabled: e.target.value })}
                          options={[
                            {
                              label: IsInvoicingEnabled.on,
                              value: true,
                            },
                            {
                              label: IsInvoicingEnabled.off,
                              value: false,
                            },
                          ]}
                        />
                      )
                      : (<Text>{isInvoicingEnabled ? IsInvoicingEnabled.on : IsInvoicingEnabled.off}</Text>)}
                  </Form.Item>

                  <Form.Item
                    label="Country and timezone"
                    name="countryAndTimezone"
                  >
                    <EditableCountryAndTimezone
                      defaultValue={countryAndTimezone}
                      isEditable={formFieldsStatuses.countryAndTimezone}
                      onSave={data => updateField({ country: data[0], timezone: data[1] })}
                    />
                  </Form.Item>
                </Form>
              ) : (
                <Descriptions
                  column={1}
                  layout="vertical"
                  colon={null}
                  className="programme-details__descriptions"
                >
                  <Descriptions.Item label="Programme name">
                    {programme.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Programme manager">
                    {programme.Manager ? (
                      getFullName(programme.Manager)
                    ) : (
                      <Text italic>
                        no programme manager assigned
                      </Text>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Programme duration">
                    <DateIntervalReadonly
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Currency">
                    {programme.currency}
                  </Descriptions.Item>
                  <Descriptions.Item label="Programme budget">
                    {programme.entitlements} {programme.currency}
                  </Descriptions.Item>
                  <Descriptions.Item label="Programme type">
                    {programme.isOnline ? IsOnline.online : IsOnline.offline}
                  </Descriptions.Item>
                  {programme.isOnline && (
                    <Descriptions.Item label="Certificates enrollment">
                      {programme.isEnrollmentEnabled ? IsEnrollmentEnabled.mandatory : IsEnrollmentEnabled.optional}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label="Biometric verification">
                    {programme.isBiometricEnabled ? IsBiometricEnabled.enabled : IsBiometricEnabled.disabled}
                  </Descriptions.Item>
                  <Descriptions.Item label="Country and timezone">
                    {getFormattedCountryAndTimezone(countryAndTimezone)}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </div>

            <div className="second-column">
              <InfoBox
                actions={infoBoxActions}
                details={[
                  {
                    label: 'Programme status',
                    value: (
                      <StatusPill statusData={programmeStatuses[status]} />
                    ),
                  },
                  {
                    label: 'Redemptions',
                    value: (
                      <>
                        <Switch
                          defaultChecked={programme.isRedemptionEnabled}
                          onChange={onToggleRedemptions}
                          checkedChildren={programmeRedemptionStatuses.active.label.toLocaleUpperCase()}
                          unCheckedChildren={programmeRedemptionStatuses.paused.label.toLocaleUpperCase()}
                          key="toggle-redemptions"
                          disabled={status === programmeStatuses.draft.key}
                        />
                        {status === programmeStatuses.draft.key && (<InfoButton infoText={`This feature is not available for ${programmeStatuses.draft.label} programmes`} />)}
                      </>
                    ),
                  },
                  {
                    label: 'Renewal date',
                    value: '1st of every month',
                  },
                  {
                    label: 'Created on',
                    value: createdOn,
                  },
                  {
                    label: 'Created by',
                    value: createdBy,
                  },
                  {
                    label: 'Last edit on',
                    value: lastEditOn,
                  },
                  {
                    label: 'Last edit by',
                    value: lastEditBy,
                  },
                ]}
              >
                <GpsDataCapturingControl
                  disabled={status === programmeStatuses.draft.key}
                  onEnrollment={gpsDataCapturing.onEnrollment}
                  onRedemption={gpsDataCapturing.onRedemption}
                  onToggleEnrollmentGpsCapture={isChecked => onToggleGpsCoordinatesCapturing(true, isChecked)}
                  onToggleRedemptionGpsCapture={isChecked => onToggleGpsCoordinatesCapturing(false, isChecked)}
                />
                <div
                  className="organization-logo__container"
                  key="organization-logo"
                >
                  {isAdmin(currentUserRoles) && (
                    <Descriptions>
                      <Descriptions.Item label="Show the L20 logo on certificates">
                        <Switch
                          defaultChecked={programme.isL20LogoEnabled}
                          disabled={status === programmeStatuses.draft.key}
                          onChange={setL20LogoVisibility}
                        />
                        {status === programmeStatuses.draft.key && (<InfoButton infoText={`This feature is not available for ${programmeStatuses.draft.label} programmes`} />)}
                      </Descriptions.Item>
                    </Descriptions>
                  )}

                  <Descriptions>
                    <Descriptions.Item label="Organisation logo">
                      <NgoLogoControl
                        currentLogo={ngoLogo ? URL.createObjectURL(ngoLogo) : programme.ngoLogo}
                        ngoLogo={ngoLogo}
                        disabled={status === programmeStatuses.draft.key}
                        beforeUpload={beforeNgoLogoUpload}
                        onSave={onNgoLogoSave}
                        onDelete={onNgoLogoDelete}
                        onCancel={() => setNgoLogo(null)}
                      />
                      {status === programmeStatuses.draft.key && (<InfoButton infoText={`This feature is not available for ${programmeStatuses.draft.label} programmes`} />)}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </InfoBox>

              <Descriptions
                column={1}
                layout="vertical"
                colon={null}
              >
                <Descriptions.Item label="Certificates Issued">
                  {programme.vouchersSummary.issued}
                </Descriptions.Item>
                <Descriptions.Item label="Certificates Redeemed">
                  {programme.vouchersSummary.redeemed}
                </Descriptions.Item>
                <Descriptions.Item label="Remaining Value">
                  {programme.vouchersSummary.remainingValueAfterRenewals} {programme.currency}
                </Descriptions.Item>
                <Descriptions.Item label="Disabled Certificates">
                  {programme.vouchersSummary.disabled}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ProgrammeDetailsForm
