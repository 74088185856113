import { FC, ReactNode } from 'react'
import TransactionDetailsRow from './transaction-details-row'
import Table from '../../atoms/table'
import { ColumnsType } from 'antd/es/table'

const TransactionDetails: FC<{
  date: string
  amount: string
  fieldworkerName: string
  merchantName: string
  programmeName: string
  voucherIndex: string
  productsTableData: {
    id: string,
    item: ReactNode,
    quantity: number,
    value: string
  }[]
  householdNumber: string
}> = ({
  date,
  amount,
  fieldworkerName,
  merchantName,
  programmeName,
  voucherIndex,
  productsTableData,
  householdNumber,
}) => {
  const columns: ColumnsType = [
    {
      title: 'Item',
      key: 'item',
      dataIndex: 'item',
      width: '60%',
      ellipsis: true
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Value',
      key: 'value',
      dataIndex: 'value',
    },
  ]

  return (
    <article className="transaction-details">
      <section className="transaction-details__details-wrapper">
        <TransactionDetailsRow
          description="Date"
          value={date}
        />
        <TransactionDetailsRow
          description="Amount"
          value={amount}
        />
        {voucherIndex && (
          <TransactionDetailsRow
            description="Certificate number"
            value={voucherIndex}
          />
        )}
        {householdNumber && (
          <TransactionDetailsRow
            description="Household number"
            value={householdNumber}
          />
        )}
        <TransactionDetailsRow
          description="Programme name"
          value={programmeName}
        />
        {merchantName && (
          <TransactionDetailsRow
            description={'Supplier name'}
            value={merchantName}
          />
        )}
        {fieldworkerName && (
          <TransactionDetailsRow
            description={'Fieldworker name'}
            value={fieldworkerName}
          />
        )}
      </section>

      {!!productsTableData?.length && (
        <section className="transaction-details__table-wrapper">
          <Table
            data={productsTableData}
            columns={columns}
          />
        </section>
      )}
    </article>
  )
}

export default TransactionDetails
