import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import Spinner from '../components/atoms/spinner'
import { viewContexts, ViewModes } from '../constants/app.constants'
import { UserRoles } from '../constants/userRoles.constants'
import { setViewMode } from '../redux/stores/application/app.actions'
import { setActiveProgramme } from '../redux/stores/programme/programme.actions'
import { CombinedReducers } from '../redux/stores/reducers'
import { isUuidV4 } from '../services/helpers/helpers'
import { isAdmin } from '../services/helpers/user'

const ContextAwareRoute = () => {
  const { pathname } = useLocation()
  const { viewContext } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { programmes, isInitialRequestDone, currentUserRoles } = useSelector<
    CombinedReducers,
    { programmes: unknown[], isInitialRequestDone: boolean, currentUserRoles: UserRoles[] }
  >(state => ({
    programmes: state.programme.programmes,
    isInitialRequestDone: state.user.isInitialRequestDone,
    currentUserRoles: state.user.userRoles,
  }))

  useEffect(() => {
    if (!isInitialRequestDone) {
      return
    }

    if (viewContext === viewContexts.admin.key) {
      if (!isAdmin(currentUserRoles)) {
        return navigate('/404')
      }
      dispatch(setViewMode(ViewModes.admin))
      return
    }

    if (isUuidV4(viewContext) && programmes?.length) {
      const currentProgramme = programmes.find(({ id }) => viewContext === id)
      if (!currentProgramme) {
        return navigate('/programme-not-found', { state: { programmeId: viewContext } })
      }
      dispatch(setViewMode(ViewModes.programme))
      dispatch(setActiveProgramme(currentProgramme))
      return
    }

    return navigate('/404')
  }, [currentUserRoles, dispatch, isInitialRequestDone, navigate, pathname, programmes, viewContext])

  return isInitialRequestDone
    ? <Outlet />
    : (
      <Spinner
        centered
        fillContainer
        size="200px"
      />
    )
}

export default ContextAwareRoute
