import { IconBan } from '@tabler/icons-react';
import NutritionalValueHighSvg from '../../atoms/icons/nutritionalValue-high-svg';
import NutritionalValueLowSvg from '../../atoms/icons/nutritionalValue-low-svg';
import NutritionalValueMediumSvg from '../../atoms/icons/nutritionalValue-medium-svg';

export const nutritionalValues = {
  low: {
    key: 'low',
    label: 'Low',
    icon: <NutritionalValueLowSvg />,
  },
  medium: {
    key: 'medium',
    label: 'Medium',
    icon: <NutritionalValueMediumSvg />,
  },
  high: {
    key: 'high',
    label: 'High',
    icon: <NutritionalValueHighSvg />,
  },
  notApplicable: {
    key: 'notApplicable',
    label: 'N/A',
    icon: <IconBan size="1.2rem" />,
  },
}
