import { FC } from 'react'
import { Image, ImageProps, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { IconPencil, IconPhotoPlus } from '@tabler/icons-react'
import './styles.scss'
import { pdfCompatibleFileTypes } from '../../../services/helpers/images'
import Icon from '../../atoms/icon'
import { UploadFile, UploadProps } from 'antd/es/upload'

const ImageUploader: FC<UploadProps & {
  file?: UploadFile,
  existingImage?: string,
  imagePreviewSize?: string,
  imagePreviewProps?: ImageProps,
}> = ({
  beforeUpload,
  file,
  existingImage,
  imagePreviewSize = '50px',
  imagePreviewProps,
  ...uploadProps
}) => {
  return (
    <ImgCrop
      rotationSlider
      zoomSlider
    >
      <Upload
        name="image"
        listType="picture-card"
        className="ant-image-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        fileList={file ? [file] : []}
        accept={pdfCompatibleFileTypes.acceptTypes}
        {...uploadProps}
      >
        {existingImage ? (
          <figure className="hover-img">
            <Image
              src={existingImage}
              preview={false}
              className="existing-image"
              {...imagePreviewProps}
            />
            <figcaption>
              <Icon component={IconPencil} />
            </figcaption>
          </figure>
        ) : (
          <div className="item-image">
            <div
              className="item-image--background"
              style={{
                backgroundImage: existingImage
                  ? `url(${existingImage})`
                  : null,
              }}
            >
            </div>
            <div className="item-image--content">
              <Icon
                component={IconPhotoPlus}
                componentProps={{ strokeWidth: 1.5, size: imagePreviewSize }}
              />
            </div>
          </div>
        )}
      </Upload>
    </ImgCrop>
  )
}

export default ImageUploader
