import { VoucherBatchRaw, VoucherHistory } from '../../types/voucher'
import { getFileNameFromHeaders } from '../helpers/helpers'
import { ApiResponseData, axiosApi } from './api'

export type AddVoucherBatchPayload = {
  endDate?: string
  fieldworkerId: string
  fieldworkerMessage?: string
  fixedValue: number
  isRenewable: boolean,
  merchantMessage?: string
  number: number
  renewalType?: number
  startDate?: string
  totalValue: number
}
export const addVoucherBatch = async (programmeId: string, payload: AddVoucherBatchPayload) => {
  const { data } = await axiosApi()
    .post(
      `/programmes/${programmeId}/voucherBatches`,
      payload
    )
  return data
}

export const getVoucherBatchDetails = async (programmeId: string, voucherBatchId: string) => {
  const { data } = await axiosApi()
    .get<ApiResponseData<VoucherBatchRaw>>(
      `/programmes/${programmeId}/voucherBatches/${voucherBatchId}`
    )
  return data
}

export const getVoucherBatches = async (programmeId, params) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/voucherBatches`,
      { params }
    )
  return data
}

export const updateVoucherBatch = async (
  programmeId,
  voucherBatchId,
  payload
) => {
  const { data } = await axiosApi()
    .patch(
      `/programmes/${programmeId}/voucherBatches/${voucherBatchId}`,
      payload
    )
  return data
}

export const disableVoucherBatch = async (programmeId, voucherBatchId) => {
  const { data } = await axiosApi()
    .patch(
      `/programmes/${programmeId}/voucherBatches/${voucherBatchId}/disable`
    )
  return data
}

export const getVoucherBatchPDF = async (programmeId, voucherBatchId) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/voucherBatches/${voucherBatchId}/pdf`,
      {
        headers: { accept: 'application/octet-stream' },
        responseType: 'blob',
      }
    )
  return data
}

export const getVouchersReportCSV = async (programmeId) => {
  const { data, headers } = await axiosApi()
    .get(
      `/programmes/${programmeId}/report/vouchers`,
      {
        headers: { accept: 'application/octet-stream' },
        responseType: 'blob',
      }
    )

  const filename = getFileNameFromHeaders(headers)

  return { data, filename }
}

export const transferVoucherBatch = async (
  programmeId,
  voucherBatchId,
  userId
) => {
  const { data } = await axiosApi()
    .put(
      `/programmes/${programmeId}/voucherBatches/${voucherBatchId}/fieldworkers/${userId}`
    )
  return data
}

export const getVouchers = async (programmeId, voucherBatchId, params) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/voucherBatches/${voucherBatchId}/vouchers`,
      { params }
    )
  return data
}

export const getVoucherHistory = async (programmeId, voucherId) => {
  const { data } = await axiosApi()
    .get<VoucherHistory>(
      `/programmes/${programmeId}/vouchers/${voucherId}/history`
    )
  return data
}

export const disableVoucher = async (
  programmeId,
  voucherBatchId,
  voucherId
) => {
  const { data } = await axiosApi()
    .patch(
      `/programmes/${programmeId}/voucherBatches/${voucherBatchId}/vouchers/${voucherId}/disable`
    )
  return data
}
