import { FC } from 'react'
import { IconLoader2 } from '@tabler/icons-react'
import { Spin } from 'antd'
import Icon from '../icon'
import colors from '../../../styles/_variables.module.scss'
import classNames from 'classnames'
import './styles.scss'

const Spinner: FC<{
  centered?: boolean,
  fillContainer?: boolean,
  size?: string
}> = ({ centered, fillContainer, size = '75px' }) => (
  <div
    className={classNames('spinner', {
      centered,
      'fill-container': fillContainer,
    })}
  >
    <Spin
      spinning
      indicator={
        <Icon
          spin
          component={IconLoader2}
          componentProps={{
            height: size,
            width: size,
            color: colors.mainColor,
          }}
        />
      }
    />
  </div>
)

export default Spinner
