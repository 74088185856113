import { FC, useCallback, useState } from 'react'
import { DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import './styles.scss'
import DateIntervalReadonly from '../../atoms/date-interval-readonly'
import { rangePickerDateFormat } from '../../../constants/general'
import EditableField, { EditableFieldContext } from './editable-field'

const { RangePicker } = DatePicker

const EditableDatepicker: FC<{
  startDate: Dayjs
  endDate: Dayjs
  rangePickerDisabled?: [startDate: boolean, endDate: boolean],
  onSave: (props: {
    startDate: Dayjs;
    endDate: Dayjs;
  }) => Promise<void>
  rangePickerDateDisabledDate: (date: Dayjs) => boolean,
}> = ({
  startDate,
  endDate,
  rangePickerDisabled,
  onSave,
  rangePickerDateDisabledDate
}) => {
  const [dateRange, setDateRange] = useState({ startDate, endDate })

  const onRangePickerChange = useCallback(
    (dates) => {
      if (!dates) {
        setDateRange({
          startDate: null,
          endDate: null
        })
        return
      }

      const [startDate, endDate] = dates
      const newDateRanges = { ...dateRange }
      if (!startDate.isSame(dateRange.startDate)) {
        newDateRanges.startDate = startDate
      }

      const endDateEod = endDate.endOf('day')
      if (!endDateEod.isSame(dateRange.endDate)) {
        newDateRanges.endDate = endDateEod
      }
      setDateRange(newDateRanges)
    },
    [dateRange]
  )

  return (
    <EditableFieldContext.Provider
      value={{
        fieldValue: dateRange,
        setFieldValue: setDateRange
      }}
    >
      <EditableField
        className="editable-field__date-range"
        onSave={onSave}
        readOnlyTitle={`${dayjs.utc(startDate).format('LL')} → ${dayjs.utc(endDate).format('LL')}`}
        readOnlyContent={(
          <DateIntervalReadonly
            startDate={startDate}
            endDate={endDate}
          />
        )}
        initialFieldValue={{ startDate, endDate }}
        isEditable={rangePickerDisabled?.findIndex(isDateDisabled => !isDateDisabled) !== -1}
        isSaveDisabled={
          !dateRange.startDate
          || !dateRange.endDate
          || (dateRange.startDate?.isSame(startDate) && dateRange.endDate?.isSame(endDate))
        }
      >
        <RangePicker
          format={rangePickerDateFormat}
          defaultValue={[dayjs.utc(startDate), dayjs.utc(endDate)]}
          onChange={onRangePickerChange}
          disabledDate={rangePickerDateDisabledDate}
          disabled={rangePickerDisabled}
        />
      </EditableField>
    </EditableFieldContext.Provider>
  )
}

export default EditableDatepicker
