import { useState, useEffect, useCallback, useMemo, FC } from 'react'
import dayjs from 'dayjs'
import { Button, Col, DatePicker, Input, message, Row } from 'antd'
import { saveAs } from 'file-saver'
import './styles.scss'
import { mapActions, defaultQuery } from '../../molecules/transaction-table/transaction-table.config'
import TransactionTable from '../../molecules/transaction-table'
import {
  getProgrammeTransactions,
  getTransactionsCsv,
} from '../../../services/api/transaction.api'
import { capitalizeFirstLetter, getFullName } from '../../../services/helpers/helpers'
import { serverDateToString } from '../../../services/helpers/date'
import { useQuery } from '../../../hooks/query.hook'
import TransactionDetailsModal from '../../molecules/transaction-details-modal'
import InfoSvg from '../../atoms/icons/info-svg'
import { mapTransaction } from '../../../services/helpers/transaction'
import HighlightedText from '../../atoms/highlighted-text'
import { getFieldworkers } from '../../../services/api/fieldworker.api'
import { transactionTypes } from '../../../constants/transactions.constants'
import { handleSorting } from '../../../services/helpers/table'
import { ColumnsType } from 'antd/es/table'
import { ColumnFilterItem } from 'antd/es/table/interface'
import { Programme } from '../../../types/programme'
import { rangePickerDateFormat } from '../../../constants/general'

const { RangePicker } = DatePicker
const { Search } = Input

const TransactionListComponent: FC<{ programme: Programme}> = ({ programme }) => {
  const [transactions, setTransactions] = useState([])
  const [transactionsCount, setTransactionsCount] = useState(null)
  const defaultFrom = dayjs.utc().subtract(7, 'days').startOf('day')
  const defaultTo = dayjs.utc().endOf('day')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentTransaction, setCurrentTransaction] = useState(null)
  const [searchPhrase, setSearchPhrase] = useState('')
  const [fieldworkers, setFieldworkers] = useState<ColumnFilterItem[]>([])

  const { query, onPageChange, onFilters, onSearch, onSort } = useQuery({
    ...defaultQuery,
    from: defaultFrom.valueOf(),
    to: defaultTo.valueOf(),
  })

  useEffect(() => {
    if (!programme?.id) {
      return
    }

    getProgrammeTransactions(programme.id, query).then((res) => {
      if (!res.hasErrors && res.rows) {
        setTransactions(
          res.rows.map((transaction) => {
            const mappedTransaction = mapTransaction(transaction)

            mappedTransaction.details = mapActions([
              {
                onClick: () => {
                  setCurrentTransaction({
                    ...mappedTransaction,
                    date: dayjs(transaction.createdAt).format('LLL'),
                  })
                  setIsModalOpen(true)
                },
                icon: <InfoSvg />,
              },
            ])

            return mappedTransaction
          })
        )

        if (res.count !== transactionsCount) {
          setTransactionsCount(res.count || null)
        }
      }
    })

    getFieldworkers(programme.id).then((res) => {
      if (res.hasErrors) {
        message.error('Failed to get fieldworkers')
      }

      setFieldworkers(res.fieldWorkers?.map(fieldworker => ({
        text: getFullName(fieldworker),
        value: fieldworker.id,
      })))
    })
  }, [
    programme,
    transactionsCount,
    setTransactions,
    setTransactionsCount,
    query,
  ])

  const handleDownloadClick = useCallback(async () => {
    try {
      const { page: _page, pageSize: _pageSize, ...queryParams } = query

      const response = await getTransactionsCsv(programme?.id, queryParams)
      const fromString = serverDateToString(query.from, 'DD.MM.YYYY')
      const toString = serverDateToString(query.to, 'DD.MM.YYYY')
      const fileName = `Transactions ${programme?.name} ${fromString}-${toString}.csv`
      saveAs(response, fileName)
    } catch (err) {
      message.error('There was an error downloading the report')
    }
  }, [programme, query])

  const columns = useMemo(() => {
    const cols: ColumnsType = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'createdAt',
        sorter: true,
        defaultSortOrder: 'descend',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        filters: Object.keys(transactionTypes).map(
          transactionType => ({
            text: capitalizeFirstLetter(transactionTypes[transactionType].label),
            value: transactionTypes[transactionType].key,
          })
        ),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'value',
        // sortDirections will work only after upgrading to ant 5.0
        // sortDirections: Object.values(SortDirections),
        sorter: true,
      },
      {
        title: 'Fieldworker',
        dataIndex: 'fieldworker',
        key: 'fieldworker',
        filters: fieldworkers,
        filterSearch: true,
      },
      {
        dataIndex: 'details',
        key: 'details',
      },
    ]

    if (searchPhrase.length) {
      cols.splice(
        cols.findIndex(column => column.key === 'details'),
        0,
        {
          title: 'Certificate Index',
          dataIndex: 'voucherIndex',
          key: 'voucherIndex',
          render: text => (
            <HighlightedText
              contentText={text}
              searchWords={searchPhrase}
            />
          )
        },
        {
          title: 'Household Number',
          dataIndex: 'householdNumber',
          key: 'householdNumber',
          render: text => (
            <HighlightedText
              contentText={text}
              searchWords={searchPhrase}
            />
          )
        },
      )
    }
    return cols
  }, [fieldworkers, searchPhrase])

  const onTableChange = useCallback(
    (_, filters, sorter, extra) => {
      if (extra.action === 'filter') {
        const mappedFilters = Object.keys(filters).map((filterName) => {
          switch (filterName) {
            case 'fieldworker':
              return {
                name: 'fieldworkerIds',
                value: filters[filterName] && filters[filterName]
              }

            case 'type':
              return {
                name: 'type',
                value: filters[filterName] && filters[filterName]
              }

            default:
              return {
                name: filterName,
                value: filters[filterName]
              };
          }
        })
        onFilters(mappedFilters)
      }

      handleSorting(onSort, extra.action, sorter, defaultQuery)
    },
    [onFilters, onSort],
  )

  return (
    <>
      <TransactionTable
        columns={columns}
        presetQuery={query}
        transactions={transactions}
        transactionsCount={transactionsCount}
        onPageChange={onPageChange}
        onTableChange={onTableChange}
      >
        <div className="transactions-header">
          <Input.Group size="large">
            <Row wrap={false}>
              <Col
                md={17}
                xl={20}
              >
                <Row gutter={[16, 16]}>
                  <Col
                    sm={24}
                    xl={15}
                    flex="0 1 600px"
                  >
                    <Search
                      allowClear
                      placeholder="Search by certificate # or household number"
                      onSearch={(searchText) => {
                        setSearchPhrase(searchText)
                        onSearch(searchText, 'search')
                      }}
                    />
                  </Col>
                  <Col
                    sm={24}
                    xl={9}
                  >
                    <RangePicker
                      format={rangePickerDateFormat}
                      defaultValue={[defaultFrom, defaultTo]}
                      disabledDate={date =>
                        date < dayjs(programme?.startDate)
                        || date > dayjs(programme?.endDate)
                      }
                      onChange={(dates) => {
                        if (!dates) {
                          return
                        }
                        const [newFrom, newTo] = dates
                        onFilters([
                          {
                            name: 'from',
                            value: newFrom.utc().startOf('day').valueOf(),
                          },
                          {
                            name: 'to',
                            value: newTo.utc().endOf('day').valueOf(),
                          },
                        ])
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                md={7}
                xl={4}
                className="transactions-download-buttons"
              >
                <Button
                  type="primary"
                  onClick={handleDownloadClick}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Input.Group>
        </div>
      </TransactionTable>

      <TransactionDetailsModal
        transaction={currentTransaction}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  )
}

export default TransactionListComponent
