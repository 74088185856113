import { useState, useRef, useCallback, useMemo } from 'react'
import { Typography, Form, Button, message, Input } from 'antd'
import Recaptcha from 'react-google-recaptcha'
import './styles.scss'
import { resetPasswordRequest, ResetPasswordRequestPayload } from '../../../services/api/user.api.js'
import { useSearchParams } from 'react-router-dom'
import { EnvTypes, testingCaptchaSiteKey } from '../../../constants/app.constants'

const { Paragraph } = Typography

const ResetPassword = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
  const [captchaToken, setCaptchaToken] = useState(null)
  const [searchParams] = useSearchParams()

  const [form] = Form.useForm()
  const captchaRef = useRef(null)

  const onCaptchaChange = useCallback(
    (token) => {
      setCaptchaToken(token)
      // when the token expires onChange is called with a null token so the submit must be disabled
      setIsSubmitEnabled(!!token)
    },
    [],
  )

  const shouldSkipCaptcha = useMemo(() => {
    if (import.meta.env.VITE_ENV === EnvTypes.production){
      return false
    }

    return searchParams.get('skipCaptcha') !== null
  },
  [searchParams])

  const captchaSiteKey = useMemo(() => {
    if (import.meta.env.VITE_ENV === EnvTypes.production){
      return import.meta.env.VITE_CAPTCHA_SITE_KEY
    }

    return shouldSkipCaptcha
      ? testingCaptchaSiteKey
      : import.meta.env.VITE_CAPTCHA_SITE_KEY
  },
  [shouldSkipCaptcha])

  const onSubmit = useCallback(
    async ({ email }) => {
      const payload: ResetPasswordRequestPayload = { email, captchaToken }
      if (shouldSkipCaptcha) {
        payload.shouldSkipCaptcha = true
      }

      const response = await resetPasswordRequest(payload)
      if (response.hasErrors) {
        captchaRef.current.reset();
        setIsSubmitEnabled(false)
        return message.error(response.message)
      }
      return setIsSuccess(true)
    },
    [captchaToken, shouldSkipCaptcha])

  return (
    <div className="reset-password">
      {isSuccess
        ? (
          <div className="after-message">
            <Paragraph strong>Request sent successfully.</Paragraph>
            <Paragraph>You will receive an email to help you reset your password.</Paragraph>
            <Paragraph>
              If you do not receive an email in the following 5-10 minutes, please request another password reset.
            </Paragraph>
          </div>
        )
        : (
          <>
            <Paragraph>Forgotten your password? No worries!</Paragraph>
            <Paragraph>Enter your email address below and we will send you a password reset link.</Paragraph>

            <Form
              form={form}
              name="resetPassword"
              autoComplete="on"
              className="reset-password-form"
              onFinish={onSubmit}
              labelWrap
              size="large"
              layout="vertical"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please type a valid email address.',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <div className="captcha__wrapper">
                <Recaptcha
                  sitekey={captchaSiteKey}
                  ref={captchaRef}
                  onChange={onCaptchaChange}
                />
              </div>

              <Form.Item className="submit" >
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={!isSubmitEnabled}
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
    </div>
  )
}

export default ResetPassword
