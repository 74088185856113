/* eslint-disable default-param-last */
import { UserRoles } from '../../../constants/userRoles.constants'
import { BaseUser } from '../../../types/user'
import {
  SET_USER_DETAILS,
  SET_USER_ROLE,
  SET_IS_LOGGED_IN,
  SET_IS_INITIAL_USER_REQUEST_DONE,
  RESET_STATE,
} from './user.types'

export type UserReducerState = {
  userDetails: BaseUser,
  userRoles: UserRoles[],
  isLoggedIn: boolean,
  isInitialRequestDone: boolean,
}

const initialState = {
  userDetails: null,
  userRoles: [],
  isLoggedIn: null,
  isInitialRequestDone: false,
}

export default function userReducer(state = initialState, action): UserReducerState {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      }
    case SET_USER_ROLE:
      return {
        ...state,
        userRoles: action.payload,
      }
    case SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      }
    case SET_IS_INITIAL_USER_REQUEST_DONE:
      return {
        ...state,
        isInitialRequestDone: action.payload,
      }
    case RESET_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
