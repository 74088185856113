import { IconBuildingStore, IconCategory, IconFileDescription, IconHash, IconHome, IconInfoCircle, IconLayoutBoard, IconListSearch, IconShoppingBag, IconSparkles, IconUsers, IconWalk } from '@tabler/icons-react'
import { ViewModes } from '../../../constants/app.constants'

const menuItems = {
  admin: [
    {
      url: '/programmes',
      title: 'Programmes',
      icon: IconSparkles
    },
    {
      url: '/users',
      title: 'Users',
      icon: IconUsers
    },
    {
      url: '/transactions',
      title: 'Transactions',
      icon: IconListSearch
    },
    {
      url: '/items',
      title: 'Items',
      icon: IconShoppingBag
    },
    {
      url: '/categories',
      title: 'Categories',
      icon: IconCategory
    },
  ],
  programmeManager: [
    {
      url: '',
      title: 'Dashboard',
      icon: IconLayoutBoard
    },
    {
      url: '/programmes/details',
      title: 'Programme Details',
      icon: IconInfoCircle
    },
    {
      url: '/products',
      title: 'Items',
      icon: IconShoppingBag
    },
    {
      url: '/certificates',
      title: 'Certificates',
      icon: IconFileDescription
    },
    {
      url: '/suppliers',
      title: 'Suppliers',
      icon: IconBuildingStore
    },
    {
      url: '/fieldworkers',
      title: 'Fieldworkers',
      icon: IconWalk
    },
    {
      url: '/households',
      title: 'Households',
      icon: IconHash
    },
  ],
  noProgramme: [
    {
      url: '/',
      title: 'Home',
      icon: IconHome
    },
  ],
}

export const getMenuItems = (viewMode) => {
  switch (viewMode) {
    case ViewModes.admin:
      return menuItems.admin

    case ViewModes.programme:
      return menuItems.programmeManager

    case ViewModes.noProgramme:
      return menuItems.noProgramme

    default:
      return [];
  }
}
