import { FC, PropsWithChildren } from 'react'
import { Card } from 'antd'
import './styles.scss'

const CardWide: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Card
      hoverable
      className="card card-wide default-cursor"
    >
      {children}
    </Card>
  )
}

export default CardWide
