import { useState, useCallback, createContext } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { getVoucherBatchDetails } from '../../../services/api/voucher.api'
import Tabs from '../../atoms/tabs'
import PageBackLink from '../../atoms/page-back-link'
import VoucherBatchDetailsForm from '../../organisms/voucher-batch-details-form'
import VouchersList from '../../organisms/vouchers-list'
import { voucherStatuses } from '../../../constants/voucher.constants'
import { voucherBatchStatuses } from '../../../constants/voucherBatch.constants'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'
import { VoucherBatchRaw, VoucherBatchSummary } from '../../../types/voucher'
import { getFullName } from '../../../services/helpers/helpers'

export const VoucherBatchContext = createContext<{ voucherBatch, setVoucherBatch, getVoucherBatch }>(null)

const VoucherBatchDetails = () => {
  const [voucherBatch, setVoucherBatch] = useState({})
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  const { viewContext, voucherId } = useParams()
  const [query] = useLocation().state || []

  const getVoucherUsageStats = useCallback((status: string, summary: VoucherBatchSummary, currency: string) => {
    if (!summary) {
      return []
    }

    const voucherStatusTypes = [
      voucherStatuses.available.key,
      voucherStatuses.assigned.key,
      voucherStatuses.redeemed.key,
    ]
    if (status !== voucherBatchStatuses.active.key) {
      voucherStatusTypes[0] = voucherStatuses.disabled.key
    } else {
      voucherStatusTypes.push(voucherStatuses.disabled.key)
    }

    return voucherStatusTypes.map((type) => {
      const quantity: number = summary[type] || 0

      return {
        type,
        quantity,
        monetaryValue: `${summary[`${type}Value`]} ${currency}`,
      }
    })
  }, [])

  const setVoucherBatchData = useCallback((voucher: VoucherBatchRaw) => {
    const {
      id,
      startDate,
      endDate,
      fieldworkerMessage,
      fixedValue,
      isRenewable,
      renewalType,
      merchantMessage,
      number,
      renewals,
      status,
      summary,
      totalValue,
      FieldWorker: fieldWorker,
      Programme: { currency },
    } = voucher

    setVoucherBatch({
      usageStats: getVoucherUsageStats(status, summary, currency),
      issuedTo: getFullName(fieldWorker),
      fieldworkerId: fieldWorker.id,
      totalValueAfterRenewals: voucher.totalValueAfterRenewals || totalValue,
      totalVouchers: number,
      currency,
      fieldworkerMessage,
      fixedValue,
      id,
      isRenewable,
      renewalType,
      merchantMessage,
      renewals,
      status,
      totalValue,
      startDate,
      endDate,
    })
  },
  [getVoucherUsageStats]
  )

  const getVoucherBatch = useCallback(async () => {
    if (activeProgramme && activeProgramme.id && voucherId) {
      const voucherBatchDetails = await getVoucherBatchDetails(
        activeProgramme.id,
        voucherId
      )
      if (voucherBatchDetails.hasErrors) {
        return
      }
      setVoucherBatchData(voucherBatchDetails)
    }
  }, [activeProgramme, setVoucherBatchData, voucherId])

  const tabsContent = [
    {
      title: 'Certificate Batch Details',
      component: <VoucherBatchDetailsForm />,
    },
    {
      title: 'Certificates List',
      component: <VouchersList />,
    },
  ]

  return (
    <div className="voucher-batch-details">
      <PageBackLink
        url={`/${viewContext}/certificates`}
        pageName="List"
        state={[query]}
      />
      <VoucherBatchContext.Provider
        value={{
          voucherBatch,
          setVoucherBatch: setVoucherBatchData,
          getVoucherBatch,
        }}
      >
        <Tabs tabsContent={tabsContent} />
      </VoucherBatchContext.Provider>
    </div>
  )
}

export default VoucherBatchDetails
