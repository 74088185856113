import { resetGlobalState } from '../initializers/global.initializer'
import { ApiResponse, ApiResponseData, axiosApi } from './api'
import { history } from '../../router/browserHistory'

export const logout = async () => {
  await axiosApi()
    .get('/auth/logout')

  resetGlobalState()
  history.push('/login')
}

type LoggedInResponse = {
  auth: boolean
  userId: string
  token: string
  refreshToken: string
  isLocked?: boolean
}

type LoginParams = { email: string, password: string, otp?: string }
export const login: (params: LoginParams) => Promise<ApiResponse<LoggedInResponse>> = async ({ email, password, otp }) => {
  const body: LoginParams = { email, password }

  if (otp) {
    body.otp = otp
  }

  const { data, status } = await axiosApi()
    .post<ApiResponseData<LoggedInResponse>>('/auth/login', body)
  return { data, status }
}

export const validate = async () => {
  const { data } = await axiosApi()
    .get('/auth/validate')
  return data
}

export const refreshAuthToken = async () => {
  const { data, status } = await axiosApi()
    .get('/auth/token')
  return { data, status }
}
