import { FC, useEffect, useState } from 'react'
import { Descriptions } from 'antd';
import './styles.scss'
import { Programme } from '../../../../types/programme';

const PaymentDetails: FC<{ programme: Programme }> = ({ programme }) => {
  const [currency, setCurrency] = useState('')
  const [erpProgrammeId, setERPProgrammeId] = useState('')
  const [theme, setTheme] = useState('')
  const [donor, setDonor] = useState('')
  const [client, setClient] = useState('')

  useEffect(() => {
    if (programme) {
      const {
        erpProgrammeId,
        theme,
        donor,
        client,
        currency
      } = programme
      setERPProgrammeId(erpProgrammeId)
      setTheme(theme)
      setDonor(donor)
      setClient(client)
      setCurrency(currency)
    }
  }, [programme])

  return (
    <section className="payment-details">
      <Descriptions
        column={1}
        layout="vertical"
        colon={null}
      >
        <Descriptions.Item label="ERP Programme ID">
          {erpProgrammeId || '–'}
        </Descriptions.Item>
        <Descriptions.Item label="Theme">
          {theme || '–'}
        </Descriptions.Item>
        <Descriptions.Item label="Donor">
          {donor || '–'}
        </Descriptions.Item>
        <Descriptions.Item label="Client">
          {client || '–'}
        </Descriptions.Item>
        <Descriptions.Item label="Currency">
          {currency || '–'}
        </Descriptions.Item>
      </Descriptions>
    </section>
  )
}

export default PaymentDetails
