import { FC, ReactNode } from 'react'
import { Form } from 'antd'

const FormItem: FC<{
  propertyName: string,
  fieldTitle: string,
  className?: string,
  children: ReactNode,
  required?: boolean,
}> = ({
  propertyName,
  fieldTitle,
  className,
  children,
  required = true,
}) => {
  return (
    <Form.Item
      name={propertyName}
      key={propertyName}
      className={className}
      rules={[
        {
          required: required,
          message: `Please input ${fieldTitle}!`,
        },
      ]}
    >
      {children}
    </Form.Item>
  )
}

export default FormItem
