import { useCallback, useContext, useEffect, useState } from 'react'
import { Table, message, Typography, Select } from 'antd'
import { useSelector } from 'react-redux'
import './styles.scss'
import {
  getFieldworkersForProgrammeManager,
  getProgrammesForProgrammeManager,
} from '../../../services/api/manager.api'
import { useQuery } from '../../../hooks/query.hook'
import { getPaginationTotalText } from '../../../services/helpers/helpers'
import { SelectedFieldworkerIdsContext } from '../../views/AddExistingFieldworker'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'

const AddExistingFieldworker = () => {
  const { selectedFieldworkerIds, setSelectedFieldworkerIds } = useContext(SelectedFieldworkerIdsContext)
  const [fieldWorkers, setFieldWorkers] = useState([])
  const [fieldworkersCount, setFieldworkersCount] = useState(0)
  const [programmes, setProgrammes] = useState([])
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  const { query, onPageChange, onFilter, resetQuery } = useQuery({
    pageSize: 10,
    page: 1,
  })

  useEffect(() => {
    if (!activeProgramme?.id) {
      return
    }
    getFieldworkersForProgrammeManager(activeProgramme.id, query).then((res) => {
      if (res.hasErrors) {
        message.error('Could not get the existing fieldworkers')
        return
      }
      setFieldWorkers(res.rows)
      setFieldworkersCount(res.count)
    })

    getProgrammesForProgrammeManager(activeProgramme.id).then((res) => {
      if (res.hasErrors) {
        message.error('Could not get the programmes for the current user')
        return
      }
      setProgrammes(
        res.map(programme => ({ label: programme.name, value: programme.id }))
      )
    })
  }, [activeProgramme, query])

  const onFieldworkerSelect = useCallback(
    ({ id: fieldworkerId }, isSelected) => {
      const newSelectedFieldworkerIds = [...selectedFieldworkerIds]

      if (isSelected) {
        newSelectedFieldworkerIds.push(fieldworkerId)
      } else {
        const index = selectedFieldworkerIds.indexOf(fieldworkerId)
        newSelectedFieldworkerIds.splice(index, 1)
      }
      setSelectedFieldworkerIds(newSelectedFieldworkerIds)
    },
    [selectedFieldworkerIds, setSelectedFieldworkerIds]
  )

  const onFieldworkerSelectAll = useCallback(
    (isSelected, _, affectedRows) => {
      let newSelectedFieldworkerIds = [...selectedFieldworkerIds]
      const fieldworkerIdsToChange = affectedRows.map(({ id }) => id)
      if (isSelected) {
        newSelectedFieldworkerIds = [
          ...newSelectedFieldworkerIds,
          ...fieldworkerIdsToChange,
        ]
      } else {
        newSelectedFieldworkerIds = newSelectedFieldworkerIds.filter(
          selectedFwId => fieldworkerIdsToChange.indexOf(selectedFwId) === -1
        )
      }

      setSelectedFieldworkerIds(newSelectedFieldworkerIds)
    },
    [selectedFieldworkerIds, setSelectedFieldworkerIds]
  )

  return (
    <div className="fieldworkers-add-existing">
      <div className="selected-fieldworkers-header">
        <Typography.Paragraph className="selected-fieldworkers-count">
          {selectedFieldworkerIds.length
            ? `${selectedFieldworkerIds.length} out of ${fieldworkersCount}`
            : 'No'}{' '}
          fieldworkers selected
        </Typography.Paragraph>

        <Select
          defaultValue=""
          onChange={(programmeId) => {
            if (programmeId === '') {
              resetQuery()
              return
            }
            onFilter('programmeId', programmeId)
          }}
          options={[{ label: 'From all programmes', value: '' }, ...programmes]}
        />
      </div>

      <Table
        columns={[
          {
            title: 'Name',
            dataIndex: 'fullName',
            width: '75%',
          },
          {
            title: 'E-mail address',
            dataIndex: 'email',
          },
        ]}
        dataSource={fieldWorkers}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selectedFieldworkerIds,
          onSelectAll: onFieldworkerSelectAll,
          onSelect: onFieldworkerSelect,
        }}
        pagination={{
          onChange: onPageChange,
          total: fieldworkersCount,
          pageSize: query.pageSize,
          showTotal: getPaginationTotalText,
          current: query.page,
        }}
        bordered
      />
    </div>
  )
}

export default AddExistingFieldworker
