import { useState, useEffect, useCallback, useMemo, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IconExclamationCircle, IconX } from '@tabler/icons-react'
import { message, Button, Modal } from 'antd'
import './styles.scss'
import { isAdmin } from '../../../services/helpers/user'
import { programmeStatuses } from '../../../constants/programme.constants'
import { productStatuses } from '../../../constants/product.constants'
import {
  getItemsGroupedByCategory,
  updateItemsStatuses,
} from '../../../services/api/item.api'
import Card from '../../atoms/card'
import Message from '../../atoms/message'
import ProductTable from '../../molecules/product-table'
import {
  mappings,
  mapProductName,
  mapProductUnit,
  mapProductStatus,
  parseProductTableData,
  columns,
} from '../../molecules/product-table/product-table.config'
import Icon from '../../atoms/icon'
import { messageTypes } from '../../atoms/message/messageTypes'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { UserRoles } from '../../../constants/userRoles.constants'
import { Programme } from '../../../types/programme'

const { confirm } = Modal

const ProductsList = () => {
  const { activeProgramme, currentUserRoles } = useSelector<CombinedReducers, {
    activeProgramme: Programme
    currentUserRoles: UserRoles[]
  }>(state => ({
    activeProgramme: state.programme.activeProgramme,
    currentUserRoles: state.user.userRoles,
  }))
  const [disabledMessage, setDisabledMessage] = useState<ReactNode>('')
  const [isProgrammeComplete, setIsProgrammeComplete] = useState(false)
  const [isEditListButtonDisabled, setIsEditListButtonDisabled] = useState(false)
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([])
  const [tableData, setTableData] = useState([])
  const [productStates, setProductStates] = useState([])
  const [categoriesStates, setCategoriesStates] = useState<{[categoryId: string]: boolean | null }>({})
  const [initialCategoriesStates, setInitialCategoriesStates] = useState({})
  const [isInEditMode, setIsInEditMode] = useState(false)
  const navigate = useNavigate()

  const filterProductIdsByState = useCallback(
    (products, isEnabled) =>
      products.reduce((acc, product) => {
        if (product.isEnabled === isEnabled) {
          acc.push(product.id)
        }
        return acc
      }, []),
    []
  )

  const getCategoryProductsCount = useCallback(
    (categoryId, productStates) => {
      const products = categoriesWithProducts.find(
        category => category[mappings.category.id] === categoryId
      )[mappings.category.products]
      const activeProductsCount = products.filter(product =>
        filterProductIdsByState(productStates, true).includes(product.id)
      ).length

      return {
        productsCount: products.length,
        activeProductsCount,
      }
    },
    [categoriesWithProducts, filterProductIdsByState]
  )

  const getCategoryState = useCallback(
    ({ productsCount, activeProductsCount }) => {
      if (activeProductsCount === 0) {
        return false
      }
      return activeProductsCount === productsCount ? true : null
    },
    []
  )

  const onCategoryToggle = useCallback(
    (categoryId) => {
      const categoryProductIds = categoriesWithProducts
        .find(category => category.id === categoryId)[mappings.category.products]
        .map(product => product.id)

      const newProductStates = productStates.map(product =>
        categoryProductIds.indexOf(product.id) > -1
          ? { ...product, isEnabled: !categoriesStates[categoryId] }
          : product
      )
      setProductStates(newProductStates)
      const newCategoriesState = {
        ...categoriesStates,
        [categoryId]: getCategoryState(
          getCategoryProductsCount(categoryId, newProductStates)
        ),
      }
      setCategoriesStates(newCategoriesState)
    },
    [
      categoriesStates,
      categoriesWithProducts,
      productStates,
      getCategoryProductsCount,
      getCategoryState,
    ]
  )

  const onProductStatusToggle = useCallback(
    (productId, productCategoryId) => {
      const newProductStates = [...productStates]
      const productIndex = newProductStates.findIndex(
        ({ id }) => id === productId
      )
      newProductStates[productIndex].isEnabled
        = !newProductStates[productIndex].isEnabled
      setProductStates(newProductStates)

      const newCategoriesStates = {
        ...categoriesStates,
        [productCategoryId]: getCategoryState(
          getCategoryProductsCount(productCategoryId, newProductStates)
        ),
      }
      setCategoriesStates(newCategoriesStates)
    },
    [
      categoriesStates,
      productStates,
      getCategoryProductsCount,
      getCategoryState,
    ]
  )

  const onEditList = useCallback(() => {
    setInitialCategoriesStates({ ...categoriesStates })
    setIsInEditMode(!isInEditMode)
  }, [categoriesStates, isInEditMode])

  const saveChanges = useCallback(async () => {
    setIsInEditMode(!isInEditMode)
    const res = await updateItemsStatuses(activeProgramme?.id, {
      enabledItemIds: filterProductIdsByState(productStates, true),
      disabledItemIds: filterProductIdsByState(productStates, false),
    })

    if (res.hasError) {
      message.error('Failed to update the item states.')
      return
    }
    message.success('Successfully updated item states.')
    setCategoriesWithProducts(res || [])
  }, [activeProgramme, filterProductIdsByState, isInEditMode, productStates])

  const onCancel = useCallback(() => {
    const newProductStates = productStates.map(product => ({
      ...product,
      isEnabled: product.originalState,
    }))
    setProductStates(newProductStates)
    setCategoriesStates({ ...initialCategoriesStates })
    setInitialCategoriesStates({})
    setIsInEditMode(!isInEditMode)
  }, [initialCategoriesStates, isInEditMode, productStates])

  const onSave = useCallback(() => {
    const wereProductsDisabled = !!productStates.find(
      product =>
        product.isEnabled === false
        && product.isEnabled !== product.originalState
    )
    if (wereProductsDisabled) {
      confirm({
        title: 'Are you sure you want to remove products from the programme?',
        icon: <Icon component={IconExclamationCircle} />,
        content: 'When you remove products from a programme, they become unavailable for all suppliers to sell.',
        okText: 'Remove',
        okType: 'danger',
        async onOk() {
          await saveChanges()
        },
      })
      return
    }
    saveChanges()
  }, [productStates, saveChanges])

  useEffect(() => {
    if (activeProgramme) {
      const status = activeProgramme.status as string
      const statusesWithDisabledState = [
        programmeStatuses.archived.key,
        programmeStatuses.draft.key,
      ]
      if (statusesWithDisabledState.includes(status)) {
        setIsEditListButtonDisabled(true)
        setDisabledMessage(
          <>
            The editing of the products list is suspended while the programme
            status is <b>{status.toLocaleUpperCase()}</b>
          </>
        )
      }
      if (status === programmeStatuses.completed.key) {
        setIsProgrammeComplete(true)
      }
    }
  }, [activeProgramme])

  useEffect(() => {
    if (activeProgramme) {
      if (!activeProgramme.id) {
        navigate('/')
        return
      }

      getItemsGroupedByCategory(activeProgramme.id).then((res) => {
        if (!res.hasError) {
          setCategoriesWithProducts(res || [])
        }
      })
    }
  }, [activeProgramme, navigate])

  useEffect(() => {
    if (!categoriesWithProducts.length) {
      return
    }

    const isProductEnabled = (product) => {
      return product.status === productStatuses.active
    }

    const { newCategoriesStates, newProductStates }
      = categoriesWithProducts.reduce(
        (acc, category) => {
          let activeProductsCount = 0

          category[mappings.category.products].forEach((product) => {
            acc.newProductStates.push({
              id: product[mappings.product.id],
              isEnabled: isProductEnabled(product),
              originalState: isProductEnabled(product),
            })
            if (isProductEnabled(product)) {
              activeProductsCount += 1
            }
          })

          acc.newCategoriesStates[category.id] = getCategoryState({
            productsCount: category[mappings.category.products].length,
            activeProductsCount,
          })
          return acc
        },
        {
          newCategoriesStates: {},
          newProductStates: [],
        }
      )

    setCategoriesStates(newCategoriesStates)
    setProductStates(newProductStates)
  }, [getCategoryState, categoriesWithProducts])

  useEffect(() => {
    if (categoriesWithProducts.length) {
      const mapProduct = (product, productCategoryId) => {
        return {
          id: product.id,
          item: mapProductName(product),
          unit: mapProductUnit(product),
          status: mapProductStatus(
            product,
            productCategoryId,
            isInEditMode,
            filterProductIdsByState(productStates, true),
            onProductStatusToggle
          ),
        }
      }

      setTableData(
        parseProductTableData(
          categoriesWithProducts,
          mapProduct,
          categoriesStates,
          onCategoryToggle,
          isInEditMode
        )
      )
    }
  }, [
    categoriesStates,
    categoriesWithProducts,
    filterProductIdsByState,
    isInEditMode,
    onCategoryToggle,
    onProductStatusToggle,
    productStates,
  ])

  const actionButtons = useMemo(() => {
    return (
      isInEditMode ? (
        <>
          <Button
            size="large"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </>
      ) : (
        !isAdmin(currentUserRoles)
        && !isProgrammeComplete && (
          <Button
            size="large"
            type="primary"
            onClick={onEditList}
            disabled={isEditListButtonDisabled}
          >
            Edit list
          </Button>
        )
      )
    )
  }, [currentUserRoles, isEditListButtonDisabled, isInEditMode, isProgrammeComplete, onCancel, onEditList, onSave])

  return (
    <div className="product-list">
      {actionButtons && (
        <div className="product-list__actions">
          {actionButtons}
        </div>
      )}

      {!!disabledMessage && (
        <div className="global-disabled-message">
          <Message
            type={messageTypes.suspend}
            icon={<Icon component={IconX} />}
            onClick={() => setDisabledMessage('')}
          >
            {disabledMessage}
          </Message>
        </div>
      )}

      <Card>
        {tableData && (
          <ProductTable
            data={tableData}
            columns={columns}
            hasSections
          />
        )}
      </Card>
    </div>
  )
}

export default ProductsList
