import ct from 'countries-and-timezones';

export const getFormattedTimezone = timezone => `${timezone.replaceAll('_', ' ')} (UTC${ct.getTimezone(timezone).utcOffsetStr})`

export const getFormattedCountryAndTimezone: ([country, timezone]: [string, string]) => string = ([country, timezone]) => {
  const countryText = ct.getCountry(country)?.name
  const timezoneText = timezone && getFormattedTimezone(timezone)

  const countryAndTimezone = timezoneText
    ? `${countryText} - ${timezoneText}`
    : countryText

  return countryAndTimezone || country
}
