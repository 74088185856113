import { ApiResponseData, axiosApi } from './api'
import { userStatuses } from '../../constants/user.constants';
import { Query } from '../../hooks/query.hook';
import { Fieldworker } from '../../types/user';

export const getFieldworkers = async (programmeId: string, params?: Query) => {
  const { data } = await axiosApi()
    .get<ApiResponseData<{ fieldWorkers: Fieldworker[], count: number }>>(
      `/programmes/${programmeId}/fieldworkers`,
      { params }
    )
  return data
}

export const addFieldworker = async (payload) => {
  const { data } = await axiosApi()
    .post(
      '/fieldworkers',
      payload
    )
  return data
}

export const getFieldworker = async (programmeId,
  fieldworkerId) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/fieldworkers/${fieldworkerId}`
    )
  return data
}

export const setFieldworkerStatus = async (
  fieldworkerId,
  programmeId,
  status
) => {
  const getStatusPath = (status) => {
    switch (status) {
      case userStatuses.active.key:
        return 'enable'

      case userStatuses.disabled.key:
        return 'disable'

      default:
        break;
    }
  }
  const { data } = await axiosApi()
    .patch(
      `/fieldworkers/${fieldworkerId}/programmes/${programmeId}/${getStatusPath(status)}`
    )
  return data
}

export const assignFieldworkersToProgramme = async (programmeId,
  payload) => {
  const { data } = await axiosApi()
    .post(
      `/programmes/${programmeId}/fieldworkers`,
      payload
    )
  return data
}
