import { IconCheck, IconX } from '@tabler/icons-react'
import { Button, ButtonProps, Popconfirm, PopconfirmProps, Tooltip } from 'antd'
import { FC } from 'react'
import './styles.scss'
import Icon from '../../atoms/icon'

const ConfirmationButtons: FC<{
  isSaveDisabled?: boolean
  cancelConfirmationMessage?: string
  buttonSize?: ButtonProps['size']
  onCancel: PopconfirmProps['onCancel'],
  onSave: ButtonProps['onClick']
}> = ({
  isSaveDisabled = false,
  cancelConfirmationMessage = 'Are you sure you want to cancel?',
  buttonSize = 'large',
  onCancel,
  onSave,
}) => {
  return (
    <div className="confirmation-actions">
      <Tooltip
        title="Cancel"
        placement="bottom"
      >
        <Popconfirm
          title={cancelConfirmationMessage}
          onConfirm={onCancel}
          placement="top"
        >
          <Button
            className="confirmation-actions__cancel"
            shape="circle"
            size={buttonSize}
            icon={<Icon component={IconX} />}
          />
        </Popconfirm>
      </Tooltip>
      <Tooltip
        title="Save"
        placement="bottom"
      >
        <Button
          className="confirmation-actions__save"
          htmlType="submit"
          shape="circle"
          size={buttonSize}
          type="primary"
          icon={<Icon component={IconCheck} />}
          onClick={onSave}
          disabled={isSaveDisabled}
        />
      </Tooltip>
    </div>
  )
}

export default ConfirmationButtons
