import { Typography } from 'antd'
import { FC } from 'react';
import Highlighter from 'react-highlight-words';

const { Text } = Typography

const HighlightedText: FC<{
  contentText: string | number
  searchWords: string | number
}> = ({ contentText, searchWords }) => (
  <Text>
    <Highlighter
      highlightStyle={{
        padding: 0,
      }}
      searchWords={[searchWords]}
      autoEscape
      textToHighlight={contentText ? contentText.toString() : ''}
    />
  </Text>
)

export default HighlightedText
