import {
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import { useSelector } from 'react-redux'
import { message, Typography, Input, Descriptions } from 'antd'
import './styles.scss'
import { SortDirections, useQuery } from '../../../hooks/query.hook'
import { getVouchers as getVouchersApiReqest } from '../../../services/api/voucher.api'
import { getPaginationTotalText } from '../../../services/helpers/helpers'
import { VoucherBatchContext } from '../../views/VoucherBatchDetails'
import VouchersTable from '../../molecules/vouchers-table'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'

const { Search } = Input

const VouchersList = () => {
  const {
    voucherBatch: {
      id: voucherBatchId,
      fixedValue: voucherValue,
      totalVouchers: totalVouchersInBatch,
      issuedTo: fieldworkerName,
    },
  } = useContext(VoucherBatchContext)
  const [vouchers, setVouchers] = useState([])
  const [vouchersCount, setVouchersCount] = useState(totalVouchersInBatch)
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)
  const { query, onPageChange, onSearch } = useQuery({
    orderType: SortDirections.ascend,
    pageSize: 10,
    page: 1,
  })

  const getVouchers = useCallback(async () => {
    const vouchersResponse = await getVouchersApiReqest(
      activeProgramme.id,
      voucherBatchId,
      query
    )
    if (vouchersResponse.hasErrors) {
      message.error('Could not get the certificates')
      return
    }
    if (vouchersResponse.count !== vouchersCount) {
      setVouchersCount(vouchersResponse.count)
    }
    setVouchers(vouchersResponse.vouchers)
  }, [activeProgramme, query, voucherBatchId, vouchersCount])

  useEffect(() => {
    getVouchers()
  }, [getVouchers])

  return (
    <div className="vouchers-list">
      <section className="header-controls">
        <Search
          allowClear
          placeholder="Certificate #"
          onSearch={value => onSearch(value, 'index')}
        />
        <Descriptions
          bordered
          size="small"
        >
          <Descriptions.Item label="Certificate value">
            <Typography.Text strong>
              {voucherValue} {activeProgramme.currency}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      </section>

      <VouchersTable
        vouchers={vouchers}
        voucherBatchId={voucherBatchId}
        searchQuery={query.index}
        programme={activeProgramme}
        onOperationCompleted={getVouchers}
        fieldworkerName={fieldworkerName}
        tableProps={{
          bordered: true,
          pagination: {
            onChange: onPageChange,
            total: vouchersCount,
            pageSize: query.pageSize,
            showTotal: getPaginationTotalText,
            current: query.page,
            showSizeChanger: false
          }
        }}
      />
    </div>
  )
}

export default VouchersList
