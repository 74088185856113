import { useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import TransactionTable from '../../molecules/transaction-table'
import { mapActions, defaultQuery } from '../../molecules/transaction-table/transaction-table.config'
import Card from '../../atoms/card'
import InfoSvg from '../../atoms/icons/info-svg'
import { getAdminTransactions } from '../../../services/api/transaction.api'
import { useQuery } from '../../../hooks/query.hook'
import TransactionDetailsModal from '../../molecules/transaction-details-modal'
import { transactionTypes } from '../../../constants/transactions.constants'
import { mapTransaction } from '../../../services/helpers/transaction'
import { capitalizeFirstLetter } from '../../../services/helpers/helpers'
import { handleSorting } from '../../../services/helpers/table'
import { ColumnsType } from 'antd/es/table'

const TransactionsList = () => {
  const { query, onPageChange, onFilters, onSort } = useQuery(defaultQuery)
  const [transactions, setTransactions] = useState([])
  const [transactionsCount, setTransactionsCount] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentTransaction, setCurrentTransaction] = useState(null)

  useEffect(() => {
    getAdminTransactions(query).then((res) => {
      if (!res.hasError && res.rows) {
        setTransactions(
          res.rows.map((transaction) => {
            const mappedTransaction = mapTransaction(transaction)

            mappedTransaction.details = mapActions([
              {
                onClick: () => {
                  setCurrentTransaction({
                    ...mappedTransaction,
                    date: dayjs(transaction.createdAt).format('LLL'),
                  })
                  setIsModalOpen(true)
                },
                icon: <InfoSvg />,
              },
            ])

            return mappedTransaction
          })
        )

        if (res.count !== transactionsCount) {
          setTransactionsCount(res.count || null)
        }
      }
    })
  }, [query, transactionsCount])

  const columns: ColumnsType = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'createdAt',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: Object.keys(transactionTypes).map(
        transactionType => ({
          text: capitalizeFirstLetter(transactionTypes[transactionType].label),
          value: transactionTypes[transactionType].key,
        })
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'value',
      sorter: true,
    },
    {
      title: 'Project manager',
      dataIndex: 'projectManager',
      key: 'projectManager',
      ellipsis: true,
    },
    {
      title: 'Programme name',
      dataIndex: 'programmeName',
      key: 'programmeName',
      ellipsis: true,
    },
    {
      title: 'Programme identifier',
      dataIndex: 'programmeIdentifier',
      key: 'programmeIdentifier',
      ellipsis: true,
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
    },
  ]

  const onTableChange = useCallback(
    (_, filters, sorter, extra) => {
      if (extra.action === 'filter') {
        const mappedFilters = Object.keys(filters).map((filterName) => {
          return {
            name: filterName,
            value: filters[filterName]
          }
        })
        onFilters(mappedFilters)
      }

      handleSorting(onSort, extra.action, sorter, defaultQuery)
    },
    [onFilters, onSort],
  )
  return (
    <>
      <Card>
        <TransactionTable
          columns={columns}
          presetQuery={query}
          transactions={transactions}
          transactionsCount={transactionsCount}
          onPageChange={onPageChange}
          onTableChange={onTableChange}
        />
      </Card>

      <TransactionDetailsModal
        transaction={currentTransaction}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  )
}

export default TransactionsList
