import { refreshAuthToken, validate } from '../api/auth.api'
import { getUserDetails } from '../api/user.api'
import store from '../../redux/store'
import {
  setUserDetails,
  setUserRole,
  setIsLoggedIn,
  setIsInitialRequestDone,
} from '../../redux/stores/user/user.actions'

const { dispatch } = store

export const storeUserData = async (userId: string) => {
  const userDetails = await getUserDetails(userId)

  dispatch(setUserDetails(userDetails))
  dispatch(setUserRole(userDetails.roles))
  dispatch(setIsLoggedIn(true))
}

export const validateToken = async () => {
  let validationResponse = await validate()

  // refresh token and retry validating it
  if (!validationResponse.auth) {
    await refreshAuthToken()
    validationResponse = await validate()
  }

  if (validationResponse.auth) {
    await storeUserData(validationResponse.userId)
  } else {
    dispatch(setIsLoggedIn(false))
  }
  dispatch(setIsInitialRequestDone(true))
}
