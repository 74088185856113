import { useState, useEffect, useCallback, FC } from 'react'
import { useSelector } from 'react-redux'
import { Button, message, Typography } from 'antd'
import { IconSquareMinus, IconSquarePlus } from '@tabler/icons-react';
import dayjs from 'dayjs';
import './styles.scss'
import { Query, SortDirections, useQuery } from '../../../hooks/query.hook'
import Icon from '../../atoms/icon';
import VouchersTable from '../vouchers-table';
import { CombinedReducers } from '../../../redux/stores/reducers';
import { Programme } from '../../../types/programme';
import { ColumnsType } from 'antd/es/table';
import { Voucher } from '../../../types/voucher';
import { getProgrammeHouseholds } from '../../../services/api/programme.api';
import { Household } from '../../../types/household';
import Table from '../../atoms/table';
import { getNumberOfPages } from '../../../services/helpers/table';

const { Text } = Typography

const defaultQuery: Query = {
  order: 'createdAt',
  orderType: SortDirections.descend,
  pageSize: 10,
  page: 1,
}

const HouseholdsTable: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const [households, setHouseholds] = useState<Household[]>([])
  const [numberOfPages, setNumberOfPages] = useState(1)

  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  const {
    query,
    onSearch,
    onSort,
    onPageChange,
  } = useQuery(defaultQuery)

  useEffect(() => {
    if (searchQuery !== query.search) {
      onSearch(searchQuery)
    }
  }, [searchQuery])

  const getData = useCallback(async () => {
    if (activeProgramme) {
      const householdsRes = await getProgrammeHouseholds(activeProgramme.id, query)

      if (householdsRes.hasErrors) {
        message.error('Failed to obtain the households')
      }

      setNumberOfPages(getNumberOfPages(householdsRes.count, query.pageSize))
      setHouseholds(householdsRes.rows)
    }
  }, [activeProgramme, query])

  useEffect(() => {
    getData()
  }, [activeProgramme, getData, query])

  const tableColumns: ColumnsType = [
    {
      title: 'Household ID',
      key: 'number',
      dataIndex: 'number',
      sorter: true
    },
    {
      title: 'Certificates',
      key: 'certificates',
      dataIndex: 'vouchers',
      ellipsis: true,
      render: (vouchers: Voucher[]) => {
        return vouchers.map((voucher, index, array) => (
          <>
            <Text key={voucher.index}>{voucher.index}</Text>
            {index < array.length - 1 && ', '}
          </>
        ))
      },
    },
    {
      title: 'Created on',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: true,
      render: value => <Text>{dayjs(value).format('L')}</Text>
    },
  ]

  return (
    <div className="households-table">
      <Table
        data={households}
        columns={tableColumns}
        expandable={{
          columnWidth: '70px',
          expandRowByClick: true,
          expandedRowRender: (householdRecord: Household, index) => (
            <VouchersTable
              vouchers={households[index].vouchers}
              searchQuery={query.search}
              programme={activeProgramme}
              onOperationCompleted={getData}
              highlightSearchResult={false}
              showFieldworkerColumn
            />
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Button
                icon={(
                  <Icon
                    component={IconSquareMinus}
                    componentProps={{ strokeWidth: 1 }}
                  />
                )}
                type="text"
                onClick={e => onExpand(record, e)}
              />
            ) : (
              <Button
                icon={(
                  <Icon
                    component={IconSquarePlus}
                    componentProps={{ strokeWidth: 1 }}
                  />
                )}
                type="text"
                onClick={e => onExpand(record, e)}
              />
            )
        }}
        pagination={{
          pageSize: query.pageSize,
          numberOfPages,
          onPageChange,
          page: query.page
        }}
        onSort={onSort}
      />
    </div>
  )
}

export default HouseholdsTable
