import { FC, MouseEventHandler } from 'react'
import { Button } from 'antd'
import EmailInput from '../../atoms/email-input'
import PasswordInput from '../../atoms/password-input'
import { Link } from 'react-router-dom'

const LoginStep: FC<{
  email: string,
  password: string,
  errorMessage: string,
  onEmailChange: (res: string) => void,
  onPasswordChange: (res: string) => void,
  onSubmit: MouseEventHandler,
}> = ({
  email,
  password,
  errorMessage,
  onEmailChange,
  onPasswordChange,
  onSubmit,
}) => {
  return (
    <>
      <div className="otp__form-elements">
        <EmailInput
          value={email}
          error={errorMessage}
          placeholder="Email address"
          handleChange={e => onEmailChange(e.target.value)}
        />
        <PasswordInput
          value={password}
          error={errorMessage}
          placeholder="Password"
          handleChange={e => onPasswordChange(e.target.value)}
        />

        <Button
          onClick={onSubmit}
          type="primary"
          htmlType="submit"
          className="submit_button"
          size="large"
        >
          Log in
        </Button>
      </div>

      <Link to="/reset-password">Forgot password?</Link>
    </>
  )
}

export default LoginStep
