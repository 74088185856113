import { IconAlertTriangle, IconBan, IconBuildingStore, IconDotsVertical, IconInfoCircle } from '@tabler/icons-react'
import { Dropdown, message, Modal, Space, Typography } from 'antd'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'
import { Dispatch, FC, SetStateAction } from 'react'
import { VoucherDetailsModalData } from '.'
import { voucherStatuses } from '../../../constants/voucher.constants'
import { changeVoucherAllocatedMerchant } from '../../../services/api/merchant.api'
import { disableVoucher, getVoucherHistory } from '../../../services/api/voucher.api'
import { Voucher } from '../../../types/voucher'
import Icon from '../../atoms/icon'
import { SelectMerchantModalProps } from '../select-merchant-modal'

const { Text } = Typography

const ActionsDropdown: FC<{
  record: Voucher,
  programmeId: string,
  isProgrammeOnline: boolean,
  fieldworkerName: string,
  householdNumber?: string,
  voucherBatchId: string,
  onOperationCompleted: () => void,
  setIsVoucherDetailsModalOpen: Dispatch<SetStateAction<boolean>>,
  setVoucherDetailsModalData: Dispatch<SetStateAction<VoucherDetailsModalData>>,
  setIsChangeMerchantModalOpen: Dispatch<SetStateAction<boolean>>,
  setChangeMerchantModalData: Dispatch<SetStateAction<SelectMerchantModalProps>>,
}> = ({
  record,
  programmeId,
  isProgrammeOnline,
  fieldworkerName,
  householdNumber,
  voucherBatchId,
  onOperationCompleted,
  setIsVoucherDetailsModalOpen,
  setVoucherDetailsModalData,
  setIsChangeMerchantModalOpen,
  setChangeMerchantModalData,
}) => {
  const iconComponentProps = {
    size: '1.2rem',
  }

  const onInfoClick = async () => {
    const voucherHistoryRes = await getVoucherHistory(programmeId, record.id)
    setIsVoucherDetailsModalOpen(true)
    setVoucherDetailsModalData({
      title: `Certificate #${record.index}`,
      certificateDetails: [
        {
          label: 'Household number',
          value: householdNumber || 'N/A'
        },
        {
          label: 'Renewals',
          value: voucherHistoryRes.renewals.total
            ? `${voucherHistoryRes.renewals.current} of ${voucherHistoryRes.renewals.total}`
            : 'N/A'
        },
        {
          label: 'Fieldworker',
          value: fieldworkerName
        }
      ],
      redemptions: voucherHistoryRes.redemptions
    })
  }

  const onChangeMerchantClick = async () => {
    const closeModal = () => {
      setIsChangeMerchantModalOpen(false)
      setChangeMerchantModalData(null)
    }

    setIsChangeMerchantModalOpen(true)
    setChangeMerchantModalData({
      title: 'Change supplier',
      programmeId: programmeId,
      currentMerchant: record.Merchant,
      onOk: async (merchantId) => {
        const res = await changeVoucherAllocatedMerchant(programmeId, record.id, merchantId)
        if (res.hasErrors) {
          message.error('Failed to change the supplier.')
          return
        }
        message.success('Successfully changed the supplier')
        closeModal()
        onOperationCompleted()
      },
      onCancel: closeModal
    })
  }

  const onDisableClick = async () => {
    const onOk = async() => {
      const disableVoucherResponse = await disableVoucher(
        programmeId,
        voucherBatchId,
        record.id
      )
      if (disableVoucherResponse.hasErrors) {
        message.error(
          <>
            Failed to disable the certificate with the number{' '}
            <Text strong>{record.index}</Text>.
          </>
        )
        return
      }
      message.success(
        <>
          Successfully disabled the certificate with the number{' '}
          <Text strong>{record.index}</Text>.
        </>
      )
      onOperationCompleted()
    }

    Modal.confirm({
      title: 'Deactivate certificate',
      icon: <Icon component={IconAlertTriangle} />,
      content: `Are you sure you want to deactivate the certificate #${record.index}`,
      okText: 'Yes',
      cancelText: 'No',
      onOk
    });
  }

  const menuItems: MenuItemType[] = [
    {
      label: 'See details',
      key: 'seeDetails',
      onClick: onInfoClick,
      icon: (
        <Icon
          component={IconInfoCircle}
          componentProps={iconComponentProps}
        />
      ),
    }
  ]

  if (!isProgrammeOnline && (
    record.status === voucherStatuses.assigned.key
      || record.status === voucherStatuses.redeemed.key
  )) {
    menuItems.push(
      {
        label: 'Change supplier',
        key: 'changeMerchant',
        onClick: onChangeMerchantClick,
        icon: (
          <Icon
            component={IconBuildingStore}
            componentProps={iconComponentProps}
          />
        )
      })
  }

  if (record.status !== voucherStatuses.disabled.key) {
    menuItems.push({
      label: 'Deactivate',
      key: 'deactivate',
      danger: true,
      onClick: onDisableClick,
      icon: (
        <Icon
          component={IconBan}
          componentProps={iconComponentProps}
        />
      )
    })
  }

  return (
    <Space>
      <Dropdown
        menu={{
          items: menuItems
        }}
        trigger={['click']}
      >
        <Icon component={IconDotsVertical} />
      </Dropdown>
    </Space>
  )
}

export default ActionsDropdown
