import { FC } from 'react'
import TextInput from '../text-input/index'
import './styles.scss'

type EmailInputProps = {
  label?: string
  value: string
  placeholder: string
  error: string
  handleChange?
  autoComplete?
  autoCorrect?
  autoCapitalize?
  spellCheck?
}
const EmailInput: FC<EmailInputProps> = (props) => {
  return (
    <TextInput
      type="email"
      label={props.label || 'Email'}
      value={props.value}
      className="__email-input"
      placeholder={props.placeholder}
      error={props.error || ''}
      handleChange={props.handleChange}
      autoComplete={props.autoComplete || 'off'}
      autoCorrect={props.autoCorrect || 'off'}
      autoCapitalize={props.autoCapitalize || 'off'}
      spellCheck={props.spellCheck || 'false'}
    />
  )
}

export default EmailInput
