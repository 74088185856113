import { IconInfoCircle } from '@tabler/icons-react'
import {
  Button, DatePicker,
  Form,
  Input, InputNumber, message,
  Modal,
  Radio, Select, Typography
} from 'antd'
import dayjs from 'dayjs'
import { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { apiRequestDateFormat, currencies, rangePickerDateFormat } from '../../../constants/general'
import { IsBiometricEnabled, IsEnrollmentEnabled, IsInvoicingEnabled, programmeStatuses } from '../../../constants/programme.constants'
import { addProgrammes, assignManager } from '../../../services/api/programme.api'
import { numberInputFormatter, numberInputParser } from '../../../services/helpers/helpers'
import { BaseProgramme } from '../../../types/programme'
import { updateProgrammeSelectorData } from '../../../services/initializers/programmes.initializer'
import Icon from '../../atoms/icon'
import SelectCountryAndTimezone from '../../atoms/select-country-and-timezone'
import SelectUser from '../../atoms/select-user'
import { getProgrammeManagerPickerOptions } from '../../atoms/select-user/optionsMappers'
import './styles.scss'
import { EditProgrammeFormData } from '../programme-details-form'

const { RangePicker } = DatePicker
const { confirm } = Modal
const { Text, Paragraph } = Typography

type CreateProgrammeFormData = EditProgrammeFormData & {
  erpProgrammeId: string
  theme: string
  donor: string
  client: string
}

const CreateProgrammeForm: FC = () => {
  const [form] = Form.useForm<CreateProgrammeFormData>()
  const { viewContext } = useParams()
  const navigate = useNavigate()
  const isOnline = Form.useWatch('isOnline', form)

  const getDatepickerDisabledDates = useCallback(
    currentDate => currentDate < dayjs().startOf('day'),
    []
  )

  const assignProgrammeManager = useCallback(
    async (programmeId, programmeManagerId) => {
      const assignRes = await assignManager(programmeId, programmeManagerId)
      if (assignRes.hasErrors) {
        throw new Error(assignRes.message)
      }
    },
    []
  )

  const createProgramme = useCallback(
    async (programmeData: BaseProgramme, programmeManagerId?: string) => {
      try {
        const programmeResponse = await addProgrammes(programmeData)
        if (programmeResponse.hasErrors) {
          throw new Error(programmeResponse.message)
        }

        if (programmeManagerId) {
          await assignProgrammeManager(programmeResponse.id, programmeManagerId)
        }

        await updateProgrammeSelectorData()

        navigate(`/${viewContext}/programmes`, {
          state: {
            message: {
              type: 'success',
              text: 'Successfully created the new programme',
            },
          },
        })
      } catch (error) {
        message.error(error.message)
      }
    },
    [assignProgrammeManager, navigate, viewContext]
  )

  const onSubmitForm = useCallback(
    async ({
      isOnline,
      isEnrollmentEnabled,
      isBiometricEnabled,
      isInvoicingEnabled,
      programmeName,
      programmeManager,
      currency,
      programmeValue,
      programmeDuration,
      erpProgrammeId,
      countryAndTimezone,
      theme,
      donor,
      client,
    }) => {
      const programmeData: BaseProgramme = {
        isOnline,
        isEnrollmentEnabled,
        isBiometricEnabled,
        isInvoicingEnabled,
        name: programmeName,
        currency,
        entitlements: programmeValue,
        startDate: programmeDuration[0]
          .startOf('day')
          .format(apiRequestDateFormat),
        endDate: programmeDuration[1]
          .startOf('day')
          .format(apiRequestDateFormat),
        erpProgrammeId,
        country: countryAndTimezone[0],
        timezone: countryAndTimezone[1],
        theme,
        donor,
        client,
      }
      if (!programmeManager) {
        confirm({
          title: 'You have not assigned a programme manager',
          icon: <Icon component={IconInfoCircle} />,
          content: (
            <>
              <Paragraph>
                The programme will be created having the status{' '}
                <Text strong>{programmeStatuses.draft.label}</Text> and it can
                be activated after a Programme Manager has been assigned.
              </Paragraph>
              <Paragraph>
                Do you wish to continue creating the programme?
              </Paragraph>
            </>
          ),
          onOk: async () => {
            await createProgramme(programmeData)
          },
          onCancel: () => { },
        })
        return
      }
      await createProgramme(programmeData, programmeManager.value)
    },
    [createProgramme]
  )

  return (
    <div className="create-programme-form__wrapper">
      <Form
        form={form}
        name="createProgramme"
        autoComplete="on"
        onFinish={onSubmitForm}
        initialValues={{
          isOnline: true,
          isEnrollmentEnabled: true,
          isBiometricEnabled: true,
          isInvoicingEnabled: false
        }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelWrap
        size="large"
      >
        <Form.Item
          label="Programme type"
          name="isOnline"
          rules={[
            {
              required: true,
              message: 'Please select a programme type.',
            },
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            options={[
              {
                label: 'Online',
                value: true,
              },
              {
                label: 'Offline',
                value: false,
              },
            ]}
          />
        </Form.Item>

        {isOnline && (
          <Form.Item
            label="Certificates enrollment"
            name="isEnrollmentEnabled"
            rules={[
              {
                required: true,
                message: 'Please select an option.',
              },
            ]}
          >
            <Radio.Group
              buttonStyle="solid"
              optionType="button"
              options={[
                {
                  label: IsEnrollmentEnabled.mandatory,
                  value: true,
                },
                {
                  label: IsEnrollmentEnabled.optional,
                  value: false,
                },
              ]}
            />
          </Form.Item>
        )}

        <Form.Item
          label="Biometric verification"
          name="isBiometricEnabled"
          rules={[
            {
              required: true,
              message: 'Please select an option.',
            },
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            options={[
              {
                label: IsBiometricEnabled.enabled,
                value: true,
              },
              {
                label: IsBiometricEnabled.disabled,
                value: false,
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Invoicing"
          name="isInvoicingEnabled"
          rules={[
            {
              required: true,
              message: 'Please select an option.',
            },
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            options={[
              {
                label: IsInvoicingEnabled.on,
                value: true,
              },
              {
                label: IsInvoicingEnabled.off,
                value: false,
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Programme name"
          name="programmeName"
          rules={[
            {
              required: true,
              message: 'Please specify the programme name.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Programme manager"
          name="programmeManager"
        >
          <SelectUser
            fetchOptions={searchText =>
              getProgrammeManagerPickerOptions(searchText)
            }
            style={{
              width: '100%',
            }}
          />
        </Form.Item>

        <Form.Item
          label="Programme duration"
          name="programmeDuration"
          rules={[
            {
              required: true,
              message: 'Please specify the programme duration.',
            },
          ]}
        >
          <RangePicker
            format={rangePickerDateFormat}
            disabledDate={getDatepickerDisabledDates}
          />
        </Form.Item>

        <Form.Item label="Renewal date">
          <Typography.Text
            className="ant-form-text"
            strong
          >
            1st day of the month until the end of the programme
          </Typography.Text>
        </Form.Item>

        <Form.Item
          label="Currency"
          name="currency"
          rules={[
            {
              required: true,
              message: 'Please select a currency.',
            },
          ]}
        >
          <Select options={currencies} />
        </Form.Item>

        <Form.Item
          label="Programme budget"
          name="programmeValue"
          rules={[
            {
              required: true,
              message: 'Please specify the programme budget.',
            },
            {
              validator: (_, value) =>
                Number.isInteger(value)
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error('The programme budget must be an integer.')
                  ),
            },
          ]}
        >
          <InputNumber
            min={1}
            max={Number.MAX_SAFE_INTEGER}
            formatter={numberInputFormatter}
            parser={numberInputParser}
          />
        </Form.Item>

        <Form.Item
          label="Country and timezone"
          name="countryAndTimezone"
          rules={[
            {
              required: true,
              message: 'Please specify the programme country and timezone.',
            },
          ]}
        >
          <SelectCountryAndTimezone />
        </Form.Item>

        <Form.Item
          label="ERP programme ID"
          name="erpProgrammeId"
          rules={[
            {
              required: true,
              message: 'Please specify the ERP programme ID.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Theme"
          name="theme"
          rules={[
            {
              required: true,
              message: 'Please specify the programme theme.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Donor"
          name="donor"
          rules={[
            {
              required: true,
              message: 'Please specify the programme donor.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Client"
          name="client"
          rules={[
            {
              required: true,
              message: 'Please specify the programme client.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <div className="form-buttons">
          <Form.Item
            wrapperCol={{
              offset: 10,
              span: 14,
            }}
          >
            <Button
              type="default"
              onClick={() => navigate(`/${viewContext}/programmes`)}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
            >
              Create
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default CreateProgrammeForm
