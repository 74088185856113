import { createContext, useCallback, useState } from 'react'
import { Button, ButtonProps, message } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import './styles.scss'
import AddExistingFieldworkers from '../../organisms/add-existing-fieldworkers'
import PageBackLink from '../../atoms/page-back-link'
import Tabs from '../../atoms/tabs'
import { assignFieldworkersToProgramme } from '../../../services/api/fieldworker.api'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'

export const SelectedFieldworkerIdsContext = createContext<{ selectedFieldworkerIds, setSelectedFieldworkerIds }>(null)

const AddExistingFieldworker = () => {
  const [selectedFieldworkerIds, setSelectedFieldworkerIds] = useState([])
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)
  const { viewContext } = useParams()
  const navigate = useNavigate()

  const defaultActionButtonProps: ButtonProps = {
    type: 'default',
    size: 'large',
    disabled: !selectedFieldworkerIds.length,
  }

  const tabsContent = [
    {
      title: 'Add existing user',
      component: <AddExistingFieldworkers />,
    },
  ]

  const onAdd = useCallback(async () => {
    const res = await assignFieldworkersToProgramme(activeProgramme.id, {
      ids: selectedFieldworkerIds,
    })
    if (res.hasErrors) {
      message.error('Could not add the fieldworkers to the programme.')
      return
    }
    navigate(`/${viewContext}/fieldworkers`, {
      state: {
        message: {
          type: 'success',
          text: 'Successfully added the fieldworkers to the programme',
        },
      },
    })
  }, [activeProgramme, selectedFieldworkerIds, navigate, viewContext])

  return (
    <>
      <div className="fieldworkers-add-existing__wrapper">
        <div className="fieldworkers-add-existing__navigation">
          <PageBackLink
            url={`/${viewContext}/fieldworkers`}
            pageName="List"
          />
          <div className="fieldworkers-add-existing__actions">
            <Button
              {...defaultActionButtonProps}
              onClick={() => setSelectedFieldworkerIds([])}
            >
              Cancel
            </Button>
            <Button
              {...defaultActionButtonProps}
              onClick={onAdd}
              type="primary"
            >
              Add
            </Button>
          </div>
        </div>

        <SelectedFieldworkerIdsContext.Provider
          value={{ selectedFieldworkerIds, setSelectedFieldworkerIds }}
        >
          <Tabs tabsContent={tabsContent} />
        </SelectedFieldworkerIdsContext.Provider>
      </div>
    </>
  )
}

export default AddExistingFieldworker
