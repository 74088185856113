import merchantStatusColors from '../styles/statusColors/_merchant.module.scss'
import { Status } from '../types/generic'

export type MerchantStatuses = {
  accepted: Status,
  active: Status,
  approved: Status,
  disabled: Status,
  invited: Status,
  pending: Status,
  rejected: Status,
}

export const merchantStatuses: MerchantStatuses = {
  accepted: {
    key: 'accepted',
    label: 'accepted',
    color: merchantStatusColors.active,
  },
  active: {
    key: 'active',
    label: 'active',
    color: merchantStatusColors.active,
  },
  approved: {
    key: 'approved',
    label: 'approved',
    color: merchantStatusColors.approved,
  },
  disabled: {
    key: 'disabled',
    label: 'disabled',
    color: merchantStatusColors.disabled,
  },
  invited: {
    key: 'invited',
    label: 'invited',
    color: merchantStatusColors.pending,
  },
  pending: {
    key: 'pending',
    label: 'pending',
    color: merchantStatusColors.pending,
  },
  rejected: {
    key: 'rejected',
    label: 'rejected',
    color: merchantStatusColors.rejected,
  },
}
