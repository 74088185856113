import { Category } from '../../types/category'
import { Item, ProgrammeItem } from '../../types/item'
import { axiosApi } from './api'

/* Global items */

export const getGlobalItems = async (name?: string) => {
  const { data } = await axiosApi()
    .get<Item[]>(
      '/items',
      { params: { name } }
    )
  return data
}

export const addGlobalItem = async (formData) => {
  const { data } = await axiosApi()
    .post(
      '/item',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
  return data
}

export const updateGlobalItem = async ({ id, formData }) => {
  const { data } = await axiosApi()
    .patch(
      `/item/${id}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
  return data
}

/* Item categories */

export const getItemCategories = async (name?) => {
  try {
    const { data } = await axiosApi()
      .get<Category[]>(
        '/items/categories',
        {
          params: { name },
        }
      )
    return data
  } catch {
    throw new Error('Could not add the category')
  }
}

export const addItemCategory = async (formData) => {
  const { data } = await axiosApi()
    .post(
      '/items/category',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
  return data
}

export const updateItemCategory = async ({ id, formData }) => {
  const { data } = await axiosApi()
    .patch(
      `/items/category/${id}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
  return data
}

/* Programme items */

export const getProgrammeItems = async (programmeId) => {
  const { data } = await axiosApi()
    .get<ProgrammeItem[]>(
      `/items/programme/${programmeId}`
    )
  return data
}

export const addProgrammeItem = async (programmeId, formData) => {
  const { data } = await axiosApi()
    .post(
      `/items/programme/${programmeId}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )

  return data
}

export const updateProgrammeItem = async (programmeId, itemId, formData) => {
  const { data } = await axiosApi()
    .patch(
      `/items/programme/${programmeId}/item/${itemId}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )

  return data
}

export const removeProgrammeItem = async ({ programmeId, itemId }) => {
  try {
    const { data, status } = await axiosApi()
      .delete(
        `/items/programme/${programmeId}/item/${itemId}`
      )

    if (status !== 200) {
      throw new Error('Could not delete the programme item')
    }

    return data
  } catch {
    throw new Error('Could not delete the programme item')
  }
}

export const updateItemPrice = async (
  programmeId,
  itemId,
  merchantId,
  price
) => {
  try {
    const { data } = await axiosApi()
      .patch(
        `/items/programme/${programmeId}/item/${itemId}/merchant/${merchantId}`,
        { price }
      )

    return data
  } catch {
    throw new Error('Could not update the item price')
  }
}

export const getItemsGroupedByCategory = async (programmeId) => {
  const { data } = await axiosApi()
    .get(
      `/items/programme/${programmeId}/grouped`
    )
  return data
}

export const updateItemsStatuses = async (programmeId, payload) => {
  const { data } = await axiosApi()
    .put(
      `/items/programme/${programmeId}/items/status`,
      payload
    )
  return data
}

export const getMerchantItems = async (programmeId, merchantId) => {
  const { data } = await axiosApi()
    .get(
      `/items/programme/${programmeId}/merchant/${merchantId}`
    )
  return data
}

export const getItemsCataloguePDF = async (programmeId, merchantId) => {
  const { data } = await axiosApi()
    .get(
      `/items/programme/${programmeId}/merchant/${merchantId}/pdf`,
      {
        headers: { accept: 'application/octet-stream' },
        responseType: 'blob',
      }
    )
  return data
}
