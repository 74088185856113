import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Spinner from '../components/atoms/spinner'
import { CombinedReducers } from '../redux/stores/reducers'

const AuthenticatedRoute = () => {
  const { pathname } = useLocation()
  const { isLoggedIn, isInitialRequestDone } = useSelector<
    CombinedReducers,
    { isLoggedIn: boolean, isInitialRequestDone: boolean }
  >(state => ({
    isLoggedIn: state.user.isLoggedIn,
    isInitialRequestDone: state.user.isInitialRequestDone,
  }))

  if (!isInitialRequestDone) {
    return (
      <Spinner
        centered
        fillContainer
        size="200px"
      />
    )
  }

  if (!isLoggedIn) {
    return (
      <Navigate
        to="/login"
        state={{ from: pathname }}
      />
    )
  }

  return <Outlet />
}

export default AuthenticatedRoute
