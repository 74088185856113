import './styles.scss'

const NotFound = () => {
  return (
    <div className="not-found-page">
      <h1>404 - Page not found</h1>
    </div>
  )
}

export default NotFound
