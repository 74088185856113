import { Typography } from 'antd'
import './styles.scss'
import Icon from '../../atoms/icon'
import { nutritionalValues } from './nutritionalValues'
import { FC } from 'react'

const NutritionalValue: FC<{ value: string }> = ({ value }) => {
  const nutritionalValueKey = value || nutritionalValues.notApplicable.key
  return (
    <div className="nutritional-value">
      <Icon component={() => nutritionalValues[nutritionalValueKey].icon} />
      <Typography.Text>
        {nutritionalValues[nutritionalValueKey].label}
      </Typography.Text>
    </div>
  )
}

export default NutritionalValue
