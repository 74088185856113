import {
  Navigate,
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'
import { history, RouterHistory } from './browserHistory'
import RootRoute from './rootRoute'
import AuthenticatedRoute from './authenticatedRoute'
import GuestRoute from './guestRoute'
import ContextAwareRoute from './contextAwareRoute'
import CreateFieldworker from '../components/views/CreateFieldworker'
import CreateProgramme from '../components/views/CreateProgramme'
import CreateUsers from '../components/views/CreateUsers'
import CreateVouchers from '../components/views/CreateVouchers'
import FieldworkerDetails from '../components/views/FieldworkerDetails'
import FieldworkersList from '../components/views/FieldworkersList'
import Dashboard from '../components/views/Dashboard'
import MerchantDetails from '../components/views/MerchantDetails'
import Merchants from '../components/views/Merchants'
import NotFound from '../components/views/NotFound'
import ProductsList from '../components/views/ProductsList'
import ProgrammeDetails from '../components/views/ProgrammeDetails'
import ProgrammesList from '../components/views/ProgrammesList'
import UserProfile from '../components/views/UserProfile'
import TransactionsList from '../components/views/TransactionsList'
import UsersList from '../components/views/UsersList'
import UserDetails from '../components/views/UserDetails'
import VoucherBatchesList from '../components/views/VoucherBatchesList'
import VoucherBatchDetails from '../components/views/VoucherBatchDetails'
import ItemsManagement from '../components/views/ItemsManagement'
import CategoriesManagement from '../components/views/CategoriesManagement'
import AddExistingFieldworker from '../components/views/AddExistingFieldworker'
import Login from '../components/views/Login'
import ResetPassword from '../components/views/ResetPassword'
import SetNewPassword from '../components/views/SetNewPassword'
import ProgrammeNotFound from '../components/views/ProgrammeNotFound'
import { CenteredContent } from '../components/templates/centered-content'
import TwoColumnLayout from '../components/templates/two-column-layout'
import HouseholdsList from '../components/views/HouseholdsList'

export const Router = () => (
  <HistoryRouter history={history as RouterHistory}>
    <Routes>
      <Route element={<TwoColumnLayout />}>
        <Route
          path="/"
          element={<RootRoute />}
        />
      </Route>

      <Route element={<TwoColumnLayout />}>
        <Route element={<AuthenticatedRoute />}>
          <Route
            path=":viewContext"
            element={<ContextAwareRoute />}
          >
            <Route
              index={true}
              element={<Dashboard />}
            />
            <Route
              path="programmes"
              element={<ProgrammesList />}
            />
            <Route
              path="programmes/:programmeId"
              element={<ProgrammeDetails />}
            />
            <Route
              path="programmes/create"
              element={<CreateProgramme />}
            />
            <Route
              path="programmes/details"
              element={<ProgrammeDetails />}
            />
            <Route
              path="categories"
              element={<CategoriesManagement />}
            />
            <Route
              path="products"
              element={<ProductsList />}
            />
            <Route
              path="users/:userId"
              element={<UserDetails />}
            />
            <Route
              path="suppliers"
              element={<Merchants />}
            />
            <Route
              path="suppliers/:merchantId"
              element={<MerchantDetails />}
            />
            <Route
              path="fieldworkers"
              element={<FieldworkersList />}
            />
            <Route
              path="fieldworkers/create"
              element={<CreateFieldworker />}
            />
            <Route
              path="fieldworkers/add-existing"
              element={<AddExistingFieldworker />}
            />
            <Route
              path="fieldworkers/:fieldworkerId"
              element={<FieldworkerDetails />}
            />
            <Route
              path="certificates/create"
              element={<CreateVouchers />}
            />
            <Route
              path="certificates"
              element={<VoucherBatchesList />}
            />
            <Route
              path="certificates/:voucherId"
              element={<VoucherBatchDetails />}
            />
            <Route
              path="items"
              element={<ItemsManagement />}
            />
            <Route
              path="transactions"
              element={<TransactionsList />}
            />
            <Route
              path="users"
              element={<UsersList />}
            />
            <Route
              path="users/create"
              element={<CreateUsers />}
            />
            <Route
              path="households"
              element={<HouseholdsList />}
            />
          </Route>

          <Route
            path="programme-not-found"
            element={<ProgrammeNotFound />}
          />

          <Route
            path="profile"
            element={<UserProfile />}
          />
        </Route>
      </Route>

      <Route element={<CenteredContent />}>
        <Route element={<GuestRoute />}>
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/reset-password"
            element={<ResetPassword />}
          />
          <Route
            path="/set-password"
            element={<SetNewPassword />}
          />
        </Route>
      </Route>

      <Route element={<TwoColumnLayout />}>
        <Route
          path="/404"
          element={<NotFound />}
        />
      </Route>

      <Route
        path="*"
        element={<Navigate to="/404" />}
      />
    </Routes>
  </HistoryRouter>
)
