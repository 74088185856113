import { useState, useEffect, FC } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import Table from '../../atoms/table'
import {
  parseTableMerchantData,
  columns,
  mappings,
} from './merchant-table.config'
import { getProgrammeMerchants } from '../../../services/api/merchant.api'
import { Query, SortDirections, useQuery } from '../../../hooks/query.hook'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'
import { getNumberOfPages } from '../../../services/helpers/table'

const defaultQuery = {
  order: 'createdAt',
  orderType: SortDirections.descend,
  pageSize: 10,
  page: 1,
}

const MerchantTable: FC<{
  searchQuery?: string,
  presetQuery: Query,
}> = ({
  searchQuery = '',
  presetQuery,
}) => {
  const [search, setSearch] = useState('')
  const [merchants, setMerchants] = useState([])
  const [numberOfPages, setNumberOfPages] = useState(1)
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  const navigate = useNavigate()
  const {
    query,
    onSort,
    onSearch,
    onFilter,
    onFilters,
    onPageChange,
  } = useQuery(presetQuery || defaultQuery)

  useEffect(() => {
    if (searchQuery !== search) {
      setSearch(searchQuery)
      onFilter(mappings['businessName'], searchQuery)
    }
  }, [searchQuery, search, onSearch, onFilter])

  useEffect(() => {
    if (activeProgramme) {
      const programmeId = activeProgramme ? activeProgramme.id : null
      getProgrammeMerchants(programmeId, query).then((res) => {
        setNumberOfPages(getNumberOfPages(res.count, query.pageSize))
        const merchantsData = res.merchants || []
        setMerchants(merchantsData.map(parseTableMerchantData))
      })
    }
  }, [activeProgramme, navigate, query])

  const onRowClick = (rowData) => {
    navigate(`${rowData.id}`, { state: [query] })
  }

  return (
    <div className="merchant-table">
      <Table
        data={merchants}
        columns={columns}
        onSort={(name, sortOrder) => onSort(mappings[name], sortOrder)}
        onFilters={onFilters}
        onRowClick={onRowClick}
        pagination={{
          numberOfPages,
          onPageChange,
          page: query.page,
        }}
      />
    </div>
  )
}

export default MerchantTable
