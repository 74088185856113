import { UserRaw } from '../../types/user'
import { ApiResponseData, axiosApi } from './api'

export const getUserDetails = async (userId: string) => {
  const { data } = await axiosApi()
    .get<ApiResponseData<UserRaw>>(
      `/users/${userId}/full`
    )
  return data
}

export const getSingleUser = async (userId) => {
  const { data } = await axiosApi()
    .get(
      `/users/${userId}`
    )
  return data
}

export const updateUser = async (userId, payload) => {
  const { data } = await axiosApi()
    .put(
      `/users/${userId}`,
      payload
    )
  return data
}

export type ResetPasswordRequestPayload = {
  email: string
  captchaToken: string
  shouldSkipCaptcha?: boolean
}
export const resetPasswordRequest = async (payload: ResetPasswordRequestPayload) => {
  const { data } = await axiosApi()
    .post(
      '/users/forgot-password',
      payload
    )
  return data
}

export const savePassword = async (token, payload) => {
  const { data } = await axiosApi()
    .put(
      '/users/password',
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  return data
}

export const changePassword = async (userId, payload) => {
  const { data } = await axiosApi()
    .put(
      `/users/${userId}/password`,
      payload
    )
  return data
}

export const getUsers = async (params) => {
  const { data } = await axiosApi()
    .get(
      '/users',
      { params }
    )
  return data
}

export const disableUser = async (userId, params) => {
  const { data } = await axiosApi()
    .patch(
      `/users/${userId}/disable`,
      { params }
    )
  return data
}

export const enableUser = async (userId) => {
  const { data } = await axiosApi()
    .patch(
      `/users/${userId}/enable`
    )
  return data
}

export const unlockUser = async (userId) => {
  const { data } = await axiosApi()
    .patch(
      `/users/${userId}/unlock`
    )
  return data
}
