import { FC, useMemo } from 'react'
import { Typography } from 'antd'

const { Text } = Typography

const EllipsisMiddle: FC<{
  suffixLength?: number
  showTooltip?: boolean
  children: string
}> = ({ suffixLength = 8, showTooltip = true, children }) => {
  const text = children.slice(0, children.length - suffixLength).trim();
  const suffix = children.slice(-suffixLength);

  const ellipsisProps = useMemo(() => {
    const props: {tooltip?: string} = {}
    if (showTooltip) {
      props.tooltip = children
    }

    return props
  }, [children, showTooltip])

  return ellipsisProps && (
    <Text
      ellipsis={{
        suffix,
        ...ellipsisProps
      }}
    >
      {text}
    </Text>
  );
};

export default EllipsisMiddle
