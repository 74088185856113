import { Outlet } from 'react-router-dom'
import './styles.scss'
import Logo from '../../atoms/logo'

const CenteredContent = () => (
  <div className="centered-content-template">
    <div className="vertically-centered">
      <div className="template-header">
        <Logo />
      </div>
      <div className="template-content">
        <Outlet />
      </div>
    </div>
  </div>
)

export { CenteredContent }
