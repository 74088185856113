import { MerchantItemsRequest, PendingMerchantItemStatus } from '../../types/item'
import { ApiResponseData, axiosApi } from './api'

export const getMerchants = async (userId, params) => {
  const { data } = await axiosApi()
    .get(
      `/user/${userId}/merchants`,
      { params }
    )
  return data
}

export const getProgrammeMerchants = async (programmeId, params) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/merchants`,
      { params }
    )
  return data
}

export const addMerchants = async (payload) => {
  const { data } = await axiosApi()
    .post(
      '/merchants',
      payload
    )
  return data
}

export const getMerchantEmployees = async (programmeId, merchantId) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/merchants/${merchantId}/employees`
    )
  return data
}

export const updateMerchantEmployeesStatus = async (
  programmeId,
  merchantId,
  userId,
  enable = true
) => {
  const { data } = await axiosApi()
    .patch(
      `/programmes/${programmeId}/merchants/${merchantId}/employees/${userId}/${enable ? 'enable' : 'disable'}`
    )
  return data
}

export const getMerchantInvites = async (programmeId: string) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/merchants/invites`
    )
  return data
}

export const inviteMerchant = async (programmeId, email) => {
  const { data } = await axiosApi()
    .post(
      `/programmes/${programmeId}/merchants/invites`,
      { email }
    )
  return data
}

export const getMerchant = async (programmeId, merchantId) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/merchants/${merchantId}`
    )
  return data
}

export const updateMerchantStatus = async (
  programmeId,
  merchantId,
  payload
) => {
  const { data } = await axiosApi()
    .patch(
      `/programmes/${programmeId}/merchants/${merchantId}`,
      payload
    )
  return data
}


export const disableMerchant = async (merchantId: string) => {
  const { data } = await axiosApi()
    .patch(
      `/programmes/all/merchants/${merchantId}/disable`
    )
  return data
}

export const getAvailableVouchersForMerchant = async ({
  merchantId,
  programmeId
}) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/merchants/${merchantId}/vouchers/count`
    )
  return data
}

export const changeVouchersAllocatedMerchant = async ({
  programmeId,
  toMerchantId,
  fromMerchantId
}) => {
  const { data } = await axiosApi()
    .patch(
      `/programmes/${programmeId}/transfer/from/${fromMerchantId}/to/${toMerchantId}`
    )
  return data
}

export const changeVoucherAllocatedMerchant = async (
  programmeId,
  voucherId,
  merchantId,
) => {
  const { data } = await axiosApi()
    .patch(
      `/programmes/${programmeId}/vouchers/${voucherId}/transfer/merchants/${merchantId}`
    )
  return data
}

export const getMerchantFile = async (programmeId: string, merchantId: string, fileName: string) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/merchants/${merchantId}/files/${fileName}`,
      { headers: { accept: 'application/octet-stream' }, responseType: 'blob' }
    )
  return data
}

export const getMerchantItemsRequest = async (programmeId: string, merchantId: string) => {
  const { data } = await axiosApi()
    .get<ApiResponseData<MerchantItemsRequest>>(
      `/items/programme/${programmeId}/merchant/${merchantId}/requests`
    )
  return data
}

export type ApproveMerchantItemsPayload = {
  id: string
  status: PendingMerchantItemStatus
}
export const approveMerchantItems = async (programmeId: string, merchantId: string, requestId: string, payload: ApproveMerchantItemsPayload[]) => {
  const { data } = await axiosApi()
    .post<ApiResponseData<any>>(
      `/items/programme/${programmeId}/merchant/${merchantId}/requests/${requestId}`,
      payload
    )
  return data
}
