import { useState, useEffect, FC } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import Table from '../../atoms/table'
import { getFieldworkers } from '../../../services/api/fieldworker.api'
import {
  columns,
  parseTableFieldworkerData,
  mappings,
} from './fieldworkers-table.config'
import { SortDirections, useQuery } from '../../../hooks/query.hook'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'
import { getNumberOfPages } from '../../../services/helpers/table'

const defaultQuery = {
  order: 'createdAt',
  orderType: SortDirections.descend,
  pageSize: 10,
  page: 1,
}

const FieldworkersTable: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const [search, setSearch] = useState('')
  const [fieldworkers, setFieldworkers] = useState([])
  const [numberOfPages, setNumberOfPages] = useState(1)
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  const navigate = useNavigate()
  const {
    query,
    onSort,
    onSearch,
    onFilter,
    onFilters,
    onPageChange,
  } = useQuery(defaultQuery)

  useEffect(() => {
    if (searchQuery !== search) {
      setSearch(searchQuery)
      onFilter(mappings['search'], searchQuery)
    }
  }, [searchQuery, search, onSearch, onFilter])

  useEffect(() => {
    if (activeProgramme) {
      const programmeId = activeProgramme ? activeProgramme.id : null
      getFieldworkers(programmeId, query).then((res) => {
        if (!res.hasErrors) {
          setNumberOfPages(getNumberOfPages(res.count, query.pageSize))
          setFieldworkers(res.fieldWorkers?.map(parseTableFieldworkerData))
        }
      })
    }
  }, [activeProgramme, navigate, query])

  const onRowClick = (_, index) => {
    navigate(`${fieldworkers[index].id}`, { state: [query] })
  }

  return (
    <div className="fieldworkers-table">
      <Table
        data={fieldworkers}
        columns={columns}
        onSort={(name, sortOrder) => onSort(mappings[name], sortOrder)}
        onFilters={onFilters}
        onRowClick={onRowClick}
        pagination={{
          numberOfPages,
          onPageChange,
          page: query.page,
        }}
      />
    </div>
  )
}

export default FieldworkersTable
