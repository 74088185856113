import { VoucherStatuses } from '../constants/voucher.constants'
import { BaseProgramme } from './programme'
import { Redemption } from './transactions'
import { BaseUser, Merchant } from './user'

type VoucherBatchRenewals = {
  total: number
  current: number
}

export type VoucherBatchSummary = {
  available: number,
  availableValue: number,
  disabled: number,
  disabledValue: number,
  assigned: number,
  assignedValue: number,
  redeemed: number,
  redeemedValue: number
}

export type Voucher = {
  Merchant: Merchant
  entitlements: number
  householdNumber?: string
  fieldworkerName?: string
  voucherBatchId?: string
  id: string
  index: string
  initialEntitlements: number | string
  status: keyof VoucherStatuses
  updatedAt: string
}

export type VoucherHistory = {
  enrolmentDate: string
  entitlements: string
  fieldworkerName: string
  id: string
  index: string
  initialEntitlements: string
  isRenewable: boolean
  redemptions: Redemption[]
  renewals: VoucherBatchRenewals
  status: keyof VoucherStatuses
  voucherBatchId: string
}

export enum VoucherBatchRenewalTypes {
  MONTHLY = 1,
  TOPUP = 2
}

export type VoucherBatch = {
  id: string
  issuedTo: string
  createdOn: string
  totalCertificates: number,
  isRenewable: boolean,
  renewalType: number,
  status: string
  vouchers: [],
  certificateValue: string
}

export type VoucherBatchRaw = {
  id: string
  createdBy: string
  number: number
  fixedValue: string
  totalValue: string
  isDisabled: boolean
  createdAt: string
  isRenewable: boolean
  renewalType: number
  startDate: string
  endDate: string
  Programme: BaseProgramme
  FieldWorker: BaseUser
  totalValueAfterRenewals: number
  merchantMessage: string
  fieldworkerMessage: string
  status: string
  summary: VoucherBatchSummary
  renewals: VoucherBatchRenewals
}
