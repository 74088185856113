import { Space } from 'antd'
import { IconLock } from '@tabler/icons-react'
import { getUserStatus } from '../../../services/helpers/helpers'
import { UserRoles } from '../../../constants/userRoles.constants'
import StatusPill from '../../atoms/status-pill'
import { userStatuses } from '../../../constants/user.constants'
import Icon from '../../atoms/icon'
import { statusToTextValue } from '../../../services/helpers/constants'
import { ColumnsType } from 'antd/es/table'

export const mappings = {
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  role: 'roles',
  status: 'status',
  search: 'search',
  isLocked: 'isLocked',
}

export const columns: ColumnsType = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: true,
    ellipsis: true,
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: true,
    ellipsis: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    ellipsis: true,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    filters: Object.keys(UserRoles).map(key => ({
      text: UserRoles[key],
      value: UserRoles[key]
    })),
    filterMultiple: false,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: statusToTextValue(userStatuses),
    filterMultiple: false,
  },
]

export const parseTableUsersData = (data) => {
  const status = getUserStatus(data)
  return {
    id: data[mappings.id],
    firstName: data[mappings.firstName],
    lastName: data[mappings.lastName],
    email: data[mappings.email],
    role: data[mappings.role],
    status: (
      <Space>
        {data[mappings.isLocked] && (
          <Icon
            component={IconLock}
            componentProps={{ strokeWidth: 1.5 }}
            className="locked-account"
          />
        )}
        <StatusPill statusData={userStatuses[status]} />
      </Space>
    ),
  }
}
