import { Space } from 'antd'
import { IconLock } from '@tabler/icons-react'
import { getMerchantStatus } from '../../../services/helpers/helpers'
import { merchantStatuses } from '../../../constants/merchant.constants'
import StatusPill from '../../atoms/status-pill'
import Icon from '../../atoms/icon'
import { statusToTextValue } from '../../../services/helpers/constants'
import { ColumnsType } from 'antd/es/table'

export const mappings = {
  id: 'id',
  businessName: 'name',
  owner: 'owner',
  status: 'status',
  search: 'search',
}

const {
  accepted: _accepted,
  active: _active,
  invited: _invited,
  ...filterableMerchantStatuses
} = merchantStatuses

export const columns: ColumnsType = [
  {
    title: 'Business Name',
    dataIndex: 'businessName',
    key: 'businessName',
    sorter: true,
  },
  {
    title: 'Owner',
    dataIndex: 'owner',
    key: 'owner',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: statusToTextValue(filterableMerchantStatuses),
    filterMultiple: false,
  },
]

export const parseTableMerchantData = (data) => {
  const status = getMerchantStatus(data)
  return {
    id: data[mappings.id],
    businessName: data[mappings.businessName],
    owner: data[mappings.owner],
    status: (
      <Space>
        {data.CreatedBy.isLocked && (
          <Icon
            component={IconLock}
            componentProps={{ strokeWidth: 1.5 }}
            className="locked-account"
          />
        )}
        <StatusPill statusData={merchantStatuses[status]} />
      </Space>
    ),
  }
}
