import { KeyLabel } from '../types/generic'

export type TransactionType = {
  created: KeyLabel,
  enrolled: KeyLabel,
  redeemed: KeyLabel,
  renewed: KeyLabel,
}

export const transactionTypes: TransactionType = {
  created: {
    key: 'created',
    label: 'created',
  },
  enrolled: {
    key: 'enrolled',
    label: 'enrolled',
  },
  redeemed: {
    key: 'redeemed',
    label: 'redeemed',
  },
  renewed: {
    key: 'renewed',
    label: 'renewed',
  },
}
