import { FC, useMemo, useState } from 'react'
import dayjs from 'dayjs';
import { IconFileDescription } from '@tabler/icons-react';
import { Collapse, Descriptions, Modal, Space, Table, Typography } from 'antd';
import { voucherStatuses } from '../../../constants/voucher.constants';
import { stringWithLeadingZeroes } from '../../../services/helpers/helpers';
import HighlightedText from '../../atoms/highlighted-text';
import Icon from '../../atoms/icon';
import StatusPill from '../../atoms/status-pill';
import EditableImageWithText from '../editable-image-with-text';
import SelectMerchantModal, { SelectMerchantModalProps } from '../select-merchant-modal';
import ActionsDropdown from './actions-dropdown';
import { ColumnsType, TableProps } from 'antd/es/table';
import { Voucher } from '../../../types/voucher';
import { Programme } from '../../../types/programme';
import { LabelValue } from '../../../types/generic';
import { Redemption } from '../../../types/transactions';

const { Panel } = Collapse
const { Text, Paragraph } = Typography

export type VoucherDetailsModalData = {
  title: string
  certificateDetails: LabelValue[]
  redemptions: Redemption[]
}

const VouchersTable: FC<{
  vouchers: Voucher[],
  voucherBatchId?: string,
  searchQuery: string | number,
  programme: Programme,
  onOperationCompleted: () => void,
  fieldworkerName?: string,
  householdNumber?: string,
  tableProps?: TableProps<Voucher>,
  highlightSearchResult?: boolean
  showFieldworkerColumn?: boolean
}> = ({
  vouchers,
  voucherBatchId,
  searchQuery,
  programme: {
    id: programmeId,
    isOnline: isProgrammeOnline,
    currency
  },
  onOperationCompleted,
  fieldworkerName,
  householdNumber,
  tableProps,
  highlightSearchResult = true,
  showFieldworkerColumn = false
}) => {
  const [isVoucherDetailsModalOpen, setIsVoucherDetailsModalOpen] = useState(false)
  const [voucherDetailsModalData, setVoucherDetailsModalData] = useState<VoucherDetailsModalData>()
  const [isChangeMerchantModalOpen, setIsChangeMerchantModalOpen] = useState(false)
  const [changeMerchantModalData, setChangeMerchantModalData] = useState<SelectMerchantModalProps>()

  const columns: ColumnsType<Voucher> = useMemo(() => {
    const cols: ColumnsType<Voucher> = [
      {
        title: 'Certificate #',
        dataIndex: 'index',
        key: 'index',
        render: text => (
          <Space>
            <Icon
              component={IconFileDescription}
              componentProps={{ strokeWidth: 1 }}
            />
            <HighlightedText
              contentText={text}
              searchWords={highlightSearchResult && stringWithLeadingZeroes(searchQuery || '')}
            />
          </Space>
        )
      },
      {
        title: 'Household number',
        dataIndex: 'householdNumber',
        key: 'householdNumber',
        render: text => (
          <HighlightedText
            contentText={text ?? '—'}
            searchWords={highlightSearchResult && searchQuery}
          />
        )
      },
      {
        title: 'Remaining value',
        dataIndex: 'entitlements',
        key: 'entitlements',
        render: text => `${text} ${currency}`
      },
      {
        title: 'Updated at',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: text => (
          <Text>{dayjs(text).format('L')}</Text>
        ),
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        render: text => <StatusPill statusData={voucherStatuses[text]} />
      },
      {
        title: null,
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <ActionsDropdown
            record={record}
            programmeId={programmeId}
            isProgrammeOnline={isProgrammeOnline}
            fieldworkerName={fieldworkerName || _.fieldworkerName}
            householdNumber={householdNumber || _.householdNumber}
            voucherBatchId={voucherBatchId || record.voucherBatchId}
            onOperationCompleted={onOperationCompleted}
            setIsVoucherDetailsModalOpen={setIsVoucherDetailsModalOpen}
            setVoucherDetailsModalData={setVoucherDetailsModalData}
            setIsChangeMerchantModalOpen={setIsChangeMerchantModalOpen}
            setChangeMerchantModalData={setChangeMerchantModalData}
          />
        )
      },
    ]

    if (showFieldworkerColumn) {
      cols.splice(1, 0, {
        title: 'Fieldworker',
        dataIndex: 'fieldworkerName',
        key: 'fieldworkerName',
      })
    }

    return cols
  }, []);

  // Insert merchant column only for offline programmes before the "status" column
  if (!isProgrammeOnline) {
    columns.splice(
      columns.findIndex(column => column.key === 'updatedAt'),
      0,
      {
        title: 'Supplier',
        dataIndex: ['Merchant', 'name'],
        key: 'merchantName',
        render: text => text || '–'
      }
    )
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={vouchers}
        rowKey={(record: Voucher) => record.id}
        pagination={false}
        {...tableProps}
      />

      {isChangeMerchantModalOpen && (
        <SelectMerchantModal
          open={isChangeMerchantModalOpen}
          {...changeMerchantModalData}
        />
      )}

      <Modal
        title={voucherDetailsModalData?.title}
        open={isVoucherDetailsModalOpen}
        onCancel={() => {
          setIsVoucherDetailsModalOpen(false)
          setVoucherDetailsModalData(null)
        }}
        destroyOnClose
        footer={null}
        width={700}
      >
        <Descriptions column={1}>
          {voucherDetailsModalData?.certificateDetails?.map((certificateData, index) => (
            <Descriptions.Item
              label={certificateData.label}
              key={index}
            >
              {certificateData.value}
            </Descriptions.Item>
          ))}
        </Descriptions>

        {voucherDetailsModalData?.redemptions?.length
          ? (
            <Collapse accordion>
              {voucherDetailsModalData?.redemptions.map(transaction => (
                <Panel
                  header={dayjs(transaction.createdAt).format('LLLL')}
                  key={transaction.id}
                >
                  <Table
                    bordered
                    dataSource={transaction.itemTransactions}
                    pagination={false}
                    rowKey="id"
                    columns={[
                      {
                        title: 'Item',
                        key: 'name',
                        ellipsis: true,
                        width: '50%',
                        render: (_, record) => (
                          <EditableImageWithText
                            title={{ value: record.item.name }}
                            currentImage={record.item.image}
                            isImageEditable={false}
                            isTextEditable={false}
                          />
                        )
                      },
                      {
                        title: 'Quantity',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        render: (text, record) => (
                          <Text>{text} {record.unitType}</Text>
                        )
                      },
                      {
                        title: 'Price',
                        dataIndex: 'price',
                        key: 'price',
                        render: (text, record) => (
                          <Text>{text} {record.currency}</Text>
                        )
                      },
                      {
                        title: 'Total',
                        dataIndex: 'totalAmount',
                        key: 'total',
                        render: (text, record) => (
                          <Text>{text} {record.currency}</Text>
                        )
                      },
                    ]}
                    summary={() => (
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          <Text strong>
                          Total
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={1}
                          colSpan={3}
                          align="right"
                        >
                          <Text strong>{transaction.value} {transaction.currency}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  />
                </Panel>
              ))}
            </Collapse>
          )
          : (
            <Paragraph>
              <Text type="secondary">No transactions were recorded for this certificate</Text>
            </Paragraph>
          )
        }
      </Modal>
    </>
  );
};

export default VouchersTable
