import { FC, useState } from 'react'
import './styles.scss'
import SelectUser from '../../atoms/select-user'
import EditableField, { EditableFieldContext } from './editable-field'
import { LabelValue } from '../../../types/generic'

const EditableUser: FC<{
  userName: string
  userId: string
  isEditable: boolean
  getOptions: (searchText: string) => Promise<LabelValue[]>
  onSave: (programmeManagerId: string) => Promise<void>
}> = ({
  userName,
  userId,
  isEditable,
  getOptions,
  onSave,
}) => {
  const [selectedUserId, setSelectedUserId] = useState(null)

  return (
    <EditableFieldContext.Provider
      value={{
        fieldValue: selectedUserId,
        setFieldValue: setSelectedUserId
      }}
    >
      <EditableField
        readOnlyContent={userName}
        className="editable-field__user"
        isSaveDisabled={!selectedUserId}
        isEditable={isEditable}
        onSave={onSave}
      >
        <SelectUser
          placeholder={userName}
          fetchOptions={searchText =>
            getOptions(searchText)
          }
          disabledOption={userId}
          defaultOpen
          onChange={(item) => {
            setSelectedUserId(item?.value)
          }}
          onClear={() => {
            setSelectedUserId(null)
          }}
        />
      </EditableField>
    </EditableFieldContext.Provider>
  )
}

export default EditableUser
