import { Form, FormItemProps, Input, InputProps } from 'antd'
import { FC } from 'react'

const PhoneInput: FC<{
    name?: string,
    label?: string,
    formItemProps?: FormItemProps,
    itemProps?: InputProps,
  }> = ({
    name = 'phone',
    label = 'Phone number',
    formItemProps,
    itemProps,
  }) => (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: true,
          type: 'string',
          message: 'Please type a phone number.',
        },
        {
          message: 'The phone number should contain at least 7 digits',
          min: 7,
        },
      ]}
      {...formItemProps}
    >
      <Input {...itemProps} />
    </Form.Item>
  )

export default PhoneInput
