/* eslint-disable default-param-last */
import { Programme } from '../../../types/programme'
import {
  SET_PROGRAMMES,
  SET_ACTIVE_PROGRAMME,
  SET_IS_INITIAL_PROGRAMMES_REQUEST_DONE,
  RESET_STATE,
} from './programme.types'

export type ProgrammeReducerState = {
  programmes: Programme[],
  activeProgramme: Programme,
  isInitialRequestDone: boolean,
}
const initialState = {
  programmes: [],
  activeProgramme: null,
  isInitialRequestDone: false,
}

export default function programmeReducer(state = initialState, action): ProgrammeReducerState {
  switch (action.type) {
    case SET_PROGRAMMES:
      return {
        ...state,
        programmes: action.payload,
      }
    case SET_ACTIVE_PROGRAMME:
      return {
        ...state,
        activeProgramme: action.payload,
      }
    case SET_IS_INITIAL_PROGRAMMES_REQUEST_DONE:
      return {
        ...state,
        isInitialRequestDone: action.payload,
      }
    case RESET_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
