import { ReactNode } from 'react'
import { Checkbox } from 'antd';
import { IconCheck, IconX } from '@tabler/icons-react';
import { productStatuses } from '../../../constants/product.constants'
import GoodsSvg from '../../atoms/icons/goods-svg'
import Icon from '../../atoms/icon';
import EditableImageWithText from '../editable-image-with-text'
import { ColumnsType } from 'antd/es/table';

export const mappings = {
  category: {
    id: 'id',
    name: 'name',
    products: 'items',
    programmeId: 'ProgrammeId',
  },
  product: {
    id: 'id',
    image: 'image',
    name: 'name',
    quantity: 'quantity',
    status: 'status',
    unitType: 'unitType',
  },
}

export const columns: ColumnsType = [
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
    ellipsis: true,
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: '15%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%'
  },
]

export const mapProductName = product => (
  <EditableImageWithText
    title={{ value: product[mappings.product.name] }}
    currentImage={product[mappings.product.image] || <GoodsSvg /> }
    isImageEditable={false}
    isTextEditable={false}
  />
)

export const mapProductStatus = (
  product,
  productCategoryId,
  isInEditMode,
  activeProducts,
  onProductStatusToggle
) => {
  const isProductActive = (activeProducts, productId) =>
    activeProducts.indexOf(productId) > -1

  if (isInEditMode) {
    return (
      <div className="checkbox-container">
        <Checkbox
          checked={isProductActive(
            activeProducts,
            product[mappings.product.id]
          )}
          onChange={() => {
            onProductStatusToggle(
              product[mappings.product.id],
              productCategoryId
            )
          }}
        />
      </div>
    )
  }

  return (
    <div className="product-status">
      {product.status === productStatuses.active
        ? (<Icon component={IconCheck} />)
        : (<Icon component={IconX} />)
      }
    </div>
  )
}

export const mapProductUnit = product =>
  `${product[mappings.product.quantity]} ${product[mappings.product.unitType]}`

type SectionHeader = {
  content: ReactNode
  colSpan: number
}

export const parseProductTableData = (
  productCategories,
  mapProduct,
  categoriesStates: {[categoryId: string]: boolean | null },
  onCategoryClick,
  isInEditMode
) => {
  const mappedProducts = productCategories.map((productCategory) => {
    const columns: SectionHeader[] = [
      {
        content: <div>{productCategory[mappings.category.name]}</div>,
        colSpan: isInEditMode ? 2 : 3,
      },
    ]

    if (categoriesStates && isInEditMode) {
      columns.push({
        content: (
          <div className="product-category__checkbox">
            <Checkbox
              checked={categoriesStates[productCategory[mappings.category.id]]}
              indeterminate={categoriesStates[productCategory[mappings.category.id]] === null}
              onChange={() => onCategoryClick(productCategory[mappings.category.id])}
            />
          </div>
        ),
        colSpan: 1,
      })
    }

    const section: {
      id: string
      columns: SectionHeader[]
      data?: unknown
    } = {
      id: productCategory[mappings.category.id],
      columns,
    }

    const rawProducts = productCategory[mappings.category.products]
    if (Array.isArray(rawProducts) && rawProducts.length) {
      section.data = rawProducts.map(product =>
        mapProduct(product, productCategory[mappings.category.id])
      )
    }
    return section
  })

  return mappedProducts
}
