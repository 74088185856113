import { Button, ButtonProps } from 'antd'
import { FC, ReactNode } from 'react'
import './styles.scss'

const ContentWithAction: FC<{
  actionButtonProps: ButtonProps,
  actionButtonLabel: string,
  children: ReactNode
}> = ({ actionButtonProps, actionButtonLabel, children }) => (
  <>
    <div className="action-button__wrapper">
      <Button
        type="primary"
        size="large"
        {...actionButtonProps}
      >
        {actionButtonLabel}
      </Button>
    </div>

    {children}
  </>
)

export default ContentWithAction
