import { FC, ReactNode } from 'react'
import './styles.scss'
import { messageTypes } from './messageTypes';

const MessageComponent: FC<{
  type?: messageTypes;
  children?: ReactNode;
  onClick?: () => void;
  icon?: ReactNode;
  iconAlign?: string;
  iconStyle?: string;
}> = ({
  type = messageTypes.success,
  children,
  onClick = null,
  icon,
  iconAlign,
  iconStyle,
}) => {
  return (
    <div
      className={`message ${type} ${onClick ? 'with-action' : ''}`}
      onClick={() => (onClick ? onClick() : () => { })}
    >
      <p> {children} </p>
      {!!icon && (
        <div className={`message-icon icon-${iconAlign || 'right'} icon-${iconStyle || 'default'}`}>
          {icon}
        </div>
      )}
    </div>
  )
}

export default MessageComponent
