import { FC, ReactNode } from 'react'
import { Button, Tooltip, Typography } from 'antd'
import { IconInfoCircle } from '@tabler/icons-react';
import classNames from 'classnames';
import Icon from '../../../atoms/icon';
import { VoucherStatuses } from '../../../../constants/voucher.constants';
import './styles.scss'

const { Title, Paragraph } = Typography

const StatusCard: FC<{
  type?: keyof VoucherStatuses
  title: string
  infoText?: string
  mainData: ReactNode
  secondaryData?: ReactNode
  className?: string
}> = ({ type, title, infoText, mainData, secondaryData, className }) => (
  <div
    className={classNames('status-card', className, { [`status-${type}`]: !!type })}
  >
    <section className="card-header">
      <div className="card-title">
        <Title level={5}>{title}</Title>
      </div>

      {infoText && (
        <Tooltip title={infoText}>
          <Button
            type="text"
            shape="circle"
            icon={<Icon component={IconInfoCircle} />}
          />
        </Tooltip>
      )}
    </section>
    <section className="card-body">
      <Paragraph className="main-data">{mainData}</Paragraph>
      <Paragraph className="secondary-data">{secondaryData}</Paragraph>
    </section>
  </div>
)

export default StatusCard
