import { ComputedDatum, PieCustomLayer, ResponsivePie } from '@nivo/pie'
import { Typography } from 'antd'
import { FC, createContext, useContext } from 'react'
import ChartTooltipContainer from '../../atoms/chart-tooltip-container'
import Spinner from '../../atoms/spinner'

const { Title, Paragraph, Text } = Typography

const SoldItemsChartDataContext = createContext<{ totalValue: number, currency: string }>(null)

const CustomTooltip: FC<{datum: ComputedDatum<{ price: string, unitType: string }>}> = ({ datum }) => {
  const {
    value,
    label,
    color,
    data: { price, unitType },
  } = datum
  const { currency } = useContext(SoldItemsChartDataContext)

  return (
    <ChartTooltipContainer>
      <Title
        level={4}
        style={{ borderBottom: `5px solid ${color}`, padding: '0 0.5rem' }}
      >
        {label}
      </Title>
      <Paragraph>
        Sold:{' '}
        <Text strong>
          {value} {currency}
        </Text>
      </Paragraph>

      <Paragraph>
        Unit price:{' '}
        <Text strong>
          {price} {currency}/{unitType}
        </Text>
      </Paragraph>
    </ChartTooltipContainer>
  )
}

// eslint-disable-next-line react/prop-types
const CenteredMetric: PieCustomLayer<unknown> = ({ centerX, centerY }) => {
  const { totalValue, currency } = useContext(SoldItemsChartDataContext)

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '42px',
        fontWeight: 600,
      }}
    >
      {totalValue} {currency}
    </text>
  )
}

const SoldItemsChart: FC<{ currency: string, data }> = ({ currency, data }) => {
  return data ? (
    <SoldItemsChartDataContext.Provider
      value={{ currency, totalValue: data.totalValue }}
    >
      <ResponsivePie
        data={data.items}
        margin={{ top: 60, right: 120, bottom: 60, left: 120 }}
        animate={true}
        innerRadius={0.8}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        arcLinkLabel={d => `${d.label} (${d.formattedValue})`}
        activeInnerRadiusOffset={8}
        activeOuterRadiusOffset={8}
        valueFormat={value => `${value} ${currency}`}
        layers={[
          'arcs',
          'arcLabels',
          'arcLinkLabels',
          'legends',
          CenteredMetric,
        ]}
        tooltip={CustomTooltip}
      />
    </SoldItemsChartDataContext.Provider>
  ) : (
    <Spinner />
  )
}

export default SoldItemsChart
