import { FC } from 'react'
import { Button, Tooltip, Typography } from 'antd'
import Icon from '../../../atoms/icon'
import { IconInfoCircle } from '@tabler/icons-react'
import './styles.scss'
import classNames from 'classnames'
import { CardProps } from '../simple-card'

const CardContent: FC<CardProps> = ({ title, icon, infoText, className, children }) => (
  <div className="card-content__container">
    <section className="card-header">
      <div className="card-title">
        <span className="icon__wrapper">{icon}</span>
        <Typography.Title level={4}>{title}</Typography.Title>
      </div>

      {infoText && (
        <Tooltip title={infoText}>
          <Button
            type="text"
            shape="circle"
            icon={<Icon component={IconInfoCircle} />}
          />
        </Tooltip>
      )}
    </section>

    <section className={classNames('card-content', className)}>
      {typeof children === 'object' ? (
        children
      ) : (
        <Typography.Text className="card-content__text">
          {children}
        </Typography.Text>
      )}
    </section>
  </div>
)

export default CardContent
