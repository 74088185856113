import { FC, ReactNode, useState } from 'react'
import { Descriptions, Modal, ModalProps } from 'antd'
import './styles.scss'
import SelectMerchantTable from '../select-merchant-table'
import { Merchant } from '../../../types/user'

export type SelectMerchantModalProps = ModalProps & {
  title: string,
  programmeId: string,
  currentMerchant: Merchant,
  label?: string,
  getFooter?: (merchantId: string) => ReactNode,
}
const SelectMerchantModal: FC<SelectMerchantModalProps> = ({
  title,
  programmeId,
  currentMerchant,
  label = `Current supplier: ${currentMerchant?.name}`,
  getFooter,
  ...modalProps
}) => {
  const [selectedMerchantId, setSelectedMerchantId] = useState(null)

  return (
    <Modal
      title={title || 'Select supplier'}
      footer={getFooter && getFooter(selectedMerchantId)}
      destroyOnClose
      {...modalProps}
      okButtonProps={{ disabled: !selectedMerchantId }}
      onOk={() => modalProps.onOk(selectedMerchantId)}
    >
      <Descriptions column={1}>
        <Descriptions.Item>
          {label}
        </Descriptions.Item>
      </Descriptions>
      <SelectMerchantTable
        programmeId={programmeId}
        currentMerchant={currentMerchant}
        selectedMerchantId={selectedMerchantId}
        setSelectedMerchantId={setSelectedMerchantId}
        noMerchantsMessage="No active suppliers were found to reassign certificates. "
      />
    </Modal>
  )
}

export default SelectMerchantModal
