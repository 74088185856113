import { useState, useEffect, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { Dropdown, message } from 'antd'
import { IconChevronDown, IconSearch, IconUser, IconUserPlus, IconX } from '@tabler/icons-react'
import './styles.scss'
import Card from '../../atoms/card'
import TextInput from '../../atoms/text-input'
import Message from '../../atoms/message'
import FieldworkersTable from '../../molecules/fieldworkers-table'
import { programmeStatuses } from '../../../constants/programme.constants'
import Icon from '../../atoms/icon'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'
import { messageTypes } from '../../atoms/message/messageTypes'

const FieldworkersList = () => {
  const [search, setSearch] = useState('')
  const [searchValue] = useDebounce(search, 500)
  const [searchQuery, setSearchQuery] = useState('')
  const [disabledMessage, setDisabledMessage] = useState<ReactNode>('')
  const [disabled, setDisabled] = useState(false)
  const navigate = useNavigate()
  const messageData = useLocation().state?.message
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  useEffect(() => {
    if (messageData) {
      message[messageData.type](messageData.text)
    }
  }, [messageData])

  useEffect(() => {
    setSearchQuery(searchValue)
  }, [searchValue])

  const goToCreateFieldworker = () => {
    navigate('create')
  }

  useEffect(() => {
    if (activeProgramme) {
      const { status } = activeProgramme
      const statusesWithDisabledState = [
        programmeStatuses.archived.key,
        programmeStatuses.draft.key,
      ]
      if (statusesWithDisabledState.includes(status as string)) {
        setDisabled(true)
        setDisabledMessage(
          <span>
            <p>
              The creation of new fieldworkers is suspended <br /> while the
              programme status is{' '}
              <b>{programmeStatuses.archived.label.toLocaleUpperCase()}</b>
            </p>
          </span>
        )
      }
    }
  }, [activeProgramme])

  return (
    <div className="fieldworkers-list">
      <div className="fieldworkers-actions">
        <div className="search-wrapper">
          <TextInput
            type="text"
            label=""
            className="borderless"
            value={search}
            placeholder="search"
            icon={<Icon component={IconSearch} />}
            onEnterKeypress={() => setSearchQuery(search)}
            handleChange={e => setSearch(e.target.value)}
          />
        </div>
        <div className="create-programme-wrapper">
          <Dropdown.Button
            onClick={goToCreateFieldworker}
            type="primary"
            size="large"
            icon={<Icon component={IconChevronDown} />}
            disabled={disabled}
            menu={{
              items: [
                {
                  label: 'Create new',
                  key: '1',
                  icon: <IconUserPlus size="1.2rem" />,
                  onClick: goToCreateFieldworker,
                },
                {
                  label: 'Add existing',
                  key: '2',
                  icon: <IconUser size="1.2rem" />,
                  onClick: () => navigate('add-existing'),
                },
              ]
            }}
          >
            Add Fieldworker
          </Dropdown.Button>
        </div>
      </div>

      <div className="global-disabled-message">
        {!!disabledMessage && (
          <Message
            type={messageTypes.suspend}
            icon={<Icon component={IconX} />}
            onClick={() => setDisabledMessage(null)}
          >
            {disabledMessage}
          </Message>
        )}
      </div>

      <Card>
        <FieldworkersTable searchQuery={searchQuery} />
      </Card>

    </div>
  )
}

export default FieldworkersList
