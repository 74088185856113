import { Category } from './category'
import { BaseUser } from './user'

export type NutritionalValue = '' | 'low' | 'medium' | 'high'

export type BaseItem = {
  id?: string
  description?: string
  image?: string
  key?: number | string
  name?: string
}

export type Item = BaseItem & {
  nutritionalValue?: NutritionalValue | string
  isNew?: boolean
}

export type MerchantItem = BaseItem & {
  price: number
  unit: string
  merchantItemId: string
}

export type ProgrammeItem = Item & {
  category?: Category
  isEnabled?: boolean
  quantity?: number
  unitType?: string
}

export type AssignedItem = {
  isDeleted: boolean
  isEnabled: boolean
}

export enum MerchantItemsRequestStatus {
  PENDING = 'PENDING',
  REVIEWED = 'REVIEWED',
}

export enum PendingMerchantItemStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export enum PendingMerchantItemAction {
  ADD = 'ADD',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE'
}

export type PendingMerchantItem = {
  id: string
  price: number
  unitType: string
  quantity: string
  status: PendingMerchantItemStatus
  actionType: PendingMerchantItemAction
  name: string
  oldPrice?: number
  image: string
}

export type MerchantItemsRequest = {
  id: string
  status: MerchantItemsRequestStatus
  createdAt: string
  updatedAt: string
  ReviewedByUser: BaseUser
  updatedItems: PendingMerchantItem[]
}
