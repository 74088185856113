import { FC } from 'react'

const TransactionDetailsRow: FC<{
  description: string,
  value: string
}> = ({ description, value }) => {
  return (
    <div className="transaction-details-row">
      <div className="transaction-details-row__description">{description}</div>
      <div className="transaction-details-row__value">{value}</div>
    </div>
  )
}

export default TransactionDetailsRow
