import { Select, SelectProps, Spin } from 'antd'
import { BaseOptionType } from 'antd/es/select'
import debounce from 'lodash/debounce'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LabelValue } from '../../../types/generic'

const SelectUser: FC<SelectProps &{
  fetchOptions: (searchText: string) => Promise<LabelValue[]>,
  disabledOption?: string,
  onClear?: () => void
  debounceTimeout?: number
}> = ({
  fetchOptions,
  disabledOption,
  onClear = () => { },
  debounceTimeout = 800,
  ...props
}) => {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<BaseOptionType[]>([])
  const fetchRef = useRef(0)

  const loadOptions = useCallback(async (value: string) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setOptions([])
    setFetching(true)
    const newOptions: BaseOptionType[] = await fetchOptions(value)
    if (fetchId !== fetchRef.current) {
      return
    }

    if (disabledOption) {
      const optionIndex = newOptions.findIndex(
        opt => opt.value === disabledOption
      )
      if (newOptions[optionIndex]) {
        (newOptions[optionIndex].disabled = true)
      }
    }

    setOptions(newOptions)
    setFetching(false)
  },
  [disabledOption, fetchOptions]
  )

  const debounceFetcher = useMemo(() => {
    return debounce(loadOptions, debounceTimeout)
  }, [loadOptions, debounceTimeout])

  const resetOptions = useCallback(() => {
    loadOptions('')
  }, [loadOptions])

  useEffect(() => {
    loadOptions('')
  }, [loadOptions])

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
      onSelect={resetOptions}
      showSearch
      allowClear
      onClear={() => {
        resetOptions()
        onClear()
      }}
      {...props}
    />
  )
}

export default SelectUser
