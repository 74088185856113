import { FC, useState } from 'react'
import { IconSearch } from '@tabler/icons-react'
import { useDebounce } from 'use-debounce'
import Icon from '../../atoms/icon'
import TextInput from '../../atoms/text-input'
import MerchantTable from '../../molecules/merchant-table/index'
import { Query } from '../../../hooks/query.hook'

const MerchantList: FC<{ presetQuery: Query }> = ({ presetQuery }) => {
  const [search, setSearch] = useState('')
  const [searchValue] = useDebounce(search, 500)

  return (
    <div className="merchant-list">
      <div className="merchant-actions">
        <div className="search-wrapper">
          <TextInput
            type="text"
            label=""
            className="borderless"
            value={search}
            placeholder="search"
            icon={<Icon component={IconSearch} />}
            handleChange={e => setSearch(e.target.value)}
          />
        </div>
      </div>
      <MerchantTable
        presetQuery={presetQuery}
        searchQuery={searchValue}
      />
    </div>
  )
}

export default MerchantList
