import { FC, useState } from 'react'
import { message, Typography } from 'antd'
import { IconReload } from '@tabler/icons-react'
import { merchantStatuses } from '../../../constants/merchant.constants'
import { inviteMerchant } from '../../../services/api/merchant.api'
import IconButton from '../../atoms/icon-button'

const ReinviteUser: FC<{ programmeId: string, email: string }> = ({ programmeId, email }) => {
  const [isDisabled, setIsDisabled] = useState(false)

  return (
    <IconButton
      disabled={isDisabled}
      type="primary"
      shape="circle"
      icon={IconReload}
      size="small"
      style={
        !isDisabled
          ? {
            backgroundColor: merchantStatuses.invited.color,
            border: 0,
          }
          : {}
      }
      onClick={async () => {
        setIsDisabled(true)
        const inviteResponse = await inviteMerchant(programmeId, email)
        if (inviteResponse.hasErrors) {
          setIsDisabled(false)
          message.error(
            <Typography.Text>
              Failed to resend the invite to the email{' '}
              <Typography.Text strong>{email}</Typography.Text>.
            </Typography.Text>
          )
          return
        }
        message.success(
          <Typography.Text>
            Successfully reinvited the user with the email{' '}
            <Typography.Text strong>{email}</Typography.Text>.
          </Typography.Text>
        )
      }}
    />
  )
}

export default ReinviteUser
