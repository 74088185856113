import { FC, ReactNode } from 'react'
import { Descriptions } from 'antd'
import classNames from 'classnames'
import './styles.scss'
import { LabelValue } from '../../../types/generic'

export type InfoBoxProps = {
  details?: LabelValue[],
  actions?: ReactNode[],
  className?: string,
  children?: ReactNode
}
const InfoBox: FC<InfoBoxProps> = ({ details, actions, className, children }) => {
  return (
    <div className={classNames('info-box', className)}>
      {details?.length && (
        <Descriptions
          column={1}
          className="details-container"
        >
          {details.map(({ label, value }) => (
            <Descriptions.Item
              label={label}
              key={label as string}
            >
              {value}
            </Descriptions.Item>
          ))}
        </Descriptions>
      )}

      {details?.length && !!actions?.length && (
        <hr className="info-box__separator" />
      )}

      {!!actions?.length && (
        <section className="info-box-actions__container">{actions}</section>
      )}

      {(details?.length || actions?.length) && children && (
        <hr className="info-box__separator" />
      )}

      {children && (
        <section className="info-box__children">
          {Array.isArray(children)
            ? children?.map((child, index) => (
              <div key={child.key}>
                {child}
                {index !== children.length - 1 && (
                  <hr className="info-box__separator" />
                )}
              </div>
            ))
            : children
          }

        </section>
      )}

    </div>
  )
}

export default InfoBox
