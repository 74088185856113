import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button } from 'antd'
import './styles.scss'
import { changePassword } from '../../../../services/api/user.api'
import PasswordInput from '../../../atoms/password-input'
import Message from '../../../atoms/message'
import { useGlobalMessage } from '../../../../hooks/global-message.hook'
import { messageTypes } from '../../../atoms/message/messageTypes'
import { CombinedReducers } from '../../../../redux/stores/reducers'
import { BaseUser } from '../../../../types/user'

const UserPasswordForm = () => {
  const userDetails = useSelector<CombinedReducers, BaseUser>(state => state.user.userDetails)
  const { handleSubmit } = useForm()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordIsValid, setNewPasswordIsValid] = useState(false)
  const [message, setMessage] = useGlobalMessage('')
  const [labelError, setLabelError] = useState('')
  const navigate = useNavigate()

  const saveUpdatedPassword = useCallback(async () => {
    const userId = userDetails.id
    const res = await changePassword(userId, {
      oldPassword: currentPassword,
      newPassword,
    })
    if (res.hasErrors) {
      return setLabelError('is incorrect')
    }

    setMessage('Details updated successfully!')
    setNewPasswordIsValid(false)
  }, [currentPassword, newPassword, setMessage, userDetails])

  return (
    <div className="user-password-wrapper">
      <div className="user-password-message">
        {message && (
          <Message
            type={messageTypes.success}
            onClick={() => setMessage('')}
          >
            {message}
          </Message>
        )}
      </div>
      <form
        className="user-password-form"
        onSubmit={handleSubmit(saveUpdatedPassword)}
      >
        <div className="form-inputs-wrapper">
          <div className="form-inputs">
            <PasswordInput
              value={currentPassword}
              error={labelError ? 'error' : ''}
              label={`Current Password ${labelError}`}
              placeholder="Current Password"
              handleChange={e => setCurrentPassword(e.target.value)}
              strengthCheck={false}
            />
            <PasswordInput
              value={newPassword}
              label="New Password"
              strengthCheck={true}
              strengthCheckAlign="bottom"
              placeholder="New Password"
              handleChange={e => setNewPassword(e.target.value)}
              onValidityChange={setNewPasswordIsValid}
            />
          </div>
        </div>

        <div className="form-actions">
          <Button
            size="large"
            onClick={() => navigate('/')}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            disabled={!currentPassword || !newPassword || !newPasswordIsValid}
            onClick={handleSubmit(saveUpdatedPassword)}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default UserPasswordForm
