import programmeStatusColors from '../styles/statusColors/_programme.module.scss'
import { Status } from '../types/generic'

export type ProgrammeStatuses = {
  active: Status
  archived: Status
  completed: Status
  draft: Status
  scheduled: Status
}

export const programmeStatuses: ProgrammeStatuses = {
  active: {
    key: 'active',
    label: 'active',
    color: programmeStatusColors.active,
  },
  archived: {
    key: 'archived',
    label: 'archived',
    color: programmeStatusColors.archived,
  },
  completed: {
    key: 'completed',
    label: 'completed',
    color: programmeStatusColors.completed,
  },
  draft: {
    key: 'draft',
    label: 'draft',
    color: programmeStatusColors.draft,
  },
  scheduled: {
    key: 'scheduled',
    label: 'scheduled',
    color: programmeStatusColors.scheduled,
  },
}

export type ProgrammeRedemptionStatuses = {
  active: Status
  paused: Status
}

export const programmeRedemptionStatuses: ProgrammeRedemptionStatuses = {
  active: {
    key: 'active',
    label: 'active',
    color: programmeStatusColors.redemptionActive,
  },
  paused: {
    key: 'paused',
    label: 'paused',
    color: programmeStatusColors.redemptionPaused,
  },
}

export enum IsOnline {
  online = 'Online',
  offline = 'Offline'
}

export enum IsEnrollmentEnabled {
  mandatory = 'Mandatory',
  optional = 'Optional',
}

export enum IsBiometricEnabled {
  enabled = 'Enabled',
  disabled = 'Disabled',
}

export enum IsInvoicingEnabled {
  on = 'On',
  off = 'Off',
}
