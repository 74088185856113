import { useMemo, FC } from 'react'
import { Table as AntTable } from 'antd'
import { ColumnsType, ColumnType, TableProps as AntTableProps } from 'antd/es/table'
import { Filter, UseQuery } from '../../../hooks/query.hook'
import { ExpandableConfig, SorterResult } from 'antd/es/table/interface'
import './styles.scss'

export type TableProps<T> = {
  data: T[],
  columns: ColumnsType<T>,
  expandable?: ExpandableConfig<T>
  hasSections?: boolean,
  pagination?: {
    numberOfPages: number,
    onPageChange: (page: number) => void,
    page: number,
    pageSize?: number,
  },
  onRowClick?: (rowData: T, index: number) => void,
  onSort?: UseQuery['onSort'],
  onFilters?: UseQuery['onFilters'],
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Table: FC<TableProps<any>> = ({
  data,
  columns,
  expandable,
  hasSections,
  pagination: { numberOfPages, onPageChange, page, pageSize = 10 } = {
    numberOfPages: null,
  },
  onRowClick,
  onSort,
  onFilters,
}) => {
  const getConfigurableValues = (config) => {
    const { type, options } = config
    return {
      type,
      options,
    }
  }

  const tableColumns: ColumnsType = useMemo<ColumnsType>(() =>
    columns.map((column: ColumnType<unknown>, index) => {
      const grouppingSectionSettings = hasSections
        ? {
          onCell: ({ isSection, sectionColumnsSpans }) => isSection
            ? {
              colSpan: sectionColumnsSpans[index] ?? 0,
              className: 'table-row--section',
            }
            : {},
        }
        : {}

      return {
        ...column,
        ...getConfigurableValues(column),
        ...grouppingSectionSettings,
      }
    }),
  [columns, hasSections])

  const tableDataSource: object[] = useMemo(() => {
    if (!hasSections) {
      return data
    }

    return data.reduce((acc, section) => (
      [
        ...acc,
        {
          id: section.id,
          isSection: true,
          sectionColumnsSpans: section.columns.reduce((acc, sectionColumn, index) => ({
            ...acc,
            [index]: sectionColumn.colSpan,
          }), {}),
          ...section.columns.reduce((acc, sectionColumn, index, array) => {
            const indexForColumn = index + index > 0 ? array[index - 1].colSpan - index : 0

            return {
              ...acc,
              [columns[indexForColumn].key]: sectionColumn.content,
            }
          }, {}),
        },
        ...section.data,
      ]
    ), [])
  }, [data, hasSections, columns])

  const onTableChange: AntTableProps<unknown>['onChange'] = (_, filteringData, sorting: SorterResult<unknown>, { action }) => {
    if (action === 'sort') {
      onSort(sorting.columnKey as string, sorting.order)
    }

    if (action === 'filter') {
      const columnKey = Object.keys(filteringData)[0]
      const column = tableColumns.find(({ key }) => key === columnKey)

      const filters: Filter[] = Object.keys(filteringData).map((filterName) => {
        return {
          name: filterName,
          value: column.filterMultiple
            ? filteringData[filterName] as unknown as string
            : filteringData[filterName]?.[0] as string,
        }
      })

      onFilters(filters)
    }
  }

  return (
    <>
      <div className="table-wrapper">
        <AntTable
          bordered
          columns={tableColumns}
          dataSource={tableDataSource}
          rowClassName={() => onRowClick && 'table-row--clickable'}
          rowKey="id"
          expandable={expandable}
          onChange={onTableChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                if (onRowClick) {
                  onRowClick(record, rowIndex)
                }
              },
            }
          }}
          pagination={
            numberOfPages !== null && {
              position: ['bottomRight'],
              defaultCurrent: page,
              current: page,
              pageSize,
              showSizeChanger: false,
              total: numberOfPages * pageSize,
              onChange: onPageChange,
            }
          }
        />
      </div>
    </>
  )
}

export default Table
