import { Typography } from 'antd'
import './styles.scss'
import { useLocation } from 'react-router-dom'
const { Paragraph, Link, Title } = Typography

const ProgrammeNotFound = () => {
  const { state } = useLocation();

  return (
    <div className="programme-not-found__wrapper">
      <div className="programme-not-found__content">
        <Title level={3}>
          The programme you are trying to access {state?.programmeId && `(${state.programmeId})`} either does not exist or you don&apos;t have access to it.
        </Title>
        <Paragraph>
          If you have any questions, please contact{' '}
          <Link
            href="mailto:support@f4id.org"
            title="support@f4id.org"
            strong
          >
            support@f4id.org
          </Link>
        </Paragraph>
      </div>
    </div>
  )
}

export default ProgrammeNotFound
