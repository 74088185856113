import { FC, ReactNode } from 'react'
import { Table as AntTable } from 'antd'
import './styles.scss'
import { getPaginationTotalText } from '../../../services/helpers/helpers'
import { Query } from '../../../hooks/query.hook'
import { ColumnsType, TableProps } from 'antd/es/table'
import { Transaction } from '../../../types/transactions'

const TransactionTable: FC<{
  presetQuery: Query,
  columns: ColumnsType,
  transactions: Transaction[],
  transactionsCount: number,
  children?: ReactNode,
  onPageChange: (page: number, pageSize: number) => void,
  onTableChange: TableProps<Transaction>['onChange'],
}> = ({
  presetQuery,
  columns,
  transactions,
  transactionsCount,
  children,
  onPageChange,
  onTableChange,
}) => (
  <div className="transaction-table">
    <div className="transaction-table__content">
      {children}
      <div className="transaction-table__table-wrapper">
        <AntTable
          dataSource={transactions}
          columns={columns}
          bordered
          rowKey="id"
          pagination={{
            current: presetQuery.page,
            pageSize: presetQuery.pageSize,
            total: transactionsCount,
            onChange: onPageChange,
            showTotal: getPaginationTotalText,
            showSizeChanger: false
          }}
          onChange={onTableChange}
        />
      </div>
    </div>
  </div>
)

export default TransactionTable
