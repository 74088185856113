import { IconLockOpen } from '@tabler/icons-react'
import { Button, Descriptions, message, Modal, Typography } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { userStatuses } from '../../../constants/user.constants'
import { UserRoles } from '../../../constants/userRoles.constants'
import { CombinedReducers } from '../../../redux/stores/reducers'
import {
  getFieldworker,
  setFieldworkerStatus as setFieldworkerStatusReq
} from '../../../services/api/fieldworker.api'
import { unlockUser } from '../../../services/api/user.api'
import { serverDateToString } from '../../../services/helpers/date'
import { isAdmin } from '../../../services/helpers/user'
import { Programme } from '../../../types/programme'
import IconButton from '../../atoms/icon-button'
import InfoBox from '../../atoms/info-box'
import StatusPill from '../../atoms/status-pill'
import { getFullName } from '../../../services/helpers/helpers'
import './styles.scss'

const { Paragraph, Text } = Typography

const FieldworkerDetails: FC<{ fieldworkerId: string }> = ({ fieldworkerId }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [status, setStatus] = useState('')
  const [isLocked, setIsLocked] = useState(false)
  const [createdAt, setCreatedAt] = useState('')
  const [createdBy, setCreatedBy] = useState('')
  const { activeProgramme, currentUserRoles, } = useSelector<CombinedReducers, { activeProgramme: Programme, currentUserRoles: UserRoles[] }>(state => ({
    activeProgramme: state.programme.activeProgramme,
    currentUserRoles: state.user.userRoles,
  }))

  const setFieldworkerDetails = (fieldworker) => {
    const { firstName, lastName, phone, email, status, isLocked, createdBy, createdAt } = fieldworker

    setFirstName(firstName)
    setLastName(lastName)
    setPhoneNumber(phone)
    setEmailAddress(email)
    setStatus(status)
    setIsLocked(isLocked)
    setCreatedAt(serverDateToString(createdAt))
    setCreatedBy(getFullName(createdBy))
  }

  useEffect(() => {
    if (activeProgramme && activeProgramme.id && fieldworkerId) {
      getFieldworker(activeProgramme.id, fieldworkerId).then((fieldworker) => {
        setFieldworkerDetails(fieldworker)
      })
    }
  }, [activeProgramme, fieldworkerId])

  const setFieldworkerStatus = useCallback(
    async (status) => {
      const fieldworkerRes = await setFieldworkerStatusReq(fieldworkerId, activeProgramme.id, status)
      if (fieldworkerRes.hasErrors) {
        message.error('An error ocurred while disabling the fieldworker.')
        return
      }
      setFieldworkerDetails(fieldworkerRes)
      message.success('Successfully updated the status')
    },
    [activeProgramme, fieldworkerId]
  )

  const onDisableFieldworker = useCallback(() => {
    Modal.confirm({
      title: 'Warning',
      onOk: () => setFieldworkerStatus(userStatuses.disabled.key),
      okText: 'Yes',
      cancelText: 'No',
      content: (
        <>
          <Paragraph>Disabling a fieldworker will result in the following:</Paragraph>
          <ul>
            <li>
              <Text>All the <Text strong>available certificates</Text> assigned to this fieldworker can
                no longer be enrolled.</Text>
            </li>
            <li>
              <Text>All the <Text strong>enrolled certificates</Text> can no longer be redeemed.</Text>
            </li>
          </ul>
          <Paragraph strong>Are you sure you want to disable the fieldworker account?</Paragraph>
        </>
      ),
    })
  },
  [setFieldworkerStatus]
  )

  const onUnlockUser = useCallback(async () => {
    const res = await unlockUser(fieldworkerId)
    if (res.hasErrors) {
      message.error('Could not unlock the fieldworker account')
      return
    }
    setIsLocked(res.isLocked)
    message.success('The fieldworker account has been unlocked.')
  }, [fieldworkerId])

  const onEnableFieldworker = useCallback(
    async (e) => {
      e.preventDefault()
      await setFieldworkerStatus(userStatuses.active.key)
    },
    [setFieldworkerStatus]
  )

  const getInfoBoxActions = useCallback(() => {
    const actions = []

    if (isAdmin(currentUserRoles) && isLocked) {
      actions.push(
        <IconButton
          type="primary"
          onClick={onUnlockUser}
          key="Unlock Fieldworker Account"
          icon={IconLockOpen}
        >
          Unlock Fieldworker Account
        </IconButton>
      )
    }

    if (status === userStatuses.active.key) {
      actions.push(
        <Button
          type="text"
          onClick={onDisableFieldworker}
          danger
          key="Disable Fieldworker Account"
        >
          Disable Fieldworker Account
        </Button>
      )
    }

    if (status === userStatuses.disabled.key) {
      actions.push(
        <Button
          type="link"
          onClick={onEnableFieldworker}
          key="Enable Fieldworker Account"
        >
          Enable Fieldworker Account
        </Button>
      )
    }

    return actions
  }, [
    currentUserRoles,
    onDisableFieldworker,
    onEnableFieldworker,
    isLocked,
    status,
    onUnlockUser,
  ])

  return (
    <div className="fieldworker-details__content">
      <div className="first-column">
        <Descriptions
          column={1}
          layout="vertical"
          colon={null}
        >
          <Descriptions.Item label="First Name">
            {firstName}
          </Descriptions.Item>
          <Descriptions.Item label="Last Name">
            {lastName}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {phoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Email Address">
            {emailAddress}
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div className="second-column">
        <InfoBox
          actions={getInfoBoxActions()}
          details={[
            {
              label: 'Status',
              value: <StatusPill statusData={userStatuses[status]} />,
            },
            {
              label: 'Created on',
              value: createdAt,
            },
            {
              label: 'Created by',
              value: createdBy,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default FieldworkerDetails
