import { FC, useCallback, useState } from 'react'
import { Typography, Form, Input, Button, message, Modal } from 'antd'
import { IconCheck } from '@tabler/icons-react'
import colors from '../../../styles/_variables.module.scss'
import { inviteMerchant } from '../../../services/api/merchant.api'
import Icon from '../../atoms/icon'

const { Text, Paragraph } = Typography

const InviteMerchant: FC<{ programmeId: string, onSuccess: () => Promise<void> }> = ({ programmeId, onSuccess }) => {
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCancel = useCallback(() => {
    setIsModalOpen(false)
    setIsSuccessful(false)
  }, [])

  const onFinish = useCallback(
    async ({ email }) => {
      const inviteResponse = await inviteMerchant(programmeId, email)
      if (inviteResponse.hasErrors) {
        message.error(
          <>
            <Text>
              Failed to invite the user with the email <Text strong>{email}</Text>.
            </Text>
            <br />
            <Text>{inviteResponse.message}</Text>
          </>
        )
        return
      }
      setIsSuccessful(true)
      onSuccess()
    },
    [onSuccess, programmeId]
  )

  const onFinishFailed = () => {
    message.error(<Text>Failed to invite the user.</Text>)
  }

  return (
    <>
      <Button
        type="primary"
        size="large"
        onClick={openModal}
      >
        Invite
      </Button>
      <Modal
        title="Invite Supplier"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="invite-modal"
      >
        {isSuccessful ? (
          <>
            <Paragraph className="text__successful">
              <Icon
                component={IconCheck}
                style={{ color: colors.success }}
              />
              Your Invitation was sent successfully!
            </Paragraph>
            <div className="invite-again-btn-wrapper">
              <Button
                type="default"
                onClick={() => setIsSuccessful(false)}
              >
                Invite someone else
              </Button>
            </div>
          </>
        ) : (
          <>
            <Paragraph>
              Please use the supplier email to send an invite.
            </Paragraph>
            <div className="invitation-form">
              <Form
                name="basic"
                layout="inline"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the e-mail address!',
                    },
                    {
                      type: 'email',
                      message: 'Please input a valid e-mail address',
                    },
                  ]}
                >
                  <Input
                    placeholder="E-mail"
                    autoFocus
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

export default InviteMerchant
