import { FC, useMemo, useState } from 'react'
import { Input, InputNumber, InputNumberProps, InputProps, Typography } from 'antd'
import './styles.scss'
import EditableField, { EditableFieldContext } from './editable-field'

type EditableInputProps = InputProps & InputNumberProps & {
  defaultValue: string
  isEditable: boolean,
  currency?: string,
  isNumber?: boolean
  onSave: (fieldValue: string) => Promise<void>
}

const EditableInput: FC<EditableInputProps> = ({
  defaultValue,
  isEditable,
  currency,
  isNumber = false,
  onSave,
  ...originalInputProps
}) => {
  const [inputValue, setInputValue] = useState(null)

  const sanitizedInputProps: Omit<EditableInputProps, 'defaultValue' | 'isEditable' | 'currency' | 'isNumber' | 'onSave' | 'onChange' | 'value' > = useMemo(() => {
    const {
      onChange: _onChange,
      value: _value,
      ...inputProps
    } = originalInputProps

    return inputProps
  },
  [originalInputProps])

  const readOnlyText = isNumber
    ? `${defaultValue} ${currency}`
    : defaultValue

  return (
    <EditableFieldContext.Provider
      value={{
        fieldValue: inputValue,
        setFieldValue: setInputValue
      }}
    >
      <EditableField
        readOnlyContent={(
          <Typography.Text
            ellipsis
            title={readOnlyText}
          >
            { readOnlyText}
          </Typography.Text>
        )}
        className="editable-field__input"
        isSaveDisabled={!inputValue}
        onSave={onSave}
        isEditable={isEditable}
      >
        {isNumber
          ? (
            <InputNumber
              defaultValue={defaultValue}
              onChange={(value) => {
                setInputValue(value)
              }}
              addonAfter={currency}
              {...sanitizedInputProps as InputNumberProps}
            />
          )
          : (
            <Input
              defaultValue={defaultValue}
              onChange={(e) => {
                setInputValue(e.target.value)
                // sanitizedInputProps.onChange(e)
              }}
              {...sanitizedInputProps as InputProps}
            />
          )
        }
      </EditableField>
    </EditableFieldContext.Provider>
  )
}

export default EditableInput
