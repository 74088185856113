import { FC, useState } from 'react'
import './styles.scss'
import EditableField, { EditableFieldContext } from './editable-field'
import { getFormattedCountryAndTimezone } from '../../../services/helpers/internationalization';
import SelectCountryAndTimezone from '../../atoms/select-country-and-timezone';

const EditableCountryAndTimezone: FC<{
  defaultValue: [string, string]
  isEditable: boolean
  onSave: (fieldValue: [string, string]) => Promise<void>
}> = ({
  defaultValue,
  isEditable,
  onSave,
}) => {
  const [inputValue, setInputValue] = useState(null)

  return (
    <EditableFieldContext.Provider
      value={{
        fieldValue: inputValue,
        setFieldValue: setInputValue
      }}
    >
      <EditableField
        readOnlyContent={defaultValue && getFormattedCountryAndTimezone(defaultValue)}
        className="editable-field__timezone"
        isSaveDisabled={!inputValue}
        onSave={onSave}
        isEditable={isEditable}
      >
        <SelectCountryAndTimezone
          defaultValue={defaultValue}
          onChange={((value) => {
            setInputValue(value)
          })}
        />
      </EditableField>
    </EditableFieldContext.Provider>
  )
}

export default EditableCountryAndTimezone
