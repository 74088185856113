import withIconWrapper from './with-icon-wrapper'

const GoodsSvg = withIconWrapper(() => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33337 0H6.66671V1.33333H3.33337V0ZM3.57349 2H6.4266L7.96506 5.33333H14.6667V12C14.6667 13.1046 13.7713 14 12.6667 14H10.1647C9.52802 15.0851 8.34911 15.8137 7.00004 15.8137C5.65097 15.8137 4.47206 15.0851 3.83534 14H3.33337C2.2288 14 1.33337 13.1046 1.33337 12V7.29285C1.33337 7.00345 1.39618 6.7175 1.51746 6.45473L3.57349 2ZM3.36991 12.6667C3.34583 12.4969 3.33337 12.3234 3.33337 12.1471C3.33337 10.122 4.975 8.48039 7.00004 8.48039C7.1124 8.48039 7.22359 8.48544 7.33337 8.49533L7.33337 7.29285C7.33337 7.19638 7.31244 7.10106 7.27201 7.01348L5.57349 3.33333H4.4266L2.72807 7.01348C2.68764 7.10106 2.66671 7.19638 2.66671 7.29285V12C2.66671 12.3682 2.96518 12.6667 3.33337 12.6667H3.36991ZM9.35123 9.33333C10.1553 10.0059 10.6667 11.0168 10.6667 12.1471C10.6667 12.3234 10.6543 12.4969 10.6302 12.6667H12.6667C13.0349 12.6667 13.3334 12.3682 13.3334 12L13.3334 9.33333H9.35123ZM8.66671 8H13.3334L13.3334 6.66667H8.66671V7.29285V8ZM4.66671 12.1471C4.66671 10.8584 5.71138 9.81372 7.00004 9.81372C8.2887 9.81372 9.33337 10.8584 9.33337 12.1471C9.33337 13.4357 8.2887 14.4804 7.00004 14.4804C5.71138 14.4804 4.66671 13.4357 4.66671 12.1471Z"
      fill="black"
      fillOpacity="0.56"
    />
  </svg>
))

export default GoodsSvg
