import { useParams } from 'react-router-dom'

import PageBackLink from '../../atoms/page-back-link'
import Tabs from '../../atoms/tabs'
import CreateVouchersForm from '../../organisms/create-vouchers-form'

const CreateVouchers = () => {
  const { viewContext } = useParams()

  const tabsContent = [
    {
      title: 'Create Certificates',
      component: <CreateVouchersForm />,
    },
  ]

  return (
    <div className="create-vouchers">
      <PageBackLink
        url={`/${viewContext}/certificates`}
        pageName="List"
      />

      <Tabs tabsContent={tabsContent} />
    </div>
  )
}

export default CreateVouchers
