import { useState, useEffect, useCallback, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { Button, Input, message } from 'antd'
import { IconDownload, IconX } from '@tabler/icons-react'
import './styles.scss'
import Card from '../../atoms/card'
import Message from '../../atoms/message'
import VouchersBatchesTable from '../../molecules/voucher-batches-table'
import { programmeStatuses } from '../../../constants/programme.constants'
import { getVouchersReportCSV } from '../../../services/api/voucher.api'
import Icon from '../../atoms/icon'
import { messageTypes } from '../../atoms/message/messageTypes'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'

const { Search } = Input

const VoucherBatchesList = () => {
  const [search, setSearch] = useState('')
  const [disableMessage, setDisableMessage] = useState<ReactNode>('')
  const [disabled, setDisabled] = useState(false)
  const [isProgrammeComplete, setIsProgrammeComplete] = useState(false)

  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  const messageData = useLocation().state?.message
  const navigate = useNavigate()

  useEffect(() => {
    if (messageData) {
      message[messageData.type](messageData.text)
    }
  }, [messageData])

  useEffect(() => {
    if (activeProgramme) {
      const { status } = activeProgramme
      const statusesWithDisabledState = [
        programmeStatuses.archived.key,
        programmeStatuses.draft.key,
      ]
      if (statusesWithDisabledState.includes(status as string)) {
        setDisabled(true)
        setDisableMessage(
          <span>
            <p>
              The creation of new certificate batches is suspended <br /> while
              the programme status is{' '}
              <b>{programmeStatuses.archived.label.toLocaleUpperCase()}</b>
            </p>
          </span>
        )
      }
      if (status === programmeStatuses.completed.key) {
        setIsProgrammeComplete(true)
      }
    }
  }, [activeProgramme])

  const downloadCsv = useCallback(
    async () => {
      const { data, filename } = await getVouchersReportCSV(activeProgramme.id)
      saveAs(data, filename)
    },
    [activeProgramme]
  )

  return (
    <div className="voucher-batches-list">
      <div className="vouchers-actions">
        <Search
          value={search}
          allowClear
          size="large"
          placeholder="Search by certificate # or household number"
          className="search-wrapper"
          onChange={e => setSearch(e.target.value)}
        />

        <div className="vouchers-actions__buttons">
          <Button
            size="large"
            type="default"
            onClick={downloadCsv}
            icon={(
              <Icon
                component={IconDownload}
                componentProps={{ height: '1.2rem' }}
              />
            )}
          >
            Download report
          </Button>

          {!isProgrammeComplete && (
            <Button
              size="large"
              type="primary"
              disabled={disabled}
              onClick={() => navigate('create')}
            >
              Create Certificates
            </Button>
          )}
        </div>
      </div>

      <div className="global-disabled-message">
        {!!disableMessage && (
          <Message
            type={messageTypes.suspend}
            icon={<Icon component={IconX} />}
            onClick={() => setDisableMessage('')}
          >
            {disableMessage}
          </Message>
        )}
      </div>
      <Card>
        <VouchersBatchesTable searchQuery={search} />
      </Card>
    </div>
  )
}

export default VoucherBatchesList
