import { useState, useEffect, useCallback, FC } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, message, Space, Table, Typography } from 'antd'
import { IconBan, IconSquareMinus, IconSquarePlus, IconStack2 } from '@tabler/icons-react';
import dayjs from 'dayjs';
import './styles.scss'
import { SortDirections, useQuery } from '../../../hooks/query.hook'
import { getVoucherBatches } from '../../../services/api/voucher.api'
import { capitalizeFirstLetter, getFullName, getPaginationTotalText } from '../../../services/helpers/helpers'
import { voucherBatchRenewalTypes, voucherBatchStatuses } from '../../../constants/voucherBatch.constants'
import { getFieldworkers } from '../../../services/api/fieldworker.api'
import Icon from '../../atoms/icon';
import StatusPill from '../../atoms/status-pill';
import VouchersTable from '../vouchers-table';
import { getByKey } from '../../../services/helpers/constants';
import { CombinedReducers } from '../../../redux/stores/reducers';
import { Programme } from '../../../types/programme';
import { ColumnsType } from 'antd/es/table';
import { VoucherBatch } from '../../../types/voucher';

const { Text } = Typography

const defaultQuery = {
  order: 'createdAt',
  orderType: SortDirections.descend,
  pageSize: 10,
  page: 1,
}

const VouchersBatchesTable: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const [voucherBatches, setVoucherBatches] = useState([])
  const [search, setSearch] = useState('')
  const [voucherBatchesCount, setVoucherBatchesCount] = useState(0)
  const [fieldworkers, setFieldworkers] = useState([])

  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  const navigate = useNavigate()
  const {
    query,
    onSearch,
    onFilters,
    onPageChange,
  } = useQuery(defaultQuery)

  useEffect(() => {
    if (searchQuery !== search) {
      onSearch(searchQuery, 'search')
      setSearch(searchQuery)
    }
  }, [onSearch, search, searchQuery])

  const getData = useCallback(() => {
    if (activeProgramme) {
      getVoucherBatches(activeProgramme.id, query).then((res) => {
        if (res.hasError) {
          message.error('Failed to obtain the certificate batches')
          return
        }

        setVoucherBatchesCount(res.count)
        setVoucherBatches(res.voucherBatches?.map((voucherBatch) => {
          return {
            id: voucherBatch.id,
            issuedTo: voucherBatch.fieldWorker,
            createdOn: dayjs(voucherBatch.createdAt).format('L'),
            totalCertificates: voucherBatch.number,
            isRenewable: voucherBatch.isRenewable,
            renewalType: voucherBatch.renewalType,
            status: voucherBatch.status,
            vouchers: voucherBatch.vouchers || [],
            certificateValue: voucherBatch.fixedValue,
          }
        }))
      })
    }
  }, [activeProgramme, query])

  useEffect(() => {
    getData()
  }, [activeProgramme, getData])

  useEffect(() => {
    if (activeProgramme) {
      getFieldworkers(activeProgramme.id, { order: 'firstName', orderType: SortDirections.ascend }).then((res) => {
        if (res.hasErrors) {
          message.error('Failed to obtain the existing fieldworkers')
          return
        }

        setFieldworkers(res.fieldWorkers?.map(fieldworker => ({
          text: getFullName(fieldworker),
          value: fieldworker.id
        })))
      })
    }
  }, [activeProgramme])

  const tableColumns: ColumnsType<VoucherBatch> = [
    {
      title: 'Issued to',
      key: 'issuedTo',
      dataIndex: 'issuedTo',
      filters: fieldworkers,
      render: text => (
        <Space>
          <Icon
            component={IconStack2}
            componentProps={{ strokeWidth: 1 }}
          />
          <Text>{text}</Text>
        </Space>
      )
    },
    {
      title: 'Created on',
      key: 'createdOn',
      dataIndex: 'createdOn',
    },
    {
      title: 'No. certificates',
      key: 'totalCertificates',
      dataIndex: 'totalCertificates',
    },
    {
      title: 'Certificate value',
      key: 'certificateValue',
      dataIndex: 'certificateValue',
      render: text => `${text} ${activeProgramme.currency}`
    },
    {
      title: 'Renewable',
      key: 'isRenewable',
      dataIndex: 'isRenewable',
      align: 'center',
      render: (_, record) => (
        <div className="is-renewable-icon__wrapper">
          {record.isRenewable
            ? getByKey(voucherBatchRenewalTypes, record.renewalType).label
            : <IconBan />}
        </div>
      )
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      filterMultiple: false,
      align: 'center',
      filters: Object.keys(voucherBatchStatuses).map(status => (
        {
          text: capitalizeFirstLetter(voucherBatchStatuses[status].label),
          value: voucherBatchStatuses[status].key
        }
      )),
      render: text =>
        <StatusPill statusData={voucherBatchStatuses[text]} />
    },
  ]

  const onTableChange = (_, filters, __, extra) => {
    if (extra.action === 'filter') {
      const mappedFilters = Object.keys(filters).map((filterName) => {
        switch (filterName) {
          case 'status':
            return {
              name: 'batchStatus',
              value: filters[filterName] && filters[filterName][0]
            }

          case 'issuedTo':
            return {
              name: 'fieldworkerIds',
              value: filters[filterName] && filters[filterName]
            }

          default:
            return {
              name: filterName,
              value: filters[filterName]
            }
        }
      })
      onFilters(mappedFilters)
    }
  };

  return (
    <div className="voucher-batches-table">
      <Table
        bordered
        dataSource={voucherBatches}
        columns={tableColumns}
        rowKey="id"
        rowClassName="voucher-batches__row"
        onChange={onTableChange}
        expandable={!!searchQuery && {
          expandedRowRender: (voucherBatchRecord, index) => (
            <VouchersTable
              vouchers={voucherBatches[index].vouchers}
              fieldworkerName={voucherBatchRecord.issuedTo}
              voucherBatchId={voucherBatchRecord.id}
              searchQuery={searchQuery}
              programme={activeProgramme}
              onOperationCompleted={getData}
            />
          ),
          showExpandColumn: !!searchQuery,
          expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Button
                icon={(
                  <Icon
                    component={IconSquareMinus}
                    componentProps={{ strokeWidth: 1 }}
                  />
                )}
                type="text"
                onClick={e => onExpand(record, e)}
              />
            ) : (
              <Button
                icon={(
                  <Icon
                    component={IconSquarePlus}
                    componentProps={{ strokeWidth: 1 }}
                  />
                )}
                type="text"
                onClick={e => onExpand(record, e)}
              />
            )
        }}
        pagination={{
          current: query.page,
          pageSize: query.pageSize,
          total: voucherBatchesCount,
          onChange: onPageChange,
          showTotal: getPaginationTotalText,
          showSizeChanger: false
        }}
        onRow={record => ({
          onClick: () => navigate(`${record.id}`, { state: [query] }),
        })}
      />
    </div>
  )
}

export default VouchersBatchesTable
