import { FC, ReactNode } from 'react'
import { Card } from 'antd'
import classNames from 'classnames'
import './styles.scss'
import CardContent from '../card-content'

export type CardProps = {
  title: string
  icon: ReactNode
  infoText?: ReactNode
  className?: string
  children: ReactNode
}

const SimpleCard: FC<CardProps> = ({ className, ...cardProps }) => {
  return (
    <Card
      hoverable
      className={classNames([
        'card',
        'card-simple',
        className,
      ])}
    >
      <CardContent {...cardProps} />
    </Card>
  )
}

export default SimpleCard
