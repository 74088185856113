import { FC } from 'react'
import { IconBan, IconDownload, IconFileDescription, IconPhoto } from '@tabler/icons-react'
import './styles.scss'
import Icon from '../icon'
import EllipsisMiddle from '../ellipsis-middle';

const getFileType = (fileName) => {
  const imageExtensions = ['png', 'jpg', 'jpeg', 'svg']
  const spreadName = fileName.split('.')
  return imageExtensions.includes(
    spreadName[spreadName.length - 1].toLowerCase()
  )
    ? 'img'
    : 'doc'
}

const DocumentsModule: FC<{
  files: string[],
  downloadFile: (fileName: string) => Promise<void>
}> = ({ files, downloadFile }) => {
  return (
    <div className="documents-module-container">
      <p>Documents</p>
      {files?.length ? (
        <ul className="documents-list">
          {files.map((file, i) => (
            <li
              key={i}
              onClick={() => downloadFile(file)}
            >
              <div className="doc-icon">
                {getFileType(file) === 'img' ? (
                  <Icon component={IconPhoto} />
                ) : (
                  <Icon component={IconFileDescription} />
                )}
              </div>

              <EllipsisMiddle>
                {file}
              </EllipsisMiddle>

              <div className="download-doc-icon">
                <Icon component={IconDownload} />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="no-data">
          <Icon component={IconBan} />
          No data
        </p>
      )}
    </div>
  )
}

export default DocumentsModule
