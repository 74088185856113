import { Outlet } from 'react-router-dom'
import './styles.scss'
import Sidebar from '../../molecules/sidebar'
import { Layout } from 'antd'

const { Sider, Content } = Layout

const TwoColumnLayout = () => (
  <Layout
    hasSider
    className="two-column-layout"
  >
    <Sider width="300px">
      <Sidebar />
    </Sider>
    <Layout>
      <Content className="two-column-layout__content">
        <Outlet />
      </Content>
    </Layout>
  </Layout>
)

export default TwoColumnLayout
