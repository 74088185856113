import classNames from 'classnames'
import { FC, HTMLAttributes, PropsWithChildren } from 'react'
import './styles.scss'

const ChartTooltipContainer: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement> & { className?: string}>> = ({ children, className, ...props }) => {
  return (
    <div
      className={classNames('chart-tooltip-container', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export default ChartTooltipContainer
