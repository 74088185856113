import withIconWrapper from './with-icon-wrapper'

const NutritionalValueMediumSvg = withIconWrapper(() => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66671 14L8.66671 2L11.3334 2L11.3334 14L8.66671 14ZM4.66671 14L4.66671 2L7.33337 2L7.33337 14L4.66671 14Z"
      fill="#F27630"
    />
  </svg>
))

export default NutritionalValueMediumSvg

