import withIconWrapper from './with-icon-wrapper'

const NutritionalValueHighSvg = withIconWrapper(() => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 2L13.3334 2L13.3334 14L10.6667 14L10.6667 2ZM6.66671 2L6.66671 14L9.33337 14L9.33337 2L6.66671 2ZM2.66671 2L2.66671 14L5.33337 14L5.33337 2L2.66671 2Z"
      fill="#4CBF64"
    />
  </svg>
))

export default NutritionalValueHighSvg
