import { useLocation, useParams } from 'react-router-dom'

import Tabs from '../../atoms/tabs'
import PageBackLink from '../../atoms/page-back-link'
import FieldworkerDetailsContent from '../../organisms/fieldworker-details'

const FieldworkerDetails = () => {
  const { viewContext, fieldworkerId } = useParams()
  const [query] = useLocation().state || []

  const tabsContent = [
    {
      title: 'User Details',
      component: <FieldworkerDetailsContent fieldworkerId={fieldworkerId} />,
    },
  ]

  return (
    <div className="fieldworker-details">
      <PageBackLink
        url={`/${viewContext}/fieldworkers`}
        pageName="List"
        state={[query]}
      />

      <Tabs tabsContent={tabsContent} />
    </div>
  )
}

export default FieldworkerDetails
