import { useState, useEffect, useCallback, useMemo, useRef, HTMLAttributes, FC } from 'react'
import {
  Popconfirm,
  Form,
  InputNumber,
  Input,
  Table,
  Tooltip,
  AutoComplete,
  message,
} from 'antd'
import { IconCheck, IconPencil, IconTrash, IconX } from '@tabler/icons-react'
import './styles.scss'
import ContentWithAction from '../../templates/content-with-action'
import EditableCell from '../../atoms/editable-table-cell'
import FormItem from '../../atoms/ant-form-item'
import EditableImageWithText, {
  ReadonlyTitleAndSubtitle,
  ReadonlySubtitle,
} from '../../molecules/editable-image-with-text'
import {
  getGlobalItems,
  getItemCategories,
  getProgrammeItems,
  addProgrammeItem,
  updateProgrammeItem,
  removeProgrammeItem,
} from '../../../services/api/item.api'
import { getImageTypeNotSupportedMessage, isImageTypePdfCompatible } from '../../../services/helpers/images'
import { replaceElementById } from '../../../services/helpers/helpers'
import IconButton from '../../atoms/icon-button'
import { Item, ProgrammeItem } from '../../../types/item'
import { DefaultOptionType } from 'antd/es/select'
import { Programme } from '../../../types/programme'

const mapCategory = ({ id, name, image }) => ({
  id,
  name,
  image,
  value: name,
})

const mapCategories = (categories) => {
  return categories.map(mapCategory)
}

const mapItems = items =>
  items.map((item, index) => ({
    ...item,
    category: mapCategory(item.category),
    key: index,
  }))

const mapOptions = (data, propertyName) =>
  data.map(dataItem => ({
    ...dataItem,
    value: dataItem[propertyName],
  }))

const ProgrammeItemsManagement: FC<{ programme: Programme }> = ({ programme }) => {
  const [programmeItems, setProgrammeItems] = useState<ProgrammeItem[]>([])
  const [globalItems, setGlobalItems] = useState([])
  const [categories, setCategories] = useState([])
  const [activeItem, setActiveItem] = useState <ProgrammeItem>(null)
  const [file, setFile] = useState(null)
  const [form] = Form.useForm()
  const newItemRowRef = useRef(null)

  useEffect(() => {
    if (!programme?.id) {
      return
    }

    getProgrammeItems(programme.id).then((res) => {
      setProgrammeItems(mapItems(res))
    })
    getItemCategories().then(res =>
      setCategories(mapCategories(res))
    )

    getGlobalItems().then(res => setGlobalItems(res))
  }, [programme])

  useEffect(() => {
    if (programmeItems[programmeItems.length - 1]?.isNew) {
      newItemRowRef.current.scrollIntoView(true)
    }
  }, [programmeItems])

  const refreshProgrammeItems = useCallback(async () => {
    const newProgrammeItems = await getProgrammeItems(programme.id)
    setProgrammeItems(mapItems(newProgrammeItems))
  }, [programme])

  const availableItems = useMemo(() => {
    if (!programmeItems || !programmeItems.length) {
      return []
    }
    return globalItems.filter(
      globalItem =>
        programmeItems.findIndex(
          programmeItem => programmeItem.id === globalItem.id
        ) === -1
    )
  }, [globalItems, programmeItems])

  const isItemEdited = useCallback(
    itemkey => itemkey === activeItem?.key,
    [activeItem]
  )

  const edit = useCallback(
    (item) => {
      form.setFieldsValue({
        name: '',
        category: '',
        unitType: '',
        quantity: '',
        ...item,
      })
      setActiveItem(item)
    },
    [form]
  )

  const cancel = useCallback(() => {
    if (activeItem?.isNew) {
      setProgrammeItems(programmeItems.slice(0, -1))
    }
    setActiveItem(null)
    setFile(null)
  }, [activeItem, programmeItems])

  const addItem = useCallback(
    async (itemData) => {
      const formData = new FormData()
      formData.append('itemId', itemData.id)
      formData.append('categoryId', itemData.category.id)
      formData.append('quantity', itemData.quantity)
      formData.append('unitType', itemData.unitType)
      formData.append('image', file)

      const newItems = [...programmeItems]
      const newProgrammeItemRes = await addProgrammeItem(programme.id, formData)
      if (newProgrammeItemRes.hasErrors) {
        throw new Error('Failed to add the item to the programme.')
      }
      newItems[newItems.length - 1] = newProgrammeItemRes

      setProgrammeItems(newItems)
      message.success('The item has been added successfully')
    },
    [programme, file, programmeItems]
  )

  const updateItem = useCallback(
    async (itemData) => {
      const formData = new FormData()
      const originalItem = programmeItems.find(
        item => item.id === itemData.id
      )

      if (originalItem.category.id !== itemData.category.id) {
        formData.append('categoryId', itemData.category.id)
      }
      if (originalItem.quantity !== itemData.quantity) {
        formData.append('quantity', itemData.quantity)
      }
      if (originalItem.unitType !== itemData.unitType) {
        formData.append('unitType', itemData.unitType)
      }
      if (file) {
        formData.append('image', file)
      }
      const updatedProgrammeItemRes = await updateProgrammeItem(programme.id, itemData.id, formData)
      if (updatedProgrammeItemRes.hasErrors) {
        throw new Error('Could not update the item in the programme')
      }

      setProgrammeItems(replaceElementById(programmeItems, updatedProgrammeItemRes, itemData.id))
      message.success('The item has been updated successfully')
    },
    [programmeItems, file, programme]
  )

  const deleteItem = useCallback(
    async (itemId) => {
      try {
        await removeProgrammeItem({
          programmeId: programme.id,
          itemId,
        })
        await refreshProgrammeItems()
      } catch (error) {
        message.error('Failed to delete the programme item')
      }
    },
    [programme, refreshProgrammeItems]
  )

  const save = useCallback(async () => {
    try {
      const tableRowData = await form.validateFields()
      const itemData = {
        ...tableRowData,
        category: activeItem.category,
        id: activeItem.id,
      }

      if (!itemData.category?.id) {
        throw new Error('The category is missing')
      }

      if (!itemData.id) {
        throw new Error('The item is missing')
      }

      if (!activeItem.image) {
        throw new Error('Please select an image for the category')
      }

      activeItem.isNew === true
        ? await addItem(itemData)
        : await updateItem(itemData)

      setActiveItem(null)
      await refreshProgrammeItems()
      setFile(null)
    } catch (error) {
      message.error(error.message)
    }
  }, [form, activeItem, addItem, updateItem, refreshProgrammeItems])

  const editableFields = useMemo(() => {
    return {
      name: (
        <>
          <EditableImageWithText
            isImageEditable
            isTextEditable={!!activeItem?.isNew}
            imageFile={file}
            currentImage={activeItem?.image}
            beforeUpload={(imageFile) => {
              if (!isImageTypePdfCompatible(imageFile.type)) {
                message.error(getImageTypeNotSupportedMessage(imageFile.name));
                return false;
              }
              setActiveItem({
                ...activeItem,
                image: URL.createObjectURL(imageFile),
              })
              setFile(imageFile)
              return false
            }}
            title={{
              formFieldTitle: 'Item name',
              formPropertyName: 'name',
              onInputChange: (e) => {
                setActiveItem({
                  ...activeItem,
                  name: e.target.value,
                })
              },
              value: activeItem?.isNew ? (
                <>
                  <FormItem
                    propertyName="name"
                    fieldTitle="Item name"
                  >
                    <AutoComplete
                      options={mapOptions(availableItems, 'name')}
                      placeholder="Item name"
                      filterOption={(inputValue, option) =>
                        (option?.value as string)
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) !== -1
                      }
                      onChange={async (value, option: Item & DefaultOptionType) => {
                        const newActiveItem = {
                          ...activeItem,
                          name: value,
                        }
                        if (Object.keys(option).length) {
                          newActiveItem.id = option.id
                          newActiveItem.name = option.name
                          newActiveItem.image = option.image
                          newActiveItem.description = option.description
                          setFile(null)
                        }
                        setActiveItem(newActiveItem)
                      }}
                    />
                  </FormItem>
                  <ReadonlySubtitle subtitle={activeItem.description} />
                </>
              ) : (
                <ReadonlyTitleAndSubtitle
                  title={activeItem?.name}
                  subtitle={activeItem?.description}
                />
              ),
            }}
          />
        </>
      ),
      category: (
        <FormItem
          propertyName="category"
          fieldTitle="Category"
        >
          <AutoComplete
            style={{ width: 200 }}
            options={mapCategories(categories)}
            placeholder="Category"
            filterOption={(inputValue, option) =>
              (option?.value as string).toLowerCase().indexOf(inputValue.toLowerCase())
              !== -1
            }
            onChange={async (value, option: Item & DefaultOptionType) => {
              setActiveItem({
                ...activeItem,
                category: option.id ? option : { name: value },
              })
            }}
          />
        </FormItem>
      ),
      combinedUnit: (
        <div className="programme-items-management__unit-type-container">
          <FormItem
            propertyName="quantity"
            fieldTitle="Unit Value"
          >
            <InputNumber
              placeholder="Quantity"
              min={0}
            />
          </FormItem>
          <FormItem
            propertyName="unitType"
            fieldTitle="Unit Type"
          >
            <Input placeholder="Unit" />
          </FormItem>
        </div>
      ),
    }
  }, [activeItem, availableItems, categories, file])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'Item name',
        key: 'name',
        dataIndex: 'name',
        width: '40%',
        editable: true,
        render: (text, record) => {
          return (
            <EditableImageWithText
              currentImage={record.image}
              title={{ value: text }}
              subtitle={{ value: record.description }}
            />
          )
        },
      },
      {
        title: 'Category',
        key: 'category',
        dataIndex: ['category', 'name'],
        width: '25%',
        editable: true,
      },
      {
        title: 'Unit type',
        key: 'combinedUnit',
        dataIndex: 'combinedUnit',
        width: '25%',
        editable: true,
        render: (_, record) => `${record.quantity} ${record.unitType}`,
      },
      {
        title: 'Actions',
        key: 'operation',
        dataIndex: 'operation',
        width: '10%',
        render: (_, record) => {
          return (
            <div className="programme-items-management__action-buttons">
              {isItemEdited(record.key) ? (
                <>
                  <Form.Item>
                    <Tooltip title="Cancel">
                      <Popconfirm
                        title="Cancel editing?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={cancel}
                      >
                        <IconButton
                          shape="circle"
                          icon={IconX}
                        />
                      </Popconfirm>
                    </Tooltip>
                  </Form.Item>

                  <Form.Item>
                    <Tooltip title="Save">
                      <IconButton
                        htmlType="submit"
                        shape="circle"
                        type="primary"
                        icon={IconCheck}
                      />
                    </Tooltip>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Tooltip title="Edit">
                    <IconButton
                      ghost={false}
                      shape="circle"
                      icon={IconPencil}
                      disabled={!!(activeItem && (activeItem.id || activeItem.id === null))}
                      onClick={() => edit(record)}
                    />
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton
                      shape="circle"
                      icon={IconTrash}
                      disabled={!!(activeItem && (activeItem.id || activeItem.id === null))}
                      onClick={() => deleteItem(record.id)}
                    />
                  </Tooltip>
                </>
              )}
            </div>
          )
        },
      },
    ]

    return columns.map((column) => {
      if (!column.editable) {
        return column
      }

      return {
        ...column,
        onCell: (record) => {
          return {
            record,
            dataIndex: column.dataIndex,
            title: column.title,
            isInEditMode: record.key === activeItem?.key,
            cellContent: editableFields[column.key],
          }
        },
      }
    })
  }, [activeItem, editableFields, isItemEdited, cancel, edit, deleteItem])

  return (
    <div className="programme-items-management">
      <ContentWithAction
        actionButtonLabel="Add item"
        actionButtonProps={{
          disabled: activeItem && Object.keys(activeItem).length !== 0,
          onClick: async () => {
            const newItem: ProgrammeItem = {
              id: null,
              key: programmeItems.length,
              isNew: true,
            }
            form.setFieldsValue({
              name: '',
              category: '',
              unitType: '',
              quantity: '',
              ...newItem,
            })
            setActiveItem(newItem)
            setProgrammeItems([...programmeItems, newItem])
          }
        }}
      >
        <Form
          form={form}
          onFinish={save}
          initialValues={activeItem && Object.keys(activeItem).length ? activeItem : null}
        >
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            onRow={(_, index) => {
              if (index === programmeItems.length - 1 && activeItem?.isNew) {
                return {
                  ref: newItemRowRef,
                } as HTMLAttributes<HTMLDivElement>
              }
            }}
            bordered
            dataSource={programmeItems}
            columns={tableColumns}
            rowClassName="programme-items-management__editable-row"
            rowKey={record => record.id || record.key || programmeItems.length}
            pagination={false}
          // footer={() => (
          //   <CreateItemsForm
          //     isInEditMode={false}
          //     onAddItem={onAddItem}
          //     // onSaveItem={onSaveItem}
          //     // onCancel={onCancel}
          //   />
          // )}
          />
        </Form>
      </ContentWithAction>
    </div>
  )
}

export default ProgrammeItemsManagement
