import { axiosApi } from './api'

export const addProgrammeManager = async (payload) => {
  const { data } = await axiosApi()
    .post(
      '/users/managers',
      payload
    )
  return data
}

export const getProgrammeManagers = async (params = {}) => {
  const { data } = await axiosApi()
    .get(
      'users/managers',
      { params }
    )
  return data
}

export const getFieldworkersForProgrammeManager = async (
  programmeId,
  params
) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/manager/fieldworkers`,
      { params }
    )
  return data
}

export const getProgrammesForProgrammeManager = async (programmeId) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/manager/programmes`
    )
  return data
}
