import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Divider, message, Table, Typography } from 'antd'
import {
  IconFriends,
  IconMan,
  IconWoman,
  IconMoodKid,
  IconDisabled,
  IconHome,
  IconCoin,
} from '@tabler/icons-react'
import './styles.scss'
import HouseholdSizeChart from './household-size-chart'
import {
  getProgrammeHouseholdStatistics,
  getSoldItemsStatistics,
} from '../../../services/api/programme.api'
import SimpleCard from '../../molecules/dashboard/simple-card'
import colors from '../../../styles/_variables.module.scss'
import CardWide from '../../molecules/dashboard/card-wide'
import CardContent from '../../molecules/dashboard/card-content'
import SoldItemsChart from './sold-items-chart'
import { isAdmin } from '../../../services/helpers/user'
import { viewContexts } from '../../../constants/app.constants'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'
import { UserRoles } from '../../../constants/userRoles.constants'

const rankText = ['🥇', '🥈', '🥉']

const Dashboard = () => {
  const [householdStats, setHouseholdStats] = useState(null)
  const [soldItemsStats, setSoldItemsStats] = useState(null)
  const [topSellingItems, setTopSellingItems] = useState(null)

  const { activeProgramme, currentUserRoles } = useSelector<CombinedReducers, {
    activeProgramme: Programme,
    currentUserRoles: UserRoles[],
  }>(state => ({
    activeProgramme: state.programme.activeProgramme,
    currentUserRoles: state.user.userRoles,
  }))

  const navigate = useNavigate()
  const { viewContext } = useParams()

  const getHouseholdSizeChartData = useCallback(() => {
    if (!householdStats) {
      return []
    }

    return Object.entries(householdStats.householdSize).map(([size, value]) => ({ householdSize: size, value }))
  }, [householdStats])

  const getTopSellingItems = useCallback((items) => {
    return items
      .sort((a, b) => b.value - a.value)
      .slice(0, 3)
      .map(({ id, label, value }, index) => ({
        key: id,
        rank: rankText[index],
        label,
        value,
      }))
  }, [])

  useEffect(() => {
    if (!activeProgramme?.id) {
      if (viewContext === viewContexts.admin.key && isAdmin(currentUserRoles)) {
        navigate(`/${viewContexts.admin.key}/programmes`, { replace: true })
      }

      return
    }

    getProgrammeHouseholdStatistics(activeProgramme.id).then((res) => {
      if (res.hasErrors) {
        message.error('Could not get programme statistics')
        return
      }
      delete res.hasErrors
      setHouseholdStats(res)
    })

    getSoldItemsStatistics(activeProgramme.id).then((res) => {
      if (res.hasErrors) {
        message.error('Could not get sold items statistics')
      }
      delete res.hasErrors

      const items = res.items.map(
        ({ id, name, totalAmount, price, unitType }) => ({
          id,
          label: name,
          value: totalAmount,
          price,
          unitType,
        })
      )

      setSoldItemsStats({
        ...res,
        items,
      })

      setTopSellingItems(getTopSellingItems(items))
    })
  }, [activeProgramme, currentUserRoles, getTopSellingItems, navigate, viewContext])

  return (
    <div className="dashboard-content">
      {activeProgramme && (
        <>
          <Typography.Title level={2}>Dashboard</Typography.Title>

          <div className="cards__grid">
            <CardWide>
              <CardContent
                title="Total Enrolled"
                icon={<IconFriends color={colors.accent} />}
                className="total-enrolled"
              >
                {householdStats?.totalEnrolled}
              </CardContent>

              <Divider type="vertical" />

              <CardContent
                title="Household size"
                icon={<IconHome color={colors.accent} />}
                className="household-size-chart"
              >
                <HouseholdSizeChart data={getHouseholdSizeChartData()} />
              </CardContent>
            </CardWide>
            <div className="stacked-cards">
              <SimpleCard
                title="Male"
                icon={<IconMan color={colors.accent} />}
              >
                {householdStats?.male}
              </SimpleCard>
              <SimpleCard
                title="Female"
                icon={<IconWoman color={colors.accent} />}
              >
                {householdStats?.female}
              </SimpleCard>
              <SimpleCard
                title="Children under 5"
                icon={<IconMoodKid color={colors.accent} />}
              >
                {householdStats?.childrenUnderFive}
              </SimpleCard>
              <SimpleCard
                title="Disabled persons"
                icon={<IconDisabled color={colors.accent} />}
              >
                {householdStats?.disabledPerson}
              </SimpleCard>
            </div>
            <SimpleCard
              title="Sold items"
              icon={<IconCoin color={colors.accent} />}
              className="sold-items"
            >
              <div className="sold-items-chart__wrapper">
                <SoldItemsChart
                  currency={activeProgramme.currency}
                  data={soldItemsStats}
                />
              </div>

              <Typography.Title level={5}>Top sellers</Typography.Title>
              <Table
                dataSource={topSellingItems}
                pagination={false}
                columns={[
                  {
                    title: 'Rank',
                    dataIndex: 'rank',
                    key: 'rank',
                    width: '10%',
                    align: 'center',
                    className: 'sold-items-table__column-rank',
                  },
                  {
                    title: 'Item',
                    dataIndex: 'label',
                    key: 'label',
                  },
                  {
                    title: 'Amount',
                    dataIndex: 'value',
                    key: 'value',
                  },
                ]}
              />
            </SimpleCard>
          </div>
        </>
      )}
    </div>
  )
}

export default Dashboard
