import classNames from 'classnames'
import './styles.scss'

const withIconWrapper = (Component) => {
  const CustomIcon = () => (
    <div className={classNames('icon', 'icon__wrapper')}>
      <Component />
    </div>
  )
  return CustomIcon
}

export default withIconWrapper
