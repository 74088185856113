import { FC } from 'react'
import './styles.scss'
import logo from './images/l20-logo.png'

const Logo: FC = () => {
  return (
    <div className={'logo'}>
      <img
        src={logo}
        alt="logo"
      />
    </div>
  )
}

export default Logo
