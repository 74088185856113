import { useState, useEffect, useCallback, useMemo } from 'react'
import { Select, Typography } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IconWifiOff } from '@tabler/icons-react'
import Icon from '../../../atoms/icon'
import { setActiveProgramme } from '../../../../redux/stores/programme/programme.actions'
import { viewContexts, ViewModes } from '../../../../constants/app.constants'
import { groupBy } from '../../../../services/helpers/helpers'
import { isAdmin } from '../../../../services/helpers/user'
import { CombinedReducers } from '../../../../redux/stores/reducers'
import { UserRoles } from '../../../../constants/userRoles.constants'
import { Programme } from '../../../../types/programme'
import { ProgrammeStatuses } from '../../../../constants/programme.constants'

const { OptGroup, Option } = Select

const ProgrammeSelector = () => {
  const { viewContext } = useParams()
  const [activeOption, setActiveOption] = useState(viewContext)
  const { programmes, isInitialRequestDone, currentUserRoles } = useSelector<CombinedReducers, {
    programmes: Programme[];
    isInitialRequestDone: boolean;
    currentUserRoles: UserRoles[];
} >(state => ({
  programmes: state.programme.programmes,
  isInitialRequestDone: state.user.isInitialRequestDone,
  currentUserRoles: state.user.userRoles,
}))

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { programmesGroupedByStatus, programmesGroupedById } = useMemo(() => {
    const groupedProgrammes = groupBy<Programme, keyof ProgrammeStatuses>(programmes, 'status')
    const mapParams: Iterable<readonly [keyof ProgrammeStatuses, Programme[]]>
      = Object.keys(groupedProgrammes)
        .sort()
        .map((status: keyof ProgrammeStatuses) => [status, groupedProgrammes[status]])

    const programmesGroupedByStatus = new Map<keyof ProgrammeStatuses, Programme[]>(mapParams)

    const programmesGroupedById = groupBy(programmes, 'id')
    // Add the "L20 Admin" option for admins
    if (isInitialRequestDone && isAdmin(currentUserRoles)) {
      programmesGroupedById[viewContexts.admin.key] = [{ name: viewContexts.admin.label }]
    }

    return {
      programmesGroupedByStatus,
      programmesGroupedById
    }
  }, [currentUserRoles, isInitialRequestDone, programmes])

  useEffect(() => setActiveOption(viewContext), [viewContext])

  const onSelectionChange = useCallback(
    async (programmeId) => {
      if (programmeId === ViewModes.admin) {
        dispatch(setActiveProgramme(null))
        navigate(`/${viewContexts.admin.key}`)
        return
      }

      dispatch(
        setActiveProgramme(
          programmes.find(programme => programme.id === programmeId)
        )
      )

      navigate(`/${programmeId}`)
    },
    [dispatch, navigate, programmes]
  )

  return (
    (!!programmesGroupedByStatus.size) && (
      <div className="programme-selector-wrapper">
        <Select
          showSearch
          placeholder="Select a programme"
          optionFilterProp="children"
          size="large"
          filterOption={(input, option) =>
            option.options
              ? false
              : programmesGroupedById[option.value][0].name.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
          onSelect={onSelectionChange}
          value={activeOption}
          popupClassName="programme-selector-group"
        >
          {isAdmin(currentUserRoles) && (
            <Option
              value={viewContexts.admin.key}
              key={viewContexts.admin.key}
            >
              {viewContexts.admin.label}
            </Option>
          )}

          {[...programmesGroupedByStatus.keys()].map((status) => {
            return (
              <OptGroup
                label={status}
                key={status}
              >
                {programmesGroupedByStatus.get(status).map(programme => (
                  <Option
                    value={programme.id}
                    key={programme.id}
                  >
                    <div className="programme-option__wrapper">
                      <Typography.Text
                        ellipsis
                        title={programme.name}
                      >
                        {programme.name}
                      </Typography.Text>
                      {!programme.isOnline && (
                        <Icon
                          component={IconWifiOff}
                          title="Offline programme"
                        />
                      )}
                    </div>
                  </Option>
                ))}
              </OptGroup>
            )
          })}
        </Select>
      </div>
    )
  )
}

export default ProgrammeSelector
