import { serverDateToString } from '../../../services/helpers/date'
import { programmeStatuses } from '../../../constants/programme.constants'
import StatusPill from '../../atoms/status-pill'
import { statusToTextValue } from '../../../services/helpers/constants'
import { ColumnsType } from 'antd/es/table'

export const mappings = {
  id: 'id',
  programmeName: 'name',
  programmeManager: 'Manager',
  startDate: 'startDate',
  endDate: 'endDate',
  status: 'status',
}

export const columns: ColumnsType = [
  {
    title: 'Programme name',
    dataIndex: 'programmeName',
    key: 'programmeName',
    sorter: true,
    width: '30%',
    ellipsis: true
  },
  {
    title: 'Programme manager',
    dataIndex: 'programmeManager',
    key: 'programmeManager',
    sorter: true,
    ellipsis: true
  },
  {
    title: 'Start date',
    dataIndex: 'startDate',
    key: 'startDate',
    sorter: true,
  },
  {
    title: 'End date',
    dataIndex: 'endDate',
    key: 'endDate',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: statusToTextValue(programmeStatuses),
    filterMultiple: false,
  },
]

export const parseTableProgrammeData = (data) => {
  const status = data[mappings.status]
  return {
    id: data[mappings.id],
    programmeName: data[mappings.programmeName],
    programmeManager: data[mappings.programmeManager]?.email,
    startDate: serverDateToString(data[mappings.startDate]),
    endDate: serverDateToString(data[mappings.endDate]),
    status: <StatusPill statusData={programmeStatuses[status]} />,
  }
}
