import { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import Message from '../../atoms/message'
import { messageTypes } from '../../atoms/message/messageTypes'
import { login } from '../../../services/api/auth.api'
import {
  setIsInitialRequestDone,
} from '../../../redux/stores/user/user.actions'
import OtpStep from './otpStep'
import LoginStep from './loginStep'
import LockedAccount from './lockedAccount'
import { storeUserData } from '../../../services/initializers/user.initializer'
import { initializeProgrammes } from '../../../services/initializers/programmes.initializer'

const otpErrorMessage = 'Your two-factor authentication code is incorrect.'

const Login = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [message, setMessage] = useState(null)
  const [showOtpInput, setShowOtpInput] = useState(false)
  const [isAccountLocked, setIsAccountLocked] = useState(false)

  const setAuthenticatedUser = useCallback(
    async (userId: string) => {
      await storeUserData(userId)
      dispatch(setIsInitialRequestDone(true))
      setMessage(null)
    },
    [dispatch]
  )

  const logIn = useCallback(
    async (e) => {
      e.preventDefault()
      if (!otp && showOtpInput) {
        setMessage({
          type: messageTypes.error,
          content: otpErrorMessage,
        })
        return
      }

      try {
        const res = await login({ email, password, otp })

        if (res.status === 401) {
          if (res.data.isLocked) {
            setIsAccountLocked(true)
            return
          }
          if (showOtpInput) {
            setMessage({
              type: messageTypes.error,
              content: otpErrorMessage,
            })
            return
          }

          setMessage({
            type: messageTypes.error,
            content: (
              <>
                Your email or password is incorrect.
                <br />
                Please try again.
              </>
            ),
          })
          return
        }

        if (res.status === 202) {
          setShowOtpInput(true)
          return
        }

        await setAuthenticatedUser(res.data.userId)
        await initializeProgrammes()
      } catch (err) {
        setMessage({
          type: messageTypes.error,
          content: 'There was an error processing your request',
        })
      } finally {
        dispatch(setIsInitialRequestDone(true))
      }
    },
    [otp, showOtpInput, email, password, setAuthenticatedUser, dispatch]
  )

  const resendOtp = useCallback(
    async (e) => {
      e.preventDefault()
      setOtp('')
      try {
        const res = await login({ email, password })
        if (res.status === 202) {
          setMessage({
            type: messageTypes.success,
            content: 'We have sent you an email with a verification code.',
          })
        }
      } catch (err) {
        setMessage({
          type: messageTypes.error,
          content: 'There was an error processing your request',
        })
      }
    },
    [email, password, setOtp]
  )

  useEffect(() => {
    return () => {
      setMessage(null)
      setShowOtpInput(false)
    }
  }, [])

  return isAccountLocked ? (
    <LockedAccount />
  ) : (
    <form className="login-form">
      {message && <Message type={message.type}>{message.content}</Message>}

      {showOtpInput ? (
        <OtpStep
          onOtpChange={setOtp}
          onSubmit={logIn}
          resendOtp={resendOtp}
        />
      ) : (
        <LoginStep
          email={email}
          password={password}
          errorMessage={message?.content}
          onEmailChange={setEmail}
          onPasswordChange={setPassword}
          onSubmit={(e) => {
            logIn(e)
            setMessage(null)
          }}
        />
      )}
    </form>
  )
}

export default Login
