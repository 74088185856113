import { getSortDirectionValue } from '../../hooks/query.hook'

export const handleSorting = (onSort, actionType, sorter, defaultSorter) => {
  if (actionType === 'sort') {
    if (sorter.column?.key || sorter.order) {
      onSort(sorter.column?.key, sorter.order)
    } else {
      onSort(defaultSorter.order, getSortDirectionValue(defaultSorter.orderType))
    }
  }
}

export const getNumberOfPages = (totalItems: number, pageSize = 10) => Math.ceil(totalItems / pageSize)
