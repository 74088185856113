import './styles.scss';
import CookieConsent, { Cookies } from 'react-cookie-consent';

const CookieDisclaimer = () => (
  <CookieConsent
    buttonText="Accept all cookies"
    enableDeclineButton
    declineButtonText="Accept functional cookies only"
    onAccept={() => window.clarity('consent')}
    onDecline={() => {
      Cookies.remove('_clck')
      Cookies.remove('_clsk')
    }}
    disableStyles
    containerClasses="rcc-container"
    contentClasses="rcc-content"
    buttonWrapperClasses="rcc-buttons"
  >
    This website uses functional and analytics cookies to enhance the user experience.
  </CookieConsent>
)

export default CookieDisclaimer
