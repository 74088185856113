import { FC } from 'react'
import { Descriptions, Switch } from 'antd'
import InfoButton from '../../molecules/info-button'
import { programmeStatuses } from '../../../constants/programme.constants'
import { SwitchChangeEventHandler } from 'antd/es/switch'

const GpsDataCapturingControl: FC<{
  disabled: boolean,
  onEnrollment: boolean,
  onRedemption: boolean,
  onToggleEnrollmentGpsCapture: SwitchChangeEventHandler
  onToggleRedemptionGpsCapture: SwitchChangeEventHandler
}> = ({
  disabled,
  onEnrollment,
  onRedemption,
  onToggleEnrollmentGpsCapture,
  onToggleRedemptionGpsCapture
}) => {
  const disabledInfoText = `This feature is not available for ${programmeStatuses.draft.label} programmes`

  return (
    <Descriptions
      column={1}
      size="small"
      title="GPS coordinates capturing"
      className="gps-capturing-settings "
      key="gps-capturing-settings"
    >
      <Descriptions.Item label="On enrolment">
        <Switch
          disabled={disabled}
          checked={onEnrollment}
          onChange={onToggleEnrollmentGpsCapture}
        />
        {disabled && (<InfoButton infoText={disabledInfoText}/>)}
      </Descriptions.Item>
      <Descriptions.Item label="On redemption">
        <Switch
          disabled={disabled}
          checked={onRedemption}
          onChange={onToggleRedemptionGpsCapture}
        />
        {disabled && (<InfoButton infoText={disabledInfoText}/>)}
      </Descriptions.Item>
    </Descriptions>
  )
}

export default GpsDataCapturingControl
