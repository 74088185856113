import { FC, useCallback, useMemo } from 'react'
import { Cascader } from 'antd'
import ct from 'countries-and-timezones';
import { getFormattedTimezone } from '../../../services/helpers/internationalization';
import { SingleCascaderProps } from 'rc-cascader';

const SelectCountryAndTimezone: FC<{
  defaultValue?: [string, string]
  onChange?: SingleCascaderProps['onChange']
  showSearch?: boolean
}> = ({ defaultValue, onChange, showSearch }) => {
  const options = useMemo(() =>
    Object.values(ct.getAllCountries()).map((country) => {
      return {
        value: country.id,
        label: country.name,
        children: country.timezones.map((timezone) => {
          return {
            value: timezone,
            label: getFormattedTimezone(timezone)
          };
        })
      };
    }),
  [])

  const filter = useCallback((inputValue, path) =>
    path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1),
  []);

  return (
    <Cascader
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      showSearch={showSearch || {
        filter
      }}
      placeholder="Please select"
    />
  )
}

export default SelectCountryAndTimezone
