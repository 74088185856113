import { combineReducers } from 'redux'
import application, { AppReducerState } from './application/app.reducer'
import programme, { ProgrammeReducerState } from './programme/programme.reducer'
import user, { UserReducerState } from './user/user.reducer'

export type CombinedReducers = {
  application: AppReducerState;
  programme: ProgrammeReducerState;
  user: UserReducerState;
}

export default combineReducers<CombinedReducers>({
  application,
  programme,
  user,
})
