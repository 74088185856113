import voucherStatusColors from '../styles/statusColors/_voucher.module.scss'
import { Status } from '../types/generic'

export type VoucherStatuses = {
  available: Status,
  assigned: Status,
  disabled: Status,
  redeemed: Status,
  invalid: Status,
}
export const voucherStatuses = {
  available: {
    key: 'available',
    label: 'available',
    color: voucherStatusColors.available,
    description: 'Certificates that can be enrolled',
  },
  assigned: {
    key: 'assigned',
    label: 'enrolled',
    color: voucherStatusColors.assigned,
    description: 'Certificates that have been assigned to a beneficiary',
  },
  disabled: {
    key: 'disabled',
    label: 'disabled',
    color: voucherStatusColors.disabled,
    description: 'Certificates that can no longer be used',
  },
  redeemed: {
    key: 'redeemed',
    label: 'redeemed',
    color: voucherStatusColors.redeemed,
    description: 'Certificates that have been used to obtain goods and services',
  },
  invalid: {
    key: 'invalid',
    label: 'invalid',
    color: voucherStatusColors.invalid,
    description: "Certificates that for some reason are not valid and can't be used",
  },
}
