import { Typography } from 'antd'
import { useSelector } from 'react-redux'
import logo from './images/no-programme-icon.svg'
import './styles.scss'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { BaseUser } from '../../../types/user'

const { Paragraph, Text, Link } = Typography

const NoAssignedProgrammes = () => {
  const userDetails = useSelector<CombinedReducers, BaseUser>(state => state.user.userDetails)

  return (
    <div className="no-assigned-programmes__wrapper">
      <div className="no-assigned-programmes__content">
        <img
          src={logo}
          alt="logo"
        />
        <Paragraph>
          Hello <Text strong>{userDetails?.firstName}</Text>, you are currently not assigned to any programme.
        </Paragraph>
        <Paragraph>
          If you have any questions, please contact{' '}
          <Link
            href="mailto:support@f4id.org"
            title="support@f4id.org"
            strong
          >
            support@f4id.org
          </Link>
        </Paragraph>
      </div>
    </div>
  )
}

export default NoAssignedProgrammes
