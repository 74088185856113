import { FC } from 'react'
import classNames from 'classnames'
import { Tag } from 'antd'
import './styles.scss'
import { Status } from '../../../types/generic'

const StatusPill: FC<{ statusData: Status }> = ({ statusData }) => {
  if (!statusData) {
    return
  }

  const { key, label, color } = statusData

  return (
    <Tag
      className={classNames('status-pill', { [`status-pill__${key}`]: !!key })}
      color={color}
    >
      {label}
    </Tag>
  )
}

export default StatusPill
