export const currencies = [
  {
    value: 'KES',
    label: 'KES',
  },
  {
    value: 'NGN',
    label: 'NGN',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'MMK',
    label: 'MMK',
  },
  {
    value: 'HTG',
    label: 'HTG',
  },
  {
    value: 'LBP',
    label: 'LBP',
  },
  {
    value: 'Unit',
    label: 'Unit',
  },
]

export const apiRequestDateFormat = 'YYYY-MM-DD'
export const rangePickerDateFormat = 'L'
