import { useState, useEffect, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import Table from '../../atoms/table'
import { columns, parseTableUsersData, mappings } from './users-table-config'
import { SortDirections, useQuery } from '../../../hooks/query.hook'
import { getUsers } from '../../../services/api/user.api'
import { getNumberOfPages } from '../../../services/helpers/table'

const defaultQuery = {
  order: 'createdAt',
  orderType: SortDirections.descend,
  pageSize: 10,
  page: 1,
}

const UsersTable: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')
  const [numberOfPages, setNumberOfPages] = useState(1)

  const navigate = useNavigate()
  const {
    query,
    onSort,
    onSearch,
    onFilter,
    onFilters,
    onPageChange,
  } = useQuery(defaultQuery)


  useEffect(() => {
    if (searchQuery !== search) {
      setSearch(searchQuery)
      onFilter(mappings['search'], searchQuery)
    }
  }, [searchQuery, search, onSearch, onFilter])

  useEffect(() => {
    getUsers(query).then((res) => {
      setNumberOfPages(getNumberOfPages(res.count, query.pageSize))
      if (!res.rows) {
        return
      }
      setUsers(res.rows.map(parseTableUsersData))
    })
  }, [query])

  const onRowClick = (rowData) => {
    navigate(`${rowData.id}`, { state: [query] })
  }

  return (
    <div className="users-table">
      <Table
        data={users}
        columns={columns}
        onSort={(name, sortOrder) => onSort(mappings[name], sortOrder)}
        onFilters={onFilters}
        onRowClick={onRowClick}
        pagination={{
          numberOfPages,
          onPageChange,
          page: query.page,
        }}
      />
    </div>
  )
}

export default UsersTable
