import axios, { AxiosError, AxiosResponse } from 'axios'
import { logout, refreshAuthToken } from './auth.api'

export type ApiResponseData<T> = T & {
  message?: string
  hasErrors?: boolean
}
export type ApiResponse<T = unknown> = {
  data: ApiResponseData<T>
  status?: number
  error?: AxiosError
}

const API_BASE_URL = `${import.meta.env.VITE_API_URL ?? ''}/api`

const onFulfilled:(response: AxiosResponse) => AxiosResponse & ApiResponse<unknown> = response => response

const onRejected:(error: AxiosError) => Promise<AxiosResponse | ApiResponse > = async (error: AxiosError) => {
  if (error.response?.status === 401 && error.request) {
    if (error.response.config.url.indexOf('/auth') === -1) {
      return refreshAuthToken()
        .then(() => axios.request(error.config))
        .catch((err: AxiosError) => {
          const isTokenPresentInQueyString = (/[?&]token=/u).test(window.location.search)
          if (err.response.status === 401 && !isTokenPresentInQueyString) {
            logout()
          }
          return {
            error: err,
            data: {
              ...err.response.data as object,
              hasErrors: true,
            },
            status: err.response.status,
          }
        })
    } else {
      return {
        error,
        data: {
          ...error.response?.data as object,
          hasErrors: true,
        },
        status: error.response?.status,
      }
    }
  } else {
    return {
      error,
      data: {
        ...error.response?.data as object,
        hasErrors: true,
      },
      status: error.response?.status,
    }
  }
}

export const axiosApi = () => {
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
  })
  axiosInstance.interceptors.response.use(onFulfilled, onRejected)
  axiosInstance.defaults.withCredentials = true

  return axiosInstance
}
