import { IconInfoCircle } from '@tabler/icons-react'
import { Tooltip } from 'antd'
import Icon from '../../atoms/icon'
import { FC } from 'react'

const InfoButton: FC<{ infoText: string }> = ({ infoText }) => (
  <Tooltip title={infoText}>
    <Icon component={IconInfoCircle} />
  </Tooltip>
)

export default InfoButton
