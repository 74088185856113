import { FC, MouseEventHandler, useRef } from 'react'
import AuthCode from 'react-auth-code-input'
import { Button, Tooltip } from 'antd'
import { IconBackspace } from '@tabler/icons-react'
import IconButton from '../../atoms/icon-button'

const OtpStep: FC<{
  onOtpChange: (res: string) => void,
  onSubmit: MouseEventHandler,
  resendOtp: MouseEventHandler,
}> = ({ onOtpChange, onSubmit, resendOtp }) => {
  const OtpInputRef = useRef(null)

  return (
    <>
      <div className="otp__form-elements">
        <h2>Two-factor authentication</h2>
        <p>
          Type the six-digit code that you have received on your email address
        </p>
        <p>The code expires in five miutes</p>

        <span className="otp__input-wrapper">
          <AuthCode
            length={6}
            onChange={(res) => {
              onOtpChange(res)
            }}
            containerClassName="otp__container"
            inputClassName="otp__input"
            ref={OtpInputRef}
          />
          <Tooltip title="Clear">
            <IconButton
              shape="circle"
              icon={IconBackspace}
              type="ghost"
              onClick={(e) => {
                e.preventDefault()
                return OtpInputRef.current?.clear()
              }}
            />
          </Tooltip>
        </span>

        <Button
          className="submit_button"
          onClick={onSubmit}
          htmlType="submit"
          type="primary"
          size="large"
        >
          Verify
        </Button>
      </div>

      <Button
        type="link"
        onClick={resendOtp}
      >
        Resend code
      </Button>
    </>
  )
}

export default OtpStep
