import userStatusColors from '../styles/statusColors/_user.module.scss'
import { Status } from '../types/generic'

export type UserStatus = {
  active: Status,
  disabled: Status,
}

export const userStatuses = {
  active: {
    key: 'active',
    label: 'active',
    color: userStatusColors.active,
  },
  disabled: {
    key: 'disabled',
    label: 'disabled',
    color: userStatusColors.disabled,
  },
}
