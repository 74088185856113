import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Input, message, Table } from 'antd'
import {
  getMerchantEmployees as getMerchantEmployeesApiRequest,
  updateMerchantEmployeesStatus,
} from '../../../services/api/merchant.api'
import { merchantStatuses } from '../../../constants/merchant.constants'
import { SortDirections, useQuery } from '../../../hooks/query.hook'
import StatusPill from '../../atoms/status-pill'
import { getPaginationTotalText } from '../../../services/helpers/helpers'
import { useParams } from 'react-router-dom'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'
import { ColumnsType } from 'antd/es/table'
import { MerchantEmployee } from '../../../types/user'
import { Status } from '../../../types/generic'

const { Search } = Input

const MerchantEmployees = () => {
  const [merchantEmployeesCount, setMerchantEmployeesCount] = useState(0)
  const [merchantEmployees, setMerchantEmployees] = useState([])
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)
  const { merchantId } = useParams()

  const updateEmployee = (result) => {
    setMerchantEmployees(employees =>
      employees.map((it) => {
        return it.email === result.email ? result : it
      })
    )
  }

  const { query, onPageChange, onSearch } = useQuery({
    orderType: SortDirections.descend,
    pageSize: 10,
    page: 1,
  })
  const getStatus: (user: MerchantEmployee) => Status = ({ isPending, isActive }) => {
    return (
      (isPending && merchantStatuses.invited)
      || (!isActive && merchantStatuses.disabled)
      || merchantStatuses.active
    )
  }

  const tableColumns: ColumnsType<MerchantEmployee> = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, user) => {
        return <StatusPill statusData={getStatus(user)} />
      },
    },
    {
      title: 'Action',
      dataIndex: 'isPending',
      key: 'status',
      render: (_, { id, isActive, isPending }) => {
        if (isPending) {
          return '-'
        }

        if (isActive) {
          return (
            <Button
              type="link"
              danger
              onClick={() => {
                updateMerchantEmployeesStatus(
                  activeProgramme.id,
                  merchantId,
                  id,
                  false
                ).then(updateEmployee)
              }}
            >
              Disable
            </Button>
          )
        }
        if (!isActive) {
          return (
            <Button
              type="link"
              onClick={() => {
                updateMerchantEmployeesStatus(
                  activeProgramme.id,
                  merchantId,
                  id,
                  true
                ).then(updateEmployee)
              }}
            >
              Enable
            </Button>
          )
        }
      },
    },
  ]

  const getMerchantEmployees = useCallback(async () => {
    if (!activeProgramme?.id) {
      return
    }

    const res = await getMerchantEmployeesApiRequest(
      activeProgramme.id,
      merchantId,
    )
    if (res.hasErrors) {
      message.error('Could not get the supplier invites')
      return
    }
    setMerchantEmployees(res.rows)
    setMerchantEmployeesCount(res.count)
  }, [activeProgramme, merchantId])

  useEffect(() => {
    getMerchantEmployees()
  }, [getMerchantEmployees])

  return (
    <div className="merchant-invites-list">
      <div className="merchant-actions">
        <div className="search-wrapper">
          <Search
            allowClear
            placeholder="Search"
            onSearch={value => onSearch(value, 'email')}
          />
        </div>
      </div>
      <Table
        dataSource={merchantEmployees}
        columns={tableColumns}
        bordered
        rowKey="id"
        pagination={{
          onChange: onPageChange,
          total: merchantEmployeesCount,
          pageSize: query.pageSize,
          showTotal: getPaginationTotalText,
          current: query.page,
        }}
      />
    </div>
  )
}

export default MerchantEmployees
