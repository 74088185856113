import { createContext, FC, MouseEventHandler, ReactNode, useCallback, useContext, useState } from 'react'
import { IconPencil } from '@tabler/icons-react'
import { Button, Typography } from 'antd'
import './styles.scss'
import Icon from '../../atoms/icon'
import ConfirmationButtons from '../confirmation-buttons'
import classNames from 'classnames'

export const EditableFieldContext = createContext<{ fieldValue, setFieldValue }>(null)

const ReadOnlyView: FC<{
  isEditable: boolean
  onClick: MouseEventHandler
  title: string
  children: ReactNode
}> = ({ isEditable, onClick, title, children }) => (
  <div className="editable-field__readonly">
    {isEditable
      ? (
        <Button
          type="text"
          onClick={onClick}
          title={title || children as string}
        >
          <Typography.Text ellipsis>
            {children}
          </Typography.Text>
          <Icon
            component={IconPencil}
            componentProps={{
              stroke: 1.5,
              size: '1.2rem',
            }}
          />
        </Button>
      )
      : children}
  </div>
)

const EditableField: FC<{
  readOnlyContent: ReactNode
  readOnlyTitle?: ReactNode
  isSaveDisabled: boolean
  initialFieldValue?: unknown
  className?: string
  onSave: (fieldValue: unknown) => Promise<void>
  isEditable: boolean
  children: ReactNode
}> = ({
  readOnlyContent,
  readOnlyTitle,
  isSaveDisabled,
  initialFieldValue = null,
  className,
  onSave,
  isEditable,
  children
}) => {
  const [isInEditMode, setIsInEditMode] = useState(false)
  const { fieldValue, setFieldValue } = useContext(EditableFieldContext)

  const onFinishEditing = useCallback(() => {
    setIsInEditMode(false)
    setFieldValue(initialFieldValue)
  }, [initialFieldValue, setFieldValue])

  return (
    <div className={classNames('editable-field__container', className)}>
      {isInEditMode ? (
        <>
          <div className="editable-field">
            {children}
          </div>

          <ConfirmationButtons
            isSaveDisabled={isSaveDisabled}
            cancelConfirmationMessage="Cancel editing?"
            onSave={async () => {
              await onSave(fieldValue)
              onFinishEditing()
            }}
            onCancel={onFinishEditing}
            buttonSize="middle"
          />
        </>
      )
        : (
          <ReadOnlyView
            isEditable={isEditable}
            onClick={() => setIsInEditMode(true)}
            title={readOnlyTitle as string}
          >
            {readOnlyContent}
          </ReadOnlyView>
        )
      }
    </div>
  )
}

export default EditableField
