import { useState, useEffect, useCallback, useMemo, FC } from 'react'
import { Button, List, Typography, message, Modal, Descriptions } from 'antd'
import { IconLockOpen } from '@tabler/icons-react'
import IconButton from '../../atoms/icon-button'
import './styles.scss'
import { useSelector } from 'react-redux'
import InfoBox from '../../atoms/info-box'
import {
  disableUser,
  enableUser,
  unlockUser,
} from '../../../services/api/user.api'
import { serverDateToString } from '../../../services/helpers/date'
import { UserRoles } from '../../../constants/userRoles.constants'
import { programmeStatuses } from '../../../constants/programme.constants'
import { userStatuses } from '../../../constants/user.constants'
import StatusPill from '../../atoms/status-pill'
import { isAdmin } from '../../../services/helpers/user'
import EllipsisMiddle from '../../atoms/ellipsis-middle'
import { Link } from 'react-router-dom'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { BaseUser, UserRaw } from '../../../types/user'
import { getFullName } from '../../../services/helpers/helpers'

const { Paragraph, Text } = Typography

const UserDetails: FC<{ user: UserRaw }> = ({ user }) => {
  const [roles, setRoles] = useState<string[]>([])
  const [programmes, setProgrammes] = useState<UserRaw['programmes']>([])
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [status, setStatus] = useState('')
  const [isLocked, setIsLocked] = useState(false)
  const [createdAt, setCreatedAt] = useState('')
  const [createdBy, setCreatedBy] = useState('')
  const [lastEditOn, setLastEditOn] = useState('')
  const [lastEditBy, setLastEditBy] = useState('')
  const { userDetails, currentUserRoles, } = useSelector<CombinedReducers, {
    userDetails: BaseUser,
    currentUserRoles: UserRoles[],
  }>(
    state => ({
      userDetails: state.user.userDetails,
      currentUserRoles: state.user.userRoles,
    })
  )

  const isCurrentUser = useMemo(
    () => userDetails?.id === user?.id,
    [user, userDetails.id]
  )

  useEffect(() => {
    if (user) {
      const {
        roles,
        programmes,
        firstName,
        lastName,
        email,
        phone,
        status,
        createdAt,
        CreatedBy,
        updatedAt,
        UpdatedBy,
        isLocked,
      } = user
      setRoles(roles)
      setProgrammes(programmes)
      setFirstName(firstName)
      setLastName(lastName)
      setEmail(email)
      setPhone(phone)
      setStatus(status)
      setIsLocked(isLocked)
      setCreatedAt(serverDateToString(createdAt))
      setCreatedBy(getFullName(CreatedBy))
      setLastEditOn(serverDateToString(updatedAt))
      setLastEditBy(getFullName(UpdatedBy))
    }
  }, [user])

  const activeProgrammes = useMemo(
    () =>
      programmes.reduce((acc, programme) => {
        if (programme.status === programmeStatuses.active.key) {
          acc.push({
            id: programme.id,
            name: programme.name
          })
        }
        return acc
      }, []),
    [programmes]
  )

  const setDisabledStatus = useCallback(
    async (status) => {
      const res = await disableUser(user.id, { status })
      if (res.hasErrors) {
        return
      }
      setStatus(res.status)
    },
    [user]
  )

  const onDisableManager = useCallback(async () => {
    if (Array.isArray(programmes) && programmes.length) {
      Modal.confirm({
        title: 'Warning',
        onOk: () => setDisabledStatus(userStatuses.disabled.key),
        okText: 'Yes',
        cancelText: 'No',
        content: (
          <>
            <Paragraph>
              This user is currently assigned to at least one programme
            </Paragraph>
            <Paragraph>
              Disabling this user will also archive all the programme(s) to which he/she is assigned to.
            </Paragraph>
            <Paragraph>
              Before proceeding, please check this user&apos;s programmes and reassign to a different user the ones you don&apos;t want to archive.
            </Paragraph>
            <Paragraph strong>Are you sure you want to disable this programme manager account?</Paragraph>
          </>
        ),
      })
    } else {
      await setDisabledStatus(userStatuses.disabled.key)
    }
  },
  [programmes, setDisabledStatus]
  )

  const onDisableAdmin = useCallback(() => {
    Modal.confirm({
      title: 'Warning',
      onOk: () => setDisabledStatus(userStatuses.disabled.key),
      okText: 'Yes',
      cancelText: 'No',
      content: (
        <>
          <Paragraph>By disabling this admin, you take away their rights to manage and edit programmes.</Paragraph>
          <Paragraph strong>Are you sure you want to proceed?</Paragraph>
        </>
      ),
    })
  },
  [setDisabledStatus]
  )

  const onUnlockUser = useCallback(async () => {
    const res = await unlockUser(user.id)
    if (res.hasErrors) {
      message.error('Could not unlock the user account')
      return
    }
    setIsLocked(res.isLocked)
    message.success('The user account has been unlocked.')
  }, [user])

  const onEnableUser = useCallback(
    async (e) => {
      e.preventDefault()
      const res = await enableUser(user.id)
      if (res.hasErrors) {
        return
      }
      setStatus(res.status)
    },
    [user]
  )

  const infoBoxActions = useMemo(() => {
    const actions = []

    if (isAdmin(currentUserRoles) && isLocked) {
      actions.push(
        <IconButton
          type="primary"
          onClick={onUnlockUser}
          key="Unlock User"
          icon={IconLockOpen}
        >
          Unlock User
        </IconButton>
      )
    }

    if (
      roles.includes(UserRoles.programmeManager)
      && status === userStatuses.active.key
    ) {
      actions.push(
        <Button
          type="text"
          onClick={onDisableManager}
          danger
          key="Disable User Manager"
        >
          Disable User
        </Button>
      )
    }

    if (
      !isCurrentUser
      && roles.includes(UserRoles.admin)
      && status === userStatuses.active.key
    ) {
      actions.push(
        <Button
          type="text"
          onClick={onDisableAdmin}
          danger
          key="Disable User Admin"
        >
          Disable User
        </Button>
      )
    }

    if (!isCurrentUser && status === userStatuses.disabled.key) {
      actions.push(
        <Button
          type="link"
          onClick={onEnableUser}
          key="Enable User"
        >
          Enable User
        </Button>
      )
    }

    return actions
  }, [
    onDisableAdmin,
    onDisableManager,
    onEnableUser,
    isLocked,
    roles,
    status,
    onUnlockUser,
    isCurrentUser,
    currentUserRoles,
  ])

  return (
    <div className="user-details__content">
      <div className="first-column">
        <Descriptions
          column={1}
          layout="vertical"
          colon={null}
        >
          <Descriptions.Item label="Role">
            {(roles && roles?.join(', ')) || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="First Name">
            {firstName || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Last Name">
            {lastName || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Email Address">
            {email || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {phone || '–'}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="second-column">
        <InfoBox
          actions={infoBoxActions}
          details={[
            {
              label: 'Status',
              value: <StatusPill statusData={userStatuses[status]} />,
            },
            {
              label: 'Created on',
              value: createdAt,
            },
            {
              label: 'Created by',
              value: createdBy,
            },
            {
              label: 'Last edit on',
              value: lastEditOn,
            },
            {
              label: 'Last edit by',
              value: lastEditBy,
            },
          ]}
        />

        {activeProgrammes.length ? (
          <div className="programmes-list__container">
            <List
              size="small"
              dataSource={activeProgrammes}
              header={(
                <Text strong>
                  Active programmes the user is assigned to:
                </Text>
              )}
              renderItem={item => (
                <List.Item>
                  <Link
                    state={{ hideBackButton: true }}
                    to={`/admin/programmes/${item.id}`}
                  >
                    <EllipsisMiddle>
                      {item.name}
                    </EllipsisMiddle>
                  </Link>
                </List.Item>
              )}
            />
          </div>
        ) : (
          <Text italic>
            This user is not assigned to any active programmes
          </Text>
        )}
      </div>
    </div>
  )
}

export default UserDetails
