import { Typography, message } from 'antd'
import { IconCopy, IconLock } from '@tabler/icons-react'
import colors from '../../../styles/_variables.module.scss';
import IconButton from '../../atoms/icon-button'
import Icon from '../../atoms/icon'

const { Title, Text, Link } = Typography

const LockedAccount = () => (
  <section className="locked-account">
    <div className="icon-wrapper">
      <Icon
        component={IconLock}
        componentProps={{
          size: '3.5rem',
          color: colors.mainColor,
        }}
      />
    </div>
    <Title level={2}>Your account is locked!</Title>
    <Text className="locked-account__message">
      Please reach out to L20 Support via email to get further instructions.
    </Text>
    <div className="support-email-container">
      <div className="support-email__box">
        <Link
          href="mailto:support@l20.io"
          target="_blank"
        >
          support@l20.io
        </Link>
      </div>
      <IconButton
        icon={IconCopy}
        type="primary"
        onClick={() => {
          navigator.clipboard.writeText('support@l20.io')
          message.success('Email address copied to clipboard')
        }}
      >
        Copy email
      </IconButton>
    </div>
  </section>
)

export default LockedAccount
