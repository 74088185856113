import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Spinner from '../components/atoms/spinner'
import NoAssignedProgrammes from '../components/views/NoAssignedProgrammes'
import { viewContexts } from '../constants/app.constants'
import { programmeStatuses } from '../constants/programme.constants'
import { CombinedReducers } from '../redux/stores/reducers'
import { isAdmin, isProgrammeManager } from '../services/helpers/user'
import { Programme } from '../types/programme'
import { UserRoles } from '../constants/userRoles.constants'

const RootRoute = () => {
  const [showNoProgrammesMessage, setShowNoProgrammesMessage] = useState(false)
  const {
    programmes,
    isLoggedIn,
    isInitialUserRequestDone,
    isInitialProgrammesRequestDone,
    currentUserRoles
  } = useSelector<CombinedReducers, {
    programmes: Programme[]
    isLoggedIn: boolean
    isInitialUserRequestDone: boolean
    isInitialProgrammesRequestDone: boolean
    currentUserRoles: UserRoles[]
  }>(state => ({
    programmes: state.programme.programmes,
    isLoggedIn: state.user.isLoggedIn,
    isInitialUserRequestDone: state.user.isInitialRequestDone,
    isInitialProgrammesRequestDone: state.programme.isInitialRequestDone,
    currentUserRoles: state.user.userRoles,
  }))

  const navigate = useNavigate()

  useEffect(() => {
    if (isInitialUserRequestDone && !isLoggedIn) {
      return navigate('/login')
    }

    if (!isInitialUserRequestDone || !isInitialProgrammesRequestDone) {
      return
    }

    if (isAdmin(currentUserRoles)) {
      return navigate(`/${viewContexts.admin.key}/programmes`)
    }

    if (isProgrammeManager(currentUserRoles)) {
      if (!programmes.length) {
        setShowNoProgrammesMessage(true)
        return
      }
      const firstActiveProgramme = programmes.find(
        ({ status }) => status === programmeStatuses.active.key
      )
      navigate(
        `/${firstActiveProgramme ? firstActiveProgramme.id : programmes[0]?.id}`
      )
    }
  }, [isInitialProgrammesRequestDone, isInitialUserRequestDone, navigate, programmes, isLoggedIn, currentUserRoles])

  return isInitialUserRequestDone && isInitialProgrammesRequestDone ? (
    <>{showNoProgrammesMessage && <NoAssignedProgrammes />}</>
  ) : (
    <Spinner
      centered
      fillContainer
      size="200px"
    />
  )
}

export default RootRoute
