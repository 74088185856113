import { useParams } from 'react-router-dom'

import PageBackLink from '../../atoms/page-back-link'
import Tabs from '../../atoms/tabs'
import CreateFieldworkersForm from '../../organisms/create-fieldworkers-form'

const CreateFieldworker = () => {
  const { viewContext } = useParams()

  const tabsContent = [
    {
      title: 'Create a new fieldworker',
      component: <CreateFieldworkersForm />,
    },
  ]

  return (
    <div className="create-fieldworker">
      <PageBackLink
        url={`/${viewContext}/fieldworkers`}
        pageName="List"
      />

      <Tabs tabsContent={tabsContent} />
    </div>
  )
}

export default CreateFieldworker
