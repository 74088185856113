import {
  SET_PROGRAMMES,
  SET_ACTIVE_PROGRAMME,
  SET_IS_INITIAL_PROGRAMMES_REQUEST_DONE,
  RESET_STATE,
} from './programme.types.js'

export const setProgrammes = payload => ({
  type: SET_PROGRAMMES,
  payload,
})

export const setActiveProgramme = payload => ({
  type: SET_ACTIVE_PROGRAMME,
  payload,
})

export const setIsInitialRequestDone = payload => ({
  type: SET_IS_INITIAL_PROGRAMMES_REQUEST_DONE,
  payload,
})

export const resetProgrammeState = () => ({
  type: RESET_STATE,
  payload: null,
})
