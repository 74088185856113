import { useState, useEffect, useCallback, useMemo, FC } from 'react'
import {
  Popconfirm,
  Form,
  Table as AntTable,
  Tooltip,
  message,
  Typography,
  InputNumber,
} from 'antd'
import { IconCheck, IconDownload, IconPencil, IconX } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { saveAs } from 'file-saver'
import './styles.scss'
import EditableCell from '../../atoms/editable-table-cell'
import FormItem from '../../atoms/ant-form-item'
import {
  updateItemPrice,
  getMerchantItems as getMerchantItemsApiRequest,
  getItemsCataloguePDF,
} from '../../../services/api/item.api'
import { ApproveMerchantItemsPayload, approveMerchantItems, getMerchantItemsRequest as getMerchantItemsRequestApiRequest } from '../../../services/api/merchant.api'
import EditableImageWithText from '../../molecules/editable-image-with-text'
import { merchantStatuses } from '../../../constants/merchant.constants'
import { replaceElementById } from '../../../services/helpers/helpers'
import IconButton from '../../atoms/icon-button'
import { isAdmin as isUserAdmin } from '../../../services/helpers/user'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { UserRoles } from '../../../constants/userRoles.constants'
import { Programme } from '../../../types/programme'
import { ColumnType } from 'antd/es/table'
import { MerchantItem, MerchantItemsRequest } from '../../../types/item'
import { TableCategoryRow } from '../../../types/generic'
import { Merchant } from '../../../types/user'
import MerchantProductsReview from './products-review'

const MerchantProducts: FC<{ merchant: Merchant, merchantStatus: string }> = ({ merchant, merchantStatus }) => {
  const [merchantItems, setMerchantItems] = useState([])
  const [merchantItemsRequest, setMerchantItemsRequest] = useState<MerchantItemsRequest>(null)
  const [activeItem, setActiveItem] = useState<MerchantItem>(null)
  const [isItemsReviewModalOpen, setIsItemsReviewModalOpen] = useState(false);
  const { activeProgramme, currentUserRoles } = useSelector<CombinedReducers, {activeProgramme: Programme, currentUserRoles: UserRoles[] }>(state => ({
    activeProgramme: state.programme.activeProgramme,
    currentUserRoles: state.user.userRoles,
  }))
  const [form] = Form.useForm<MerchantItem>()
  const navigate = useNavigate()

  const isAdmin = useMemo(() => isUserAdmin(currentUserRoles), [currentUserRoles])

  const getMerchantItems = useCallback(async () => {
    const res = await getMerchantItemsApiRequest(
      activeProgramme?.id,
      merchant.id
    )

    if (!res.hasErrors) {
      const tableData = res.reduce((acc: (MerchantItem | TableCategoryRow)[], category: TableCategoryRow) => {
        acc.push({
          id: category.id,
          name: category.name,
          isCategory: true,
        })
        category.items.forEach(
          ({
            id,
            merchantItem,
            name,
            description,
            image,
            quantity,
            unitType,
          }) => {
            acc.push({
              id,
              name,
              description,
              image,
              unit: `${quantity} ${unitType}`,
              merchantItemId: merchantItem.id,
              price: merchantItem.price,
            })
          }
        )
        return acc
      }, [])
      setMerchantItems(tableData || [])
    }
  }, [activeProgramme, merchant])

  const getMerchantItemsRequest = useCallback(async () => {
    const res = await getMerchantItemsRequestApiRequest(
      activeProgramme.id,
      merchant.id
    )

    setMerchantItemsRequest(res.hasErrors ? null : res)
  }, [activeProgramme, merchant])

  useEffect(() => {
    if (!activeProgramme || !merchant) {
      if (!activeProgramme.id) {
        navigate('/')
      }
      return
    }
    getMerchantItems()
    getMerchantItemsRequest()
  }, [merchant, activeProgramme, getMerchantItems, navigate])

  const isItemEdited = useCallback(
    (itemId: string) => itemId === activeItem?.id,
    [activeItem]
  )

  const edit = useCallback(
    (item: MerchantItem) => {
      form.setFieldsValue({
        ...item,
      })
      setActiveItem(item)
    },
    [form]
  )

  const cancel = useCallback(() => {
    if (!activeItem?.id) {
      setMerchantItems(merchantItems.slice(0, -1))
    }
    setActiveItem(null)
  }, [activeItem, merchantItems])

  const updateItem = useCallback(async (id: string, price: number) => {
    const originalItem = merchantItems.find(item => item.id === id)
    if (originalItem.price === price) {
      return
    }

    const updatedItem = await updateItemPrice(
      activeProgramme.id,
      id,
      merchant.id,
      price
    )

    if (updatedItem.hasErrors) {
      message.error(updatedItem.message)
      return
    }

    setMerchantItems(replaceElementById(merchantItems, updatedItem, id))
    message.success('The item has been updated successfully')
  },
  [activeProgramme, merchant, merchantItems]
  )

  const save = useCallback(async () => {
    if (activeItem) {
      try {
        const { price } = await form.validateFields()
        await updateItem(activeItem.id, price)

        setActiveItem(null)
        await getMerchantItems()
      } catch (e) {
        message.error('Failed to save changes to the item')
      }
    }
  },
  [form, activeItem, updateItem, getMerchantItems])

  const downloadPdf = useCallback(async () => {
    const res = await getItemsCataloguePDF(activeProgramme.id, merchant.id)
    saveAs(res, `Items catalogue - ${merchant.name}.pdf`)
  }, [activeProgramme, merchant])

  const editableFields = useMemo(() => ({
    price: (
      <div className="item-price__wrapper">
        <FormItem
          propertyName="price"
          fieldTitle="Price"
          required={true}
        >
          <InputNumber
            placeholder={activeItem?.price.toString()}
            onChange={(value) => {
              form.setFieldValue('price', value)
              setActiveItem({
                ...activeItem,
                price: value as number,
              })
            }}
          />
        </FormItem>
        {activeProgramme.currency}
      </div>
    ),
  }), [activeItem, activeProgramme, form])

  const tableColumns = useMemo(() => {
    const columns: (ColumnType<MerchantItem & TableCategoryRow> & { editable?: boolean })[] = [
      {
        title: 'Item name',
        key: 'name',
        dataIndex: 'name',
        width: '65%',
        ellipsis: true,
        render: (text, record) =>
          record.isCategory ? (
            <Typography.Text strong>{text}</Typography.Text>
          ) : (
            <>
              <EditableImageWithText
                currentImage={record.image}
                title={{ value: text }}
                subtitle={{ value: record.description }}
              />
            </>
          ),
      },
      {
        title: 'Unit',
        key: 'unit',
        dataIndex: 'unit',
        width: '10%',
        align: 'center',
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        width: '15%',
        align: 'center',
        editable: isAdmin,
        render: price => (
          <Typography.Text>
            {price} {activeProgramme.currency}
          </Typography.Text>
        ),
      }
    ]

    if (isAdmin) {
      columns.push({
        title: 'Actions',
        key: 'operation',
        dataIndex: 'operation',
        width: '10%',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="items-management__action-buttons">
              {isItemEdited(record.id)
                ? (
                  <>
                    <Form.Item>
                      <Tooltip title="Cancel">
                        <Popconfirm
                          title="Cancel editing?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={cancel}
                        >
                          <IconButton
                            shape="circle"
                            icon={IconX}
                          />
                        </Popconfirm>
                      </Tooltip>
                    </Form.Item>

                    <Form.Item>
                      <Tooltip title="Save">
                        <IconButton
                          htmlType="submit"
                          shape="circle"
                          type="primary"
                          icon={IconCheck}
                        />
                      </Tooltip>
                    </Form.Item>
                  </>
                )
                : (
                  <Tooltip title="Edit">
                    <IconButton
                      ghost={false}
                      shape="circle"
                      icon={IconPencil}
                      disabled={!!(activeItem?.id || activeItem?.id === null)}
                      onClick={() => edit(record)}
                    />
                  </Tooltip>
                )}
            </div>
          )
        },
      })
    }

    return columns.map((column) => {
      const getColSpan = (columnName, isCategory) => {
        if (!isCategory) {
          return
        }

        if (columnName === 'name') {
          return 4
        }
        return 0
      }

      if (!column.editable) {
        return {
          ...column,
          onCell: (record) => {
            return { colSpan: getColSpan(column.key, record.isCategory) }
          },
        }
      }

      return {
        ...column,
        onCell: (record) => {
          return {
            record,
            dataIndex: column.dataIndex,
            title: column.title,
            isInEditMode: record.id === activeItem?.id,
            cellContent: editableFields[column.key],
            colSpan: getColSpan(column.key, record.isCategory),
          }
        },
      }
    })
  }, [isAdmin, activeProgramme, isItemEdited, cancel, activeItem, edit, editableFields])

  const onApprove = async (approvedItems: ApproveMerchantItemsPayload[]) => {
    const approveMerchantItemsRes = await approveMerchantItems(activeProgramme.id, merchant.id, merchantItemsRequest.id, approvedItems)

    if (approveMerchantItemsRes.hasErrors) {
      message.error(approveMerchantItemsRes.message)
      return
    }

    message.success('Review submitted')
    await getMerchantItems()
    await getMerchantItemsRequest()
    setIsItemsReviewModalOpen(false)
  }

  return (
    <div className="merchant-items">
      <div className="action-buttons">
        {merchantItemsRequest && (
          <>
            {merchantItemsRequest && (
              <IconButton
                type="primary"
                danger
                size="large"
                onClick={() => setIsItemsReviewModalOpen(true)}
                disabled={
                  ![
                    merchantStatuses.active.key,
                    merchantStatuses.approved.key,
                  ].includes(merchantStatus)
                }
                icon={IconDownload}
              >
                Review items
              </IconButton>
            )}

            <MerchantProductsReview
              items={merchantItemsRequest?.updatedItems}
              onOk={onApprove}
              isModalOpen={isItemsReviewModalOpen}
              setIsModalOpen={setIsItemsReviewModalOpen}
            />
          </>
        )}

        {!!merchantItems.length && (
          <IconButton
            type="primary"
            size="large"
            onClick={downloadPdf}
            disabled={
              ![
                merchantStatuses.active.key,
                merchantStatuses.approved.key,
              ].includes(merchantStatus)
            }
            icon={IconDownload}
          >
            Catalogue
          </IconButton>
        )}
      </div>
      <Form
        form={form}
        onFinish={save}
        initialValues={activeItem && Object.keys(activeItem).length ? activeItem : null}
        className="merchant-items__form"
      >
        <AntTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={merchantItems}
          columns={tableColumns}
          rowKey={record => record.id}
          className="merchant-items__table"
          rowClassName={record =>
            `merchant-items__editable-row ${record.isCategory ? 'merchant-items_category' : ''}`
          }
          pagination={false}
          bordered
        />
      </Form>
    </div>
  )
}

export default MerchantProducts
