import store from '../../redux/store'
import { resetAppState } from '../../redux/stores/application/app.actions'
import { resetUserState } from '../../redux/stores/user/user.actions'
import { resetProgrammeState } from '../../redux/stores/programme/programme.actions'

export const resetGlobalState = () => {
  store.dispatch(resetAppState())
  store.dispatch(resetUserState())
  store.dispatch(resetProgrammeState())
}
