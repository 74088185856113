import { useState } from 'react'
import { Input } from 'antd'
import './styles.scss'
import Card from '../../atoms/card'
import HouseholdsTable from '../../molecules/households-table'

const { Search } = Input

const HouseholdsList = () => {
  const [search, setSearch] = useState('')

  return (
    <div className="households-list">
      <div className="households-actions">
        <Search
          value={search}
          allowClear
          size="large"
          placeholder="Household ID"
          className="search-wrapper"
          onChange={e => setSearch(e.target.value)}
        />
      </div>

      <Card>
        <HouseholdsTable searchQuery={search} />
      </Card>
    </div>
  )
}

export default HouseholdsList
