import { Button, Form, Input, message, Select } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UserRoles as userRolesEnum } from '../../../constants/userRoles.constants'
import { addAdmin } from '../../../services/api/admin.api'
import { addProgrammeManager } from '../../../services/api/manager.api'
import { getRoles } from '../../../services/api/role.api'
import PhoneInput from '../../atoms/phone-input'
import './styles.scss'

const CreateUsersForm = () => {
  const [form] = Form.useForm()
  const [userRoles, setUserRoles] = useState(null)
  const { viewContext } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    getRoles().then((rolesResponse) => {
      if (rolesResponse.hasErrors) {
        message.error('Failed to load the user roles')
        return
      }
      const rolesOptions = rolesResponse.map((role) => {
        return {
          value: role.id,
          label: role.name,
        }
      })
      setUserRoles(rolesOptions)
    })
  }, [])

  const onSubmitForm = useCallback(
    async ({ email, firstName, lastName, phone, userRoleId }) => {
      const payload = {
        email,
        firstName,
        lastName,
        phone,
      }

      const createUserResponse = userRoles.find(userRole => userRole.value === userRoleId).label === userRolesEnum.admin
        ? await addAdmin(payload)
        : await addProgrammeManager(payload)

      if (createUserResponse.hasErrors) {
        message.error(`Failed to create the user. ${createUserResponse.message}`)
        return
      }
      navigate(`/${viewContext}/users`, {
        state: {
          message: {
            type: 'success',
            text: 'Successfully created the user',
          }
        },
      })
    },
    [navigate, userRoles, viewContext]
  )

  return (
    <div className="create-user-form__wrapper">
      <Form
        form={form}
        name="createUser"
        autoComplete="on"
        onFinish={onSubmitForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelWrap
        size="large"
      >
        <Form.Item
          label="Role"
          name="userRoleId"
          rules={[
            {
              required: true,
              message: 'Please select a role.',
            },
          ]}
        >
          <Select
            options={userRoles}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item
          label="First name"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Please type a first name.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Please type a last name.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please type a valid email address.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <PhoneInput />

        <div className="form-buttons">
          <Form.Item
            wrapperCol={{
              offset: 10,
              span: 14,
            }}
          >
            <Button
              type="default"
              onClick={() => navigate(`/${viewContext}/users`)}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default CreateUsersForm
