import store from '../../redux/store'
import { getProgrammes } from '../api/programme.api'
import { setProgrammes, setIsInitialRequestDone } from '../../redux/stores/programme/programme.actions'

const { dispatch } = store

export const updateProgrammeSelectorData = async () => {
  const programmesResponse = await getProgrammes()
  if (programmesResponse.hasErrors || !Array.isArray(programmesResponse.data)) {
    return []
  }
  dispatch(setProgrammes(programmesResponse.data))
}

export const initializeProgrammes = async () => {
  await updateProgrammeSelectorData()

  dispatch(setIsInitialRequestDone(true))
}
