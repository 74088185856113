import { FC, MouseEventHandler } from 'react'
import { Popconfirm, UploadFile } from 'antd';
import { IconAlertTriangle, IconCheck, IconTrash, IconX } from '@tabler/icons-react';
import colors from '../../../styles/_variables.module.scss';
import Icon from '../../atoms/icon';
import IconButton from '../../atoms/icon-button';
import ImageUploader from '../../molecules/image-uploader';
import { RcFile } from 'antd/es/upload';

const NgoLogoControl: FC<{
  currentLogo: string
  ngoLogo: UploadFile
  disabled: boolean
  beforeUpload: (imageFile: RcFile) => false
  onSave: MouseEventHandler,
  onCancel: MouseEventHandler,
  onDelete: MouseEventHandler,
}> = ({
  currentLogo,
  ngoLogo,
  disabled,
  beforeUpload,
  onSave,
  onCancel,
  onDelete,
}) => {
  return (
    <>
      <div className="image-uploader__wrapper">
        <ImageUploader
          existingImage={currentLogo}
          file={ngoLogo}
          beforeUpload={beforeUpload}
          imagePreviewSize="3rem"
          disabled={disabled}
        />
        <div className="actions__container">
          {ngoLogo && (
            <>
              <IconButton
                icon={IconCheck}
                iconProps={{ size: '1rem' }}
                type="default"
                onClick={onSave}
              />
              <IconButton
                icon={IconX}
                iconProps={{ size: '1rem' }}
                type="text"
                onClick={onCancel}
              />
            </>

          )}
          {currentLogo && !ngoLogo && (
            <Popconfirm
              title="Delete the organisation logo"
              description="Are you sure you want to delete the organisation logo?"
              icon={(
                <Icon
                  component={IconAlertTriangle}
                  componentProps={{ color: colors.error, size: '1rem' }}
                />
              )}
              onConfirm={onDelete}
              placement="topRight"
            >
              <IconButton
                icon={IconTrash}
                iconProps={{ size: '1rem' }}
                type="text"
                danger
              />
            </Popconfirm>
          )}
        </div>
      </div>
    </>
  )
}

export default NgoLogoControl
