import './index.scss'
import variables from './styles/_variables.module.scss'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ConfigProvider, theme } from 'antd'
import en_GB from 'antd/locale/en_GB';
import { Router } from './router'
import store from './redux/store'
import * as serviceWorker from './serviceWorker'
import { validateToken } from './services/initializers/user.initializer'
import { appConstants } from './constants/app.constants'
import { initializeProgrammes } from './services/initializers/programmes.initializer'
import { intializeDayJs } from './services/initializers/dayjs.initalizer'
import CookieDisclaimer from './components/molecules/cookie-disclaimer'

// if (process.env.NODE_ENV === 'local') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

validateToken()
initializeProgrammes()
intializeDayJs()

createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <ConfigProvider
      locale={en_GB}
      csp={{ nonce: appConstants.antdNonce }}
      theme={{
        algorithm: theme.defaultAlgorithm,
        token: {
          colorInfo: variables.mainColor,
          colorPrimary: variables.mainColor,
          colorSuccess: variables.success,
          colorWarning: variables.warning,
          colorError: variables.error,
          colorText: variables.darkGrey,
          colorTextHeading: variables.darkGrey,
          borderRadius: 5,
          fontFamily: '"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
        },
        components: {
          Menu: {
            colorItemBg: variables.mainColor,
            colorItemText: variables.background,
            colorItemTextSelected: variables.white,
            colorItemBgSelected: variables.navBar,
            colorItemTextHover: variables.white,
          }
        }
      }}
    >
      <Router />
      <CookieDisclaimer />
    </ConfigProvider>
  </Provider>
)

serviceWorker.unregister()
