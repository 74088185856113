import { useParams } from 'react-router-dom'

import PageBackLink from '../../atoms/page-back-link'
import Tabs from '../../atoms/tabs'
import CreateProgrammeForm from '../../organisms/create-programme-form'

const CreateProgramme = () => {
  const { viewContext } = useParams()

  const tabsContent = [
    {
      title: 'Create Programme',
      component: <CreateProgrammeForm />,
    },
  ]

  return (
    <div className="create-programme">
      <PageBackLink
        url={`/${viewContext}/programmes`}
        pageName="List"
      />
      <Tabs tabsContent={tabsContent} />
    </div>
  )
}

export default CreateProgramme
