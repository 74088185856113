import { TablerIconsProps } from '@tabler/icons-react'
import classNames from 'classnames'
import { FC, HTMLAttributes } from 'react'

/**
 * Icon wrapper for antd components.
 * Used for passing non-antd icons to antd components. Replicated the default Icon component from '@ant-design/icons'
 *
 * https://github.com/ant-design/ant-design-icons/blob/master/packages/icons-react/src/components/Icon.tsx
 */
const Icon: FC<HTMLAttributes<HTMLSpanElement> & {
  component: FC,
  componentProps?: TablerIconsProps,
  spin?: boolean,
  className?: string;
}> = ({
  component: Component,
  componentProps,
  spin,
  className,
  ...props
}) => {
  return (
    <span
      role="img"
      className={classNames(
        'anticon',
        { 'anticon-spin': spin },
        className
      )}
      {...props}
    >
      <Component {...componentProps} />
    </span>
  )
}

export default Icon
