import { Status } from '../../types/generic'
import { capitalizeFirstLetter } from './helpers'

export const getByKey = (constants, key) => {
  return constants[Object.keys(constants).find(objectKey => constants[objectKey].key === key)]
}

export const statusToTextValue = (status: { [key: string]: Status }) => Object.keys(status).map((statusKey) => {
  const { key, label } = status[statusKey]
  return {
    text: capitalizeFirstLetter(label),
    value: key,
  }
})
