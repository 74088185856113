import { SortOrder } from 'antd/es/table/interface';
import { useState } from 'react'

// mapping for antd sort directions. This should be updated when upgrading to AntDesign 5.0
export enum SortDirections {
  ascend = 'asc',
  descend = 'desc',
}

export const getSortDirectionValue = (sortDirection: string): string => SortDirections[sortDirection]

export type Query = {
  page?: number,
  pageSize?: number,
  order?: string,
  orderType?: SortDirections,
  search?: string,
  [key: string]: string | number;
}

export type Filter = {name: string, value: string | number}

export type UseQuery = {
  query: Query;
  numberOfQueryUpdates: number;
  onSort: (sortBy: string, sortOrder: SortOrder) => void;
  onSearch: (searchText: string, searchParam?: string) => void;
  onFilter: (name: string, value: string) => void;
  onFilters: (filters: Filter[]) => void;
  onPageChange: (page: number) => void;
  resetQuery: () => void;
}

export const useQuery: (defaultQuery: Query) => UseQuery = (defaultQuery) => {
  const [query, setQuery] = useState<Query>(defaultQuery || {})
  const [numberOfQueryUpdates, setNumberOfQueryUpdates] = useState(0)

  const incrementNumberOfQueryUpdates = () => {
    setNumberOfQueryUpdates(numberOfQueryUpdates + 1)
  }

  const onSort: UseQuery['onSort'] = (sortBy, sortOrder) => {
    let order = sortBy
    let orderType = null
    if (sortOrder) {
      orderType = SortDirections[sortOrder]
    } else {
      order = defaultQuery.order
      orderType = defaultQuery.orderType
    }

    const newQuery: Query = {
      ...query,
      order,
      orderType,
      page: 1,
    }
    incrementNumberOfQueryUpdates()
    setQuery(newQuery)
  }

  const onSearch = (searchText: string, searchParam = 'search') => {
    let newQuery = {
      page: 1,
    }
    if (!searchText) {
      const {
        [searchParam]: _deletedParam,
        ...withoutSearchParam
      } = query

      newQuery = {
        ...withoutSearchParam,
        ...newQuery,
      }
    }
    else {
      newQuery = {
        ...query,
        ...newQuery,
        [searchParam]: searchText
      }
    }

    incrementNumberOfQueryUpdates()
    setQuery(newQuery)
  }

  const onFilter = (name: string, value: string) => {
    const newQuery = {
      ...query,
      [name]: value,
      page: 1,
    }
    incrementNumberOfQueryUpdates()
    setQuery(newQuery)
  }

  const onFilters = (filters: Filter[]) => {
    const newQuery = {
      ...query,
      page: 1,
    }

    filters.forEach((filter: Filter) => {
      newQuery[filter.name] = filter.value
    })
    incrementNumberOfQueryUpdates()
    setQuery(newQuery)
  }

  const onPageChange = (page: number) => {
    const newQuery = {
      ...query,
      page,
    }
    incrementNumberOfQueryUpdates()
    setQuery(newQuery)
  }

  const resetQuery = () => {
    incrementNumberOfQueryUpdates()
    setQuery(defaultQuery)
  }

  return {
    query,
    numberOfQueryUpdates,
    onSort,
    onSearch,
    onFilter,
    onFilters,
    onPageChange,
    resetQuery,
  }
}
