import { useCallback } from 'react'
import './styles.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { addFieldworker } from '../../../services/api/fieldworker.api'
import { useSelector } from 'react-redux'
import { Form, Input, message, Button } from 'antd'
import PhoneInput from '../../atoms/phone-input'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'

const CreateFieldworkersForm = () => {
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  const [form] = Form.useForm()
  const { viewContext } = useParams()
  const navigate = useNavigate()

  const onSubmitForm = useCallback(
    async ({ firstName, lastName, email, phone }) => {
      const payload = {
        firstName,
        lastName,
        email,
        phone,
        programmeId: activeProgramme.id
      }

      const voucherBatchRes = await addFieldworker(payload)
      if (voucherBatchRes.message) {
        message.error(voucherBatchRes.message)
        return
      }

      navigate(`/${viewContext}/fieldworkers`, {
        state: {
          message: {
            type: 'success',
            text: 'Successfully added the new fieldworker to the programme',
          },
        },
      })
    },
    [activeProgramme, navigate, viewContext]
  )

  return (
    <div className="create-fieldworker-form__wrapper">
      <Form
        form={form}
        name="createFieldworker"
        autoComplete="on"
        onFinish={onSubmitForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelWrap
        size="large"
      >
        <Form.Item
          label="First name"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Please type a first name.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Please type a last name.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please type a valid email address.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <PhoneInput />

        <div className="form-buttons">
          <Form.Item
            wrapperCol={{
              offset: 10,
              span: 14,
            }}
          >
            <Button
              type="default"
              onClick={() => navigate(`/${viewContext}/fieldworkers`)}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default CreateFieldworkersForm
