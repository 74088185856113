import { ChangeEventHandler, FC, useMemo } from 'react'
import classNames from 'classnames'
import './styles.scss'

type TextInputProps = {
  absoluteLabel?
  autoCapitalize?
  autoComplete?
  autoCorrect?
  className?
  disabled?
  error?
  grayedOut?
  handleChange?: ChangeEventHandler<HTMLInputElement>
  icon?
  iconAction?
  iconAlign?
  iconStyle?
  inlineLabel?
  label?
  maxLength?
  min?
  name?
  onClick?
  onEnterKeypress?
  onInput?
  onKeyDown?
  placeholder?
  readOnly?
  register?
  spellCheck?
  style?
  type?
  warning?
  value?: string
}
const TextInput: FC<TextInputProps> = ({ value = '', ...props }) => {
  const uniqueId = useMemo(() => `_${Date.now()}`, [])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && props.onEnterKeypress) {
      props.onEnterKeypress()
    }
  }

  const containerClassName = classNames('input-wrapper', {
    error: !!props.error,
    'inline-label': !!props.inlineLabel || !props.label,
    'inline-label--absolute':
      !!props.inlineLabel && props.absoluteLabel !== false,
  })

  const inputClassName = classNames('text-input', {
    [props.className]: !!props.className,
    disabled: props.grayedOut,
    warning: props.warning,
  })

  const iconClassName = classNames(
    'input-icon',
    `icon-${props.iconStyle || 'default'}`,
    `icon-${props.iconAlign || 'right'}`,
    {
      'icon-with-action': props.iconAction,
    }
  )

  return (
    <div className={containerClassName}>
      {props.label && <label htmlFor={uniqueId}>{props.label}</label>}
      <input
        type={props.type}
        id={uniqueId}
        value={value}
        title={value}
        className={inputClassName}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        style={props.style}
        ref={props.register}
        name={props.name || ''}
        min={props.min}
        onKeyPress={handleKeyPress}
        onClick={props.onClick || (() => { })()}
        readOnly={!!props.readOnly}
        onKeyDown={props.onKeyDown}
        onInput={props.onInput}
        disabled={props.disabled || false}
        maxLength={props.maxLength}
        autoComplete={props.autoComplete || 'on'}
        autoCorrect={props.autoCorrect || 'on'}
        autoCapitalize={props.autoCapitalize || 'on'}
        spellCheck={props.spellCheck || 'true'}
      />
      {!!props.icon && (
        <div
          className={iconClassName}
          onClick={props.iconAction}
        >
          {props.icon}
        </div>
      )}
    </div>
  )
}

export default TextInput
