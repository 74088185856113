import { FC, ReactNode, useState } from 'react'
import { Select } from 'antd'
import './styles.scss'
import EditableField, { EditableFieldContext } from './editable-field'
import { DefaultOptionType } from 'antd/es/select'

const EditableSelect: FC<{
  defaultValue: ReactNode
  isEditable: boolean
  options: DefaultOptionType[]
  onSave: (fieldValue: string) => Promise<void>
}> = ({
  defaultValue,
  isEditable,
  options,
  onSave,
}) => {
  const [inputValue, setInputValue] = useState(null)

  return (
    <EditableFieldContext.Provider
      value={{
        fieldValue: inputValue,
        setFieldValue: setInputValue
      }}
    >
      <EditableField
        readOnlyContent={defaultValue}
        className="editable-field__select"
        isSaveDisabled={!inputValue}
        onSave={onSave}
        isEditable={isEditable}
      >
        <Select
          options={options}
          defaultValue={defaultValue}
          onSelect={((value) => {
            setInputValue(value)
          })}
        />
      </EditableField>
    </EditableFieldContext.Provider>
  )
}

export default EditableSelect
