import { IconCircleCheck, IconCircleDashed } from '@tabler/icons-react'
import { useState, useEffect, FC } from 'react'
import Icon from '../../icon'

// const alphaNumRegex = (/^(?=.*[a-zA-Z])(?=.*[0-9])/);
const specialCharactersRegex = /[^a-z\d]+/iu
const numberRegex = /[0-9]/u
const lowercaseRegex = /[a-z]/u
const uppercaseRegex = /[A-Z]/u

const validationTypes = {
  length: {
    text: 'at least 8 characters in length',
    validator: value => value && value.length > 7,
  },
  specialChar: {
    text: 'at least 1 special character (i.e. % $ ^ @ !)',
    validator: value => specialCharactersRegex.test(value),
  },
  number: {
    text: 'at least 1 number',
    validator: value => numberRegex.test(value),
  },
  upperChar: {
    text: 'at least 1 uppercase character',
    validator: value => uppercaseRegex.test(value),
  },
  lowerChar: {
    text: 'at least 1 lowercase character',
    validator: value => lowercaseRegex.test(value),
  },
}

const StrengthCriterionComponent: FC<{
  onChange: (isValid: boolean) => void
  type: 'length' | 'specialChar' | 'number' | 'upperChar' | 'lowerChar'
  value: string
}> = ({ onChange, type, value }) => {
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    onChange(isValid)
  }, [isValid, onChange])

  useEffect(() => {
    const validity = validationTypes[type].validator(value)
    setIsValid(validity)
  }, [type, value])

  return (
    <div className="strength-criterion">
      {isValid ? (
        <Icon
          component={IconCircleCheck}
          className="strong-icon"
        />
      ) : (
        <Icon
          component={IconCircleDashed}
          className="weak-icon"
        />
      )}
      <p className="validation-requirement-text">
        {validationTypes[type].text}
      </p>
    </div>
  )
}

export default StrengthCriterionComponent
