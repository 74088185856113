import { IconAlertCircle, IconLockOpen } from '@tabler/icons-react'
import { Button, Input, message, Modal, ModalFuncProps, Typography } from 'antd'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import './styles.scss'

import { merchantStatuses } from '../../../constants/merchant.constants'
import {
  changeVouchersAllocatedMerchant, disableMerchant as disableMerchantAcrossAllProgrammes, getAvailableVouchersForMerchant,
  getMerchant,
  updateMerchantStatus
} from '../../../services/api/merchant.api'
import { unlockUser } from '../../../services/api/user.api'
import { getFullName, getMerchantStatus } from '../../../services/helpers/helpers'
import { serverDateToString } from '../../../services/helpers/date'
import { isAdmin } from '../../../services/helpers/user'
import Icon from '../../atoms/icon'
import PageBackLink from '../../atoms/page-back-link'
import StatusPill from '../../atoms/status-pill'
import Tabs from '../../atoms/tabs'
import SelectMerchantModal from '../../molecules/select-merchant-modal'
import MerchantBusinessDetails from '../../organisms/merchant-business-details'
import MerchantEmployeesList from '../../organisms/merchant-employees'
import MerchantProducts from '../../organisms/merchant-products'
import MerchantTransactionsList from '../../organisms/merchant-transaction-list'
import MerchantUserDetails from '../../organisms/merchant-user-details'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'
import { UserRoles } from '../../../constants/userRoles.constants'
import { Merchant } from '../../../types/user'
import { LabelValue } from '../../../types/generic'
import { InfoBoxProps } from '../../atoms/info-box'

const { Paragraph } = Typography
const { TextArea } = Input

const MerchantDetails = () => {
  const [modal, modalContextHolder] = Modal.useModal();
  const modalWindow = useRef<{
      destroy:() => void,
      update: (configUpdate: ModalFuncProps) => void
  }>()

  const [merchant, setMerchant] = useState<Merchant>(null)
  const [modalIsShown, showModal] = useState(false)
  const [merchantRequestInfo, setMerchantRequestInfo] = useState(null)
  const [reviewedBy, setReviewedBy] = useState(null)
  const [merchantStatus, setMerchantStatus] = useState('')
  const [isLocked, setIsLocked] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')

  const { activeProgramme, currentUserRoles } = useSelector<CombinedReducers, { activeProgramme: Programme, currentUserRoles: UserRoles[] }>(state => ({
    activeProgramme: state.programme.activeProgramme,
    currentUserRoles: state.user.userRoles,
  }))

  const { viewContext, merchantId } = useParams()
  const [query] = useLocation().state || []

  const setRequestInfo = (merchantDetails) => {
    const info = {
      requestedOn: merchantDetails.createdAt,
      approvedOn: merchantDetails.approvedOn || '',
      rejectionReason: merchantDetails.approvalDecision || ''
    }
    setMerchantRequestInfo(info)
  }

  const setMerchantData = useCallback((merchantRes) => {
    if (merchantRes && !merchantRes.hasErrors) {
      setMerchant(merchantRes)
      setIsLocked(merchantRes.CreatedBy.isLocked)
      setMerchantStatus(getMerchantStatus(merchantRes))
      setRequestInfo(merchantRes)
      if (merchantRes.reviewedBy) {
        setReviewedBy(getFullName(merchantRes.reviewedBy))
      }
    }
  }, [])

  useEffect(() => {
    if (activeProgramme && activeProgramme.id && merchantId) {
      getMerchant(activeProgramme.id, merchantId).then((merchantRes) => {
        setMerchantData(merchantRes)
      })
    }
  }, [activeProgramme, merchantId, setMerchantData])


  const disableMerchantForAllProgramme = async () => {
    const res = await disableMerchantAcrossAllProgrammes(merchantId)
    if (res.hasErrors) {
      message.error('Could not update the supplier status.')
    }
    getMerchant(activeProgramme.id, merchantId).then((merchantRes) => {
      setMerchantData(merchantRes)
    })
  }

  const updateStatus = useCallback(
    async (status: string, reason: string, transferCertificatesToMerchantId?: string) => {
      if (transferCertificatesToMerchantId) {
        const res = await changeVouchersAllocatedMerchant({
          programmeId: activeProgramme.id,
          toMerchantId: transferCertificatesToMerchantId,
          fromMerchantId: merchantId
        })
        if (res.hasErrors) {
          message.error('Could not update the merchant status.')
          return
        }
      }

      const res = await updateMerchantStatus(activeProgramme.id, merchantId, {
        status,
        reason
      })

      if (res.hasErrors) {
        message.error('Could not update the supplier status.')
        return
      }
      setMerchantData(res)
    },
    [activeProgramme, merchantId, setMerchantData]
  )

  const hasRequestProperty = useCallback(
    (property) => {
      return Boolean(merchantRequestInfo && merchantRequestInfo[property])
    },
    [merchantRequestInfo]
  )

  const onUnlockUser = useCallback(async () => {
    const res = await unlockUser(merchant.CreatedBy.id)
    if (res.hasErrors) {
      message.error('Could not unlock the user account')
      return
    }
    setIsLocked(res.isLocked)
    message.success('The user account has been unlocked.')
  }, [merchant])

  const infoBoxActions: ReactNode[] = useMemo(() => {
    const actions = []

    if (isAdmin(currentUserRoles) && isLocked) {
      actions.push(
        <Button
          type="primary"
          onClick={onUnlockUser}
          key="Unlock User"
          icon={(
            <Icon
              component={IconLockOpen}
              componentProps={{
                height: '1.2rem',
                width: '1.2rem'
              }}
            />
          )}
        >
          Unlock User
        </Button>
      )
    }

    return actions
  }, [currentUserRoles, isLocked, onUnlockUser])

  const infoBoxDetails: LabelValue[] = useMemo(() => {
    const details = [
      {
        label: 'Status',
        value: <StatusPill statusData={merchantStatuses[merchantStatus]} />,
      },
      {
        label: 'Request sent on',
        value: hasRequestProperty('requestedOn') && serverDateToString(merchantRequestInfo.requestedOn),
      },
    ]

    if (merchantStatus === merchantStatuses.approved.key || merchantStatus === merchantStatuses.active.key) {
      details.push(
        {
          label: 'Approved on',
          value: hasRequestProperty('approvedOn') && serverDateToString(merchantRequestInfo.approvedOn),
        },
        {
          label: 'Approved By',
          value: reviewedBy,
        }
      )
    }

    if (merchantStatus === merchantStatuses.disabled.key) {
      details.push(
        {
          label: 'Disabled By',
          value: reviewedBy,
        }
      )
    }

    if (merchantStatus === merchantStatuses.rejected.key) {
      details.push(
        {
          label: 'Reason',
          value: hasRequestProperty('rejectionReason') && merchantRequestInfo.rejectionReason
        }
      )
    }

    return details
  }, [hasRequestProperty, merchantRequestInfo, merchantStatus, reviewedBy])

  const infoBoxProps: InfoBoxProps = useMemo(
    () => ({
      details: infoBoxDetails,
      actions: infoBoxActions,
    }),
    [infoBoxActions, infoBoxDetails]
  )

  const tabsContent = [
    {
      title: 'Business Details',
      component: (
        <MerchantBusinessDetails
          merchantDetails={merchant}
          infoBoxProps={infoBoxProps}
        />
      ),
    },
    {
      title: 'User Details',
      component: (
        <MerchantUserDetails
          merchantDetails={merchant}
          infoBoxProps={infoBoxProps}
        />
      ),
    },
    {
      title: 'Items',
      component: (
        <MerchantProducts
          merchant={merchant}
          merchantStatus={merchantStatus}
        />
      ),
    },
    {
      title: 'Transactions',
      component: (
        <MerchantTransactionsList
          merchantId={merchantId}
          merchantName={merchant?.name}
        />
      ),
    },
    {
      title: 'Employees',
      component: <MerchantEmployeesList />,
    },
  ]

  const onDisableMerchant = async () => {
    const availableVouchersForMerchant = (await getAvailableVouchersForMerchant({ merchantId, programmeId: activeProgramme.id }))?.count ?? 0;
    if (availableVouchersForMerchant > 0) {
      showModal(true)
    } else {
      updateStatus(merchantStatuses.disabled.key as string, null)
    }
  }

  const onApproveMerchant = () => {
    updateStatus(merchantStatuses.approved.key as string, null)
  }

  const onEnableMerchant = () => {
    updateStatus(merchantStatuses.active.key as string, null)
  }
  const onRejectOk = useCallback((reason) => {
    return () => {
      updateStatus(merchantStatuses.rejected.key as string, reason)
    }
  }, [updateStatus])

  const onRejectMerchant = () => {
    modalWindow.current = modal.confirm({
      title: 'Reject merchant',
      icon: <Icon component={IconAlertCircle} />,
      okText: 'Confirm Rejection',
      content: (
        <>
          <Paragraph>
            Specify the reason the supplier has been rejected. <br /> Please
            include as many details as possible.
          </Paragraph>
          <TextArea
            rows={4}
            onChange={
              (e) => {
                setRejectionReason(e.target.value);
                modalWindow.current.update({
                  onOk: onRejectOk(e.target.value)
                })
              }
            }
          />
        </>
      ),
      onOk: onRejectOk(rejectionReason),
    });
  }

  return (
    <div className="merchant-details">
      <div className="merchant-details__navigation">
        <PageBackLink
          url={`/${viewContext}/suppliers`}
          pageName="List"
          state={[query]}
        />
        <div className="merchant-details__actions">
          {isAdmin(currentUserRoles) && (merchantStatus !== merchantStatuses.disabled.key) && (
            <Button
              danger
              size="large"
              onClick={disableMerchantForAllProgramme}
            >
              Disable Supplier
            </Button>
          )}

          {([merchantStatuses.approved.key, merchantStatuses.active.key]).find(key => key === merchantStatus) && (
            <Button
              size="large"
              onClick={onDisableMerchant}
            >
              Suspend Supplier Account
            </Button>
          )}

          {merchantStatus === merchantStatuses.disabled.key && (
            <Button
              size="large"
              onClick={onEnableMerchant}
            >
              Enable Supplier Account
            </Button>
          )}

          {merchantStatus === merchantStatuses.pending.key && (
            <>
              <Button
                size="large"
                onClick={onRejectMerchant}
              >
                Reject
              </Button>
              <Button
                className=""
                size="large"
                type="primary"
                onClick={onApproveMerchant}
              >
                Approve
              </Button>
            </>
          )}
        </div>
      </div>

      <Tabs tabsContent={tabsContent} />

      {activeProgramme?.id && merchantRequestInfo && (
        <SelectMerchantModal
          open={modalIsShown}
          currentMerchant={merchant}
          programmeId={activeProgramme.id}
          title="Select supplier replacement before disabling"
          label="To make sure beneficiaries have a supplier to go to, please select a replacement"
          onCancel={() => {
            showModal(false)
          }}
          getFooter={merchantId => (
            <div className="merchant-details__modal-footer">
              <Button
                key="1"
                onClick={() => {
                  updateStatus(merchantStatuses.disabled.key as string, null);
                  showModal(false)
                }}
                danger
                type="primary"
                className="merchant-details__disable-button"
              >
                Disable
              </Button>
              <Button
                key="2"
                onClick={() => {
                  showModal(false)
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!merchantId}
                onClick={() => {
                  updateStatus(merchantStatuses.disabled.key as string, null, merchantId).then(() => {
                    showModal(false)
                  });

                }}
                key="3"
                type="primary"
              >
                Disable & Replace
              </Button>
            </div>
          )}
        />
      )}
      {modalContextHolder}
    </div>
  )
}

export default MerchantDetails
