import { Checkbox, Button } from 'antd'
import { mapProductName } from '../product-table/product-table.config'
import { Query, SortDirections } from '../../../hooks/query.hook'
import { TransactionType } from '../../../constants/transactions.constants'

export const defaultQuery: Query = {
  order: 'createdAt',
  orderType: SortDirections.descend,
  pageSize: 10,
  page: 1,
}

export const mapTransactionType = (transactionType: keyof TransactionType) => (
  <span className="transaction-type-wrapper">{transactionType}</span>
)

export const mapProductTransactions = (productTransactions) => {
  if (!Array.isArray(productTransactions)) {
    return []
  }

  return productTransactions.map((productTransaction) => {
    return {
      id: productTransaction.id,
      item: mapProductName(productTransaction.Item),
      quantity: productTransaction.quantity,
      value: `${productTransaction.totalAmount} ${productTransaction.currency}`,
    }
  })
}

export const mapActions = (actions) => {
  return (
    <div className="actions-container">
      {actions.map((action, i) => (
        <Button
          type="text"
          shape="circle"
          onClick={action.onClick}
          key={i}
        >
          {action.icon}
        </Button>
      ))}
    </div>
  )
}

export const mapIsProcessed = (isProcessed, transactionId, onProcessItem) => {
  return (
    <div className="checkbox-container">
      <Checkbox
        checked={isProcessed}
        onChange={async (e) => {
          await onProcessItem(transactionId, e.target.checked)
        }}
      />
    </div>
  )
}
