export enum ViewModes {
  admin = 'admin',
  programme = 'programme',
  noProgramme = 'noProgramme',
}

export enum EnvTypes {
  production = 'production',
  staging = 'staging',
  development = 'development',
  local = 'local',
}

export const appConstants = {
  antdNonce: '268337446',
}

export const viewContexts = {
  admin: {
    key: 'admin',
    label: 'L20 Admin',
  },
}

export const testingCaptchaSiteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
