import dayjs from 'dayjs'
import { merchantStatuses } from '../../constants/merchant.constants'
import { userStatuses } from '../../constants/user.constants'

export const addIfExists = (arr = [], ...args) => {
  if (!Array.isArray(args)) {
    return arr
  }

  args.forEach((value) => {
    if (value) {
      arr.push(value)
    }
  })

  return arr
}

export const getFullName = (userData: {firstName: string, lastName: string}, defaultValue = '-') =>
  userData ? `${userData.firstName} ${userData.lastName}` : defaultValue

export const getMerchantStatus = (merchant) => {
  if (!merchant.status) {
    return merchantStatuses.rejected.key
  }
  if (merchant.isDisabled) {
    return merchantStatuses.disabled.key
  }
  return merchant.status
}

export const getUserStatus = (user) => {
  if (user.isDisabled) {
    return userStatuses.disabled.key
  }
  return user.status
}

export const getVoucherNumberOfRenewals = (
  endDate,
  startDate = new Date()
) => {
  if (isNaN(endDate.getTime())) {
    return 0
  }

  const monthDifference = dayjs([
    endDate.getUTCFullYear(),
    endDate.getUTCMonth(),
  ]).diff(
    dayjs([
      startDate.getUTCFullYear(),
      startDate.getUTCMonth(),
    ]),
    'months',
  )

  return monthDifference > 0 ? Math.floor(monthDifference) : 0
}

export const groupBy = <T, G>(objArray: T[], key: string) => {
  return objArray.reduce<G>((acc, obj) => {
    (acc[obj[key]] = acc[obj[key]] || []).push(obj)
    return acc
  }, {} as G)
}

export const getPaginationTotalText = (total, range) =>
  `${range[0]}-${range[1]} of ${total} items`

export const numberInputFormatter = value => value.replace(/\B(?=(\d{3})+(?!\d))/gu, ',')

export const numberInputParser = value => value.replace(/\$\s?|(,*)/gu, '')

export const stringWithLeadingZeroes = (input, stringLength = 8) => input.toString().padStart(stringLength, 0)

export const capitalizeFirstLetter = ([first, ...rest]: string, locale = navigator.language) => !first ? '' : first.toLocaleUpperCase(locale) + rest.join('')

export const isUuidV4 = value => (/^[0-9(a-f|A-F)]{8}-[0-9(a-f|A-F)]{4}-4[0-9(a-f|A-F)]{3}-[89ab][0-9(a-f|A-F)]{3}-[0-9(a-f|A-F)]{12}$/u).test(value)

export const replaceElementById = (array, newElement, id) => {
  const newArray = [...array]
  const index = newArray.findIndex(item => id === item.id)
  newArray.splice(index, 1, newElement)

  return newArray
}

export const getFileNameFromHeaders = (headers) => {
  const contentDisposition = headers['content-disposition']
  if (!contentDisposition) {
    return
  }

  let filename = '';
  if (contentDisposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/u;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches !== null && matches[1]) {
      filename = matches[1].replace(/['"]/gu, '');
    }
  }

  return filename
}
