import { useState, useEffect, useCallback, useMemo, useRef, HTMLAttributes } from 'react'
import {
  Popconfirm,
  Form,
  Table,
  Tooltip,
  message,
} from 'antd'
import { IconPencil, IconX, IconCheck } from '@tabler/icons-react'
import './styles.scss'
import EditableCell from '../../atoms/editable-table-cell'
import FormItem from '../../atoms/ant-form-item'
import Card from '../../atoms/card'
import DropdownSelector from '../../atoms/dropdown-selector'
import IconButton from '../../atoms/icon-button'
import EditableImageWithText from '../../molecules/editable-image-with-text'
import NutritionalValue from '../../molecules/nutritional-value'
import { nutritionalValues } from '../../molecules/nutritional-value/nutritionalValues'
import ContentWithAction from '../../templates/content-with-action'
import { getImageTypeNotSupportedMessage, isImageTypePdfCompatible } from '../../../services/helpers/images'
import { replaceElementById } from '../../../services/helpers/helpers'
import {
  addGlobalItem,
  updateGlobalItem,
  getGlobalItems,
} from '../../../services/api/item.api'
import { Item } from '../../../types/item'
import { ColumnType } from 'antd/es/table'
import { EditableColumn } from '../../../types/table'

const ItemsManagement = () => {
  const [globalItems, setGlobalItems] = useState<Item[]>([])
  const [activeItem, setActiveItem] = useState<Item>({})
  const [file, setFile] = useState(null)
  const [form] = Form.useForm()
  const newItemRowRef = useRef<HTMLDivElement|null>(null)

  useEffect(() => {
    getGlobalItems().then(items => setGlobalItems(items))
  }, [])

  useEffect(() => {
    if (globalItems && globalItems[globalItems.length - 1]?.isNew) {
      newItemRowRef.current.scrollIntoView(true)
    }
  }, [globalItems])

  const refreshGlobalItems = useCallback(async () => {
    const newGlobalItems = await getGlobalItems()
    setGlobalItems(newGlobalItems)
  }, [])

  const isItemEdited = useCallback(
    itemId => itemId === activeItem.id,
    [activeItem]
  )

  const edit = useCallback(
    (item) => {
      form.setFieldsValue({
        name: '',
        description: '',
        ...item,
      })
      setActiveItem(item)
    },
    [form]
  )

  const cancel = useCallback(() => {
    if (!activeItem.id) {
      setGlobalItems(globalItems.slice(0, -1))
    }
    setActiveItem({})
    setFile(null)
  }, [activeItem, globalItems])

  const addItem = useCallback(
    async (itemData) => {
      const formData = new FormData()
      formData.append('name', itemData.name)
      formData.append('description', itemData.description)
      formData.append('image', file)
      formData.append('nutritionalValue', itemData.nutritionalValue)

      const newItems = [...globalItems]
      const newGlobalItemRes = await addGlobalItem(formData)
      if (newGlobalItemRes.hasErrors) {
        throw new Error('Could not add the item')
      }
      newItems[newItems.length - 1] = newGlobalItemRes

      setGlobalItems(newItems)
      message.success('The item has been added successfully')
    },
    [file, globalItems]
  )

  const updateItem = useCallback(
    async (itemData) => {
      const formData = new FormData()
      const originalItem = globalItems.find(item => item.id === itemData.id)
      if (originalItem.name !== itemData.name) {
        formData.append('name', itemData.name)
      }

      if (file) {
        formData.append('image', file)
      }

      if (itemData.description !== originalItem.description) {
        formData.append('description', itemData.description)
      }

      if (itemData.nutritionalValue !== originalItem.nutritionalValue) {
        formData.append('nutritionalValue', itemData.nutritionalValue)
      }

      const updatedGlobalItemRes = await updateGlobalItem({ id: itemData.id, formData, })
      if (updatedGlobalItemRes.hasErrors) {
        throw new Error('Could not update the item')
      }

      setGlobalItems(replaceElementById(globalItems, updatedGlobalItemRes, itemData.id))
      message.success('The item has been updated successfully')
    },
    [file, globalItems]
  )

  const save = useCallback(
    async () => {
      try {
        const tableRowData = await form.validateFields()
        const itemData = {
          ...tableRowData,
          id: activeItem.id,
        }

        if (!activeItem.image) {
          throw new Error('Please select an image for the new item')
        }

        itemData.id
          ? await updateItem(itemData)
          : await addItem(itemData)

        setActiveItem({})
        await refreshGlobalItems()
        setFile(null)
      } catch (e) {
        message.error(e.message)
      }
    },
    [form, activeItem, updateItem, addItem, refreshGlobalItems]
  )

  const editableFields = useMemo(() => {
    return {
      name: (
        <>
          <EditableImageWithText
            isImageEditable
            isTextEditable
            imageFile={file}
            currentImage={activeItem.image}
            beforeUpload={(imageFile) => {
              if (!isImageTypePdfCompatible(imageFile.type)) {
                message.error(getImageTypeNotSupportedMessage(imageFile.name));
                return false;
              }

              setActiveItem({
                ...activeItem,
                image: URL.createObjectURL(imageFile),
              })
              setFile(imageFile)
              return false
            }}
            title={{
              formFieldTitle: 'Item name',
              formPropertyName: 'name',
              onInputChange: (e) => {
                setActiveItem({
                  ...activeItem,
                  name: e.target.value,
                })
              },
            }}
            subtitle={{
              formFieldTitle: 'Item description',
              formPropertyName: 'description',
              onInputChange: (e) => {
                setActiveItem({
                  ...activeItem,
                  description: e.target.value,
                })
              },
            }}
          />
        </>
      ),
      nutritionalValue: (
        <FormItem
          propertyName="nutritionalValue"
          fieldTitle="Nutritional value"
          required={false}
        >
          <DropdownSelector
            placeholder="Nutritional value"
            items={Object.keys(nutritionalValues).map(
              key => nutritionalValues[key]
            )}
            onClick={(nutritionalValue) => {
              form.setFields([
                {
                  name: 'nutritionalValue',
                  value:
                    nutritionalValue === nutritionalValues.notApplicable.key
                      ? ''
                      : nutritionalValue,
                },
              ])
              setActiveItem({
                ...activeItem,
                nutritionalValue,
              })
            }}
          >
            {activeItem.nutritionalValue && (
              <NutritionalValue value={activeItem.nutritionalValue} />
            )}
          </DropdownSelector>
        </FormItem>
      ),
    }
  }, [activeItem, file, form])

  const tableColumns = useMemo(() => {
    const columns: (ColumnType<Item> & {editable?: boolean})[] = [
      {
        title: 'Item name',
        key: 'name',
        dataIndex: 'name',
        editable: true,
        ellipsis: true,
        render: (text, record) => (
          <>
            <EditableImageWithText
              currentImage={record.image}
              title={{ value: text }}
              subtitle={{ value: record.description }}
            />
          </>
        ),
      },
      {
        title: 'Nutritional value',
        key: 'nutritionalValue',
        dataIndex: 'nutritionalValue',
        width: '200px',
        editable: true,
        align: 'center',
        render: text => <NutritionalValue value={text} />,
      },
      {
        title: 'Actions',
        key: 'operation',
        dataIndex: 'operation',
        width: '90px',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="items-management__action-buttons">
              {isItemEdited(record.id) ? (
                <>
                  <Form.Item>
                    <Tooltip title="Cancel">
                      <Popconfirm
                        title="Cancel editing?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={cancel}
                      >
                        <IconButton
                          shape="circle"
                          icon={IconX}
                        />
                      </Popconfirm>
                    </Tooltip>
                  </Form.Item>

                  <Form.Item>
                    <Tooltip title="Save">
                      <IconButton
                        htmlType="submit"
                        shape="circle"
                        type="primary"
                        icon={IconCheck}
                      />
                    </Tooltip>
                  </Form.Item>
                </>
              ) : (
                <Tooltip title="Edit">
                  <IconButton
                    ghost={false}
                    shape="circle"
                    icon={IconPencil}
                    disabled={!!(activeItem.id || activeItem.id === null)}
                    onClick={() => edit(record)}
                  />
                </Tooltip>
              )}
            </div>
          )
        },
      },
    ]

    return columns.map<EditableColumn<Item>>((column) => {
      if (!column.editable) {
        return column
      }

      return {
        // onCell
        ...column,
        onCell: (record: Item) => {
          return {
            record,
            dataIndex: column.dataIndex,
            title: column.title as string,
            isInEditMode: record.id === activeItem.id,
            cellContent: editableFields[column.key],
          }
        }
      }
    })
  }, [activeItem, editableFields, isItemEdited, cancel, edit])

  return (
    <div className="items-management">
      <Card>
        <ContentWithAction
          actionButtonLabel="Add item"
          actionButtonProps={{
            disabled: Object.keys(activeItem).length !== 0,
            onClick: async () => {
              const newItem = {
                key: globalItems.length,
                isNew: true,
              }
              form.setFieldsValue({
                name: '',
                image: '',
                description: '',
                nutritionalValue: '',
                ...newItem,
              })
              setActiveItem(newItem)
              setGlobalItems([...globalItems, newItem])
            }
          }}
        >
          <Form
            form={form}
            onFinish={save}
            initialValues={Object.keys(activeItem).length ? activeItem : null}
          >
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              onRow={(_, index) => {
                if (index === globalItems.length - 1 && activeItem.isNew) {
                  return {
                    ref: newItemRowRef,
                  } as HTMLAttributes<HTMLDivElement>
                }
              }}
              bordered
              dataSource={globalItems}
              columns={tableColumns}
              rowClassName="items-management__editable-row"
              rowKey={record => record.id || globalItems.length}
              pagination={false}
            />
          </Form>
        </ContentWithAction>
      </Card>
    </div>
  )
}

export default ItemsManagement
