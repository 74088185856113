import {
  SET_USER_DETAILS,
  SET_USER_ROLE,
  SET_IS_LOGGED_IN,
  SET_IS_INITIAL_USER_REQUEST_DONE,
  RESET_STATE,
} from './user.types.js'

export const setUserDetails = payload => ({
  type: SET_USER_DETAILS,
  payload,
})

export const setUserRole = payload => ({
  type: SET_USER_ROLE,
  payload,
})

export const setIsLoggedIn = payload => ({
  type: SET_IS_LOGGED_IN,
  payload,
})

export const setIsInitialRequestDone = payload => ({
  type: SET_IS_INITIAL_USER_REQUEST_DONE,
  payload,
})

export const resetUserState = () => ({
  type: RESET_STATE,
  payload: null,
})
