import { useLocation } from 'react-router-dom'
import './styles.scss'
import Tabs from '../../atoms/tabs'
import MerchantList from '../../organisms/merchant-list'
import MerchantInvitesList from '../../organisms/merchant-invites-list'

const Merchants = () => {
  const [query] = useLocation().state || []

  const tabsContent = [
    {
      title: 'Enrolled',
      component: <MerchantList presetQuery={query} />,
    },
    {
      title: 'Invited',
      component: <MerchantInvitesList />,
    }
  ]

  return (
    <div>
      <Tabs tabsContent={tabsContent} />
    </div>
  )
}

export default Merchants
