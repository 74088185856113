import { Query } from '../../hooks/query.hook'
import { TransactionRaw } from '../../types/transactions'
import { ApiResponseData, axiosApi } from './api'

export const getProgrammeTransactions = async (programmeId: string, params: Query) => {
  const { data } = await axiosApi()
    .get<ApiResponseData<{count: number, rows: TransactionRaw[]}>>(
      `/programmes/${programmeId}/transactions`,
      { params }
    )
  return data
}

export const getMerchantTransactions = async (
  programmeId: string,
  merchantId: string,
  params: Query
) => {
  const { data } = await axiosApi()
    .get<ApiResponseData<{ count: number, rows: TransactionRaw[] }>>(
      `/programmes/${programmeId}/merchants/${merchantId}/transactions`,
      { params }
    )
  return data
}
export const setTransactionProcessedState = async (transactionId, payload) => {
  const { data } = await axiosApi()
    .post(
      `/transaction/${transactionId}/process`,
      payload
    )
  return data
}

export const getTransactionsCsv = async (programmeId, params) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/transactions/download`,
      {
        params,
        headers: { accept: 'application/octet-stream' },
        responseType: 'blob',
      }
    )
  return data
}

export const getMerchantTransactionsCsv = async (
  programmeId,
  merchantId,
  params
) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/merchants/${merchantId}/transactions/download`,
      {
        params,
        headers: { accept: 'application/octet-stream' },
        responseType: 'blob',
      }
    )
  return data
}

export const getMerchantPaymentFileCsv = async (
  programmeId,
  merchantId,
  params
) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/merchants/${merchantId}/paymentFile/download`,
      {
        params,
        headers: { accept: 'application/octet-stream' },
        responseType: 'blob',
      }
    )
  return data
}

export const getAdminTransactions = async (params) => {
  const { data } = await axiosApi()
    .get(
      '/programmes/transactions/detailed',
      { params }
    )
  return data
}
