import { FC } from 'react'
import { Modal } from 'antd';
import './styles.scss'
import TransactionDetails from './transaction-details'
import { Transaction } from '../../../types/transactions';


const TransactionDetailsModal: FC<{
  isModalOpen: boolean,
  setIsModalOpen: (isModalOpen: boolean) => void,
  transaction: Transaction,
}> = ({
  isModalOpen,
  setIsModalOpen,
  transaction,
}) => (
  <Modal
    title={transaction?.type}
    className="transaction-details-modal"
    open={isModalOpen}
    closable
    destroyOnClose
    footer={null}
    onCancel={() => setIsModalOpen(false)}
    width={700}
  >
    {transaction && (
      <TransactionDetails
        date={transaction.date}
        amount={transaction.amount}
        fieldworkerName={transaction.fieldworker}
        merchantName={transaction.merchant}
        programmeName={transaction.programmeName}
        voucherIndex={transaction.voucherIndex}
        productsTableData={transaction.products}
        householdNumber={transaction.householdNumber}
      />
    )}
  </Modal>
)

export default TransactionDetailsModal
