import { useState, useEffect, FC } from 'react'
import './styles.scss'
import InfoBox, { InfoBoxProps } from '../../atoms/info-box'
import { Descriptions } from 'antd'
import { Merchant } from '../../../types/user'

const MerchantUserDetails: FC<{
  merchantDetails: Merchant,
  infoBoxProps: InfoBoxProps
}> = ({ merchantDetails, infoBoxProps }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (merchantDetails) {
      const { CreatedBy: { firstName, lastName, phone, email } } = merchantDetails
      setFirstName(firstName)
      setLastName(lastName)
      setPhoneNumber(phone)
      setEmail(email)
    }
  }, [merchantDetails])

  return (
    <div className="merchant-user-details">
      <div className="first-column">
        <Descriptions
          column={1}
          layout="vertical"
          colon={null}
        >
          <Descriptions.Item label="First Name">
            {firstName || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Last Name">
            {lastName || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {phoneNumber || '–'}
          </Descriptions.Item>
          <Descriptions.Item label="Email Address">
            {email || '–'}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="second-column">
        <div className="info-box-container">
          <InfoBox {...infoBoxProps} />
        </div>
      </div>
    </div>
  )
}

export default MerchantUserDetails
