import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Input, message, Space, Table } from 'antd'
import dayjs from 'dayjs'
import './styles.scss'
import { getMerchantInvites as getMerchantInvitesApiRequest } from '../../../services/api/merchant.api'
import { merchantStatuses } from '../../../constants/merchant.constants'
import { SortDirections, useQuery } from '../../../hooks/query.hook'
import StatusPill from '../../atoms/status-pill'
import InviteMerchantAction from './inviteMerchantAction'
import ReinviteUser from './reinviteUser'
import { getPaginationTotalText } from '../../../services/helpers/helpers'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'

const { Search } = Input

const MerchantInvitesList = () => {
  const [merchantInvitesCount, setMerchantInvitesCount] = useState(0)
  const [merchantInvites, setMerchantInvites] = useState([])
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  const { query, onPageChange, onSearch } = useQuery({
    orderType: SortDirections.descend,
    pageSize: 10,
    page: 1,
  })

  const tableColumns = [
    {
      title: 'E-mail address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Sent at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: updatedAt => dayjs(updatedAt).calendar(),
    },
    {
      title: 'Status',
      dataIndex: 'isPending',
      key: 'status',
      render: (isPending, { email }) =>
        isPending ? (
          <Space>
            <StatusPill statusData={merchantStatuses.invited} />
            <ReinviteUser
              email={email}
              programmeId={activeProgramme.id}
            />
          </Space>
        ) : (
          <StatusPill statusData={merchantStatuses.accepted} />
        ),
    },
  ]

  const getMerchantInvites = useCallback(async() => {
    if (!activeProgramme?.id) {
      return
    }

    const res = await getMerchantInvitesApiRequest(activeProgramme.id)
    if (res.hasErrors) {
      message.error('Could not get the supplier invites')
      return
    }
    setMerchantInvites(res.rows)
    setMerchantInvitesCount(res.count)
  }, [activeProgramme])

  useEffect(() => {
    getMerchantInvites()
  }, [getMerchantInvites])

  return (
    <div className="merchant-invites-list">
      <div className="merchant-actions">
        <div className="search-wrapper">
          <Search
            allowClear
            placeholder="Search"
            size="large"
            onSearch={value => onSearch(value, 'email')}
          />
        </div>

        <InviteMerchantAction
          programmeId={activeProgramme?.id}
          onSuccess={getMerchantInvites}
        />
      </div>

      <Table
        dataSource={merchantInvites}
        columns={tableColumns}
        bordered
        rowKey="id"
        pagination={{
          onChange: onPageChange,
          total: merchantInvitesCount,
          pageSize: query.pageSize,
          showTotal: getPaginationTotalText,
          current: query.page,
        }}
      />
    </div>
  )
}

export default MerchantInvitesList
