import { Query } from '../../hooks/query.hook'
import { ItemsWithCount } from '../../types/generic'
import { Household } from '../../types/household'
import { BaseProgramme, Programme } from '../../types/programme'
import { ApiResponseData, axiosApi } from './api'

export const getProgrammes = async (params?) => {
  const res = await axiosApi()
    .get(
      '/programmes',
      { params }
    )
  return res.data
}

export const getProgrammeDetails = async (programmeId) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}`
    )
  return data
}

export const addProgrammes = async (payload: BaseProgramme) => {
  const { data } = await axiosApi()
    .post(
      '/programmes',
      payload
    )
  return data
}

export const updateProgramme = async (programmeId, payload) => {
  const { data } = await axiosApi()
    .patch<ApiResponseData<Programme>>(
      `/programmes/${programmeId}`,
      payload
    )
  return data
}

export const assignManager = async (programmeId, managerId) => {
  const { data } = await axiosApi()
    .post(
      `/programmes/${programmeId}/manager/${managerId}`
    )
  return data
}

export const updateManager = async (programmeId, managerId) => {
  const { data } = await axiosApi()
    .put(
      `/programmes/${programmeId}/manager/${managerId}`
    )
  return data
}

export const disableProgramme = async (programmeId) => {
  const { data } = await axiosApi()
    .patch(
      `/programmes/${programmeId}/disable`
    )
  return data
}

export const getProgrammeHouseholds = async (programmeId: string, query?: Query) => {
  const { data } = await axiosApi()
    .get<ApiResponseData<ItemsWithCount<Household>>>(
      `/programmes/${programmeId}/households`,
      { params: query }
    )

  return data
}

export const getProgrammeHouseholdStatistics = async (programmeId) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/statistics/household`
    )
  return data
}

export const getSoldItemsStatistics = async (programmeId) => {
  const { data } = await axiosApi()
    .get(
      `/programmes/${programmeId}/statistics/items/sold`
    )
  return data
}

export const enableProgrammeRedemptions = async (programmeId) => {
  const { data } = await axiosApi()
    .put(
      `/programmes/${programmeId}/redemptions/enable`
    )
  return data
}

export const disableProgrammeRedemptions = async (programmeId) => {
  const { data } = await axiosApi()
    .put(
      `/programmes/${programmeId}/redemptions/disable`
    )
  return data
}

export const setGpsCoordinatesCapturing = async (programmeId, isEnrollment, isEnabled) => {
  const { data } = await axiosApi()
    .put(
      `/programmes/${programmeId}/gps/${isEnrollment ? 'enrollment' : 'redemption'}/${isEnabled ? 'enable' : 'disable'}`
    )
  return data
}

export const setL20LogoVisibility = async (programmeId, isEnabled) => {
  const { data } = await axiosApi()
    .put(
      `/programmes/${programmeId}/logo/l20/${isEnabled ? 'enable' : 'disable'}`
    )
  return data
}

export const setNgoLogo = async (programmeId, formData) => {
  const { data } = await axiosApi()
    .post(
      `/programmes/${programmeId}/logo/ngo/upload`,
      formData
    )
  return data
}

export const removeNgoLogo = async (programmeId) => {
  const { data } = await axiosApi()
    .delete(
      `/programmes/${programmeId}/logo/ngo`
    )
  return data
}
