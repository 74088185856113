import { useCallback, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { saveAs } from 'file-saver'
import { Button, Descriptions, message, Modal, Typography } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import './styles.scss'
import { disableVoucherBatch, getVoucherBatchPDF, transferVoucherBatch, updateVoucherBatch } from '../../../services/api/voucher.api'
import { voucherBatchRenewalTypes, voucherBatchStatuses } from '../../../constants/voucherBatch.constants'
import StatusPill from '../../atoms/status-pill'
import { VoucherBatchContext } from '../../views/VoucherBatchDetails'
import EditableUser from '../../molecules/editable-fields/editable-user'
import EditableDatepicker from '../../molecules/editable-fields/editable-datepicker'
import StatusCard from './status-card'
import { voucherStatuses } from '../../../constants/voucher.constants'
import { getFieldworkerPickerOptions } from '../../atoms/select-user/optionsMappers'
import { apiRequestDateFormat } from '../../../constants/general'
import { getInvalidRangePickerDates } from '../../../services/helpers/date'
import { getByKey } from '../../../services/helpers/constants'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'

const { Text, Title, Paragraph } = Typography
const { Item } = Descriptions

const VoucherBatchDetailsForm = () => {
  const { voucherBatch, setVoucherBatch, getVoucherBatch } = useContext(VoucherBatchContext)
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  useEffect(() => {
    getVoucherBatch()
  }, [getVoucherBatch])

  const onSaveFieldworker = useCallback(
    async (selectedFieldworkerId) => {
      const res = await transferVoucherBatch(
        activeProgramme.id,
        voucherBatch.id,
        selectedFieldworkerId
      )
      if (res.hasErrors) {
        message.error('Failed to reassign the certificate batch')
        return
      }
      message.success('Successfully reassigned the certificate batch')
      setVoucherBatch(res)
    },
    [activeProgramme, setVoucherBatch, voucherBatch]
  )

  const onSaveDates = useCallback(
    async ({ startDate, endDate }) => {
      const res = await updateVoucherBatch(
        activeProgramme.id,
        voucherBatch.id,
        {
          startDate: startDate.format(apiRequestDateFormat),
          endDate: endDate.format(apiRequestDateFormat),
        }
      )

      if (res.hasErrors) {
        message.error('Failed to change the dates of the certificate batch.')
        return
      }
      message.success('Successfully changed the certificate batch dates.')
      setVoucherBatch(res)
    },
    [activeProgramme, setVoucherBatch, voucherBatch]
  )

  const onDisableVoucherBatch = useCallback(
    () => {
      Modal.confirm({
        title: 'Warning',
        okText: 'Yes',
        cancelText: 'No',
        onOk: async () => {
          const res = await disableVoucherBatch(
            activeProgramme.id,
            voucherBatch.id
          )
          if (res.hasErrors) {
            return
          }
          setVoucherBatch(res)
        },
        content: (
          <>
            <Paragraph>If you disable this batch of certificates, the available certificates cannot be assigned to beneficiaries anymore, and the ones that are assigned can no longer be redeemed.</Paragraph>
            <Paragraph strong>Are you sure you want to disable the certificates batch?</Paragraph>
          </>
        ),
      })
    },
    [activeProgramme, setVoucherBatch, voucherBatch]
  )

  const downloadPdf = useCallback(
    async (e) => {
      e.preventDefault()
      const res = await getVoucherBatchPDF(activeProgramme.id, voucherBatch.id)
      saveAs(
        res,
        `${voucherBatch.issuedTo} - ${activeProgramme.name} - ${voucherBatch.fixedValue}${voucherBatch.currency}.pdf`
      )
    },
    [activeProgramme, voucherBatch]
  )

  return (
    <div className="voucher-batch-details__wrapper">
      <div className="voucher-batch-details">
        <Descriptions column={1}>
          <Item label="Status">
            <StatusPill
              statusData={voucherBatchStatuses[voucherBatch.status]}
            />
          </Item>

          <Item label="Type">
            {voucherBatch.isRenewable ? 'Renewable' : 'Non-renewable'}
          </Item>

          <Item label="Issued to">
            <EditableUser
              userName={voucherBatch.issuedTo}
              userId={voucherBatch.fieldworkerId}
              isEditable
              onSave={onSaveFieldworker}
              getOptions={searchText => getFieldworkerPickerOptions(searchText, activeProgramme?.id)}
            />
          </Item>

          {voucherBatch.startDate && voucherBatch.endDate && (
            <Item label="Validity period">
              <EditableDatepicker
                startDate={dayjs.utc(voucherBatch.startDate)}
                endDate={dayjs.utc(voucherBatch.endDate)}
                onSave={onSaveDates}
                rangePickerDateDisabledDate={(date: Dayjs) =>
                  (activeProgramme?.startDate || activeProgramme?.endDate)
                    ? getInvalidRangePickerDates(date, activeProgramme.startDate, activeProgramme.endDate)
                    : false
                }
              />
            </Item>
          )}

          {voucherBatch.isRenewable && (
            <>
              <Item label="Renewal Date">
                1st day of every month
              </Item>

              <Item label="Renewal Type">
                {getByKey(voucherBatchRenewalTypes, voucherBatch.renewalType).label}
              </Item>
            </>
          )}
          <Item label="Number of certificates">
            {voucherBatch.totalVouchers}
          </Item>

          <Item label="Certificate value">
            {voucherBatch.fixedValue} {voucherBatch.currency}{' '}
            {voucherBatch.isRenewable && 'per month'}
          </Item>

          <Item label="Total batch value">
            {voucherBatch.totalValueAfterRenewals} {voucherBatch.currency}
          </Item>
        </Descriptions>

        {!!voucherBatch.usageStats?.length && (
          <>
            <Title level={4}>
              Certificates in this batch:
            </Title>
            <div className="certificates-status__container">
              {voucherBatch.isRenewable && (
                <StatusCard
                  title="Renewals"
                  mainData={`${voucherBatch.renewals.current}/${voucherBatch.renewals.total}`}
                  infoText="How many times this certificates batch has been renewed"
                  className="renewals-card"
                />
              )}
              {voucherBatch.usageStats.map((certificatesUsageStat) => {
                return (
                  <StatusCard
                    key={certificatesUsageStat.type}
                    type={certificatesUsageStat.type}
                    title={voucherStatuses[certificatesUsageStat.type].label}
                    infoText={voucherStatuses[certificatesUsageStat.type].description}
                    mainData={certificatesUsageStat.quantity}
                    secondaryData={certificatesUsageStat.monetaryValue}
                  />
                )
              })}
            </div>
          </>
        )}

        <section className="user-message__wrapper">
          <Title level={4}>
            Message for fieldworker:
          </Title>
          <Paragraph>
            {voucherBatch.fieldworkerMessage ? (
              <blockquote>{voucherBatch.fieldworkerMessage}</blockquote>
            ) : (
              <Text italic>No message.</Text>
            )}
          </Paragraph>
        </section>
        <section className="user-message__wrapper">
          <Title level={4}>Message for supplier:</Title>
          <Paragraph>
            {voucherBatch.merchantMessage ? (
              <blockquote>{voucherBatch.merchantMessage}</blockquote>
            ) : (
              <Text italic>No message.</Text>
            )}
          </Paragraph>
        </section>
        {voucherBatch.status === voucherBatchStatuses.active.key && (
          <div className="action-buttons">
            <Button
              type="primary"
              size="large"
              onClick={downloadPdf}
            >
              Download certificates
            </Button>
            <Button
              danger
              type="default"
              size="large"
              onClick={onDisableVoucherBatch}
            >
              Disable this batch of certificates
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default VoucherBatchDetailsForm
