import { BarDatum, BarTooltipProps, ResponsiveBar } from '@nivo/bar'
import { FC } from 'react'
import ChartTooltipContainer from '../../atoms/chart-tooltip-container'

const CustomTooltip: FC<BarTooltipProps<BarDatum>> = ({ value, data: { householdSize } }) => {
  const household = value === 1 ? 'household' : 'households'
  const person = householdSize === 1 ? 'person' : 'persons'

  return (
    <ChartTooltipContainer>
      <strong>
        {value} {household} with {householdSize} {person}
      </strong>
    </ChartTooltipContainer>
  )
}

const HouseholdSizeChart: FC<{data}> = ({ data }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={['value', 'filler']}
      indexBy="householdSize"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      theme={{ fontSize: 14 }}
      margin={{ bottom: 60, left: 60, right: 5, top: 40 }}
      padding={0.3}
      colors="#5923B1"
      borderRadius={3}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Household size',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        format: value => (Number.isInteger(value) ? value : ''),
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 8,
        legend: 'Households count',
        legendPosition: 'middle',
        legendOffset: -50,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['brighter', 5]],
      }}
      role="application"
      ariaLabel="Household size chart"
      barAriaLabel={function (e) {
        return (
          e.id + ': ' + e.formattedValue + ' in householdSize: ' + e.indexValue
        )
      }}
      tooltip={CustomTooltip}
    />
  )
}

export default HouseholdSizeChart
