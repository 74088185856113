import { FC } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { IconArrowNarrowRight } from '@tabler/icons-react'
import './styles.scss'

const DateIntervalReadonly: FC<{
  startDate: Dayjs
  endDate: Dayjs
}> = ({ startDate, endDate }) => (
  <div className="date-interval">
    {dayjs.utc(startDate).format('L')}
    {' '}<IconArrowNarrowRight />{' '}
    {dayjs.utc(endDate).format('L')}
  </div>
)

export default DateIntervalReadonly
