import { FC } from 'react'
import { Link, To } from 'react-router-dom'
import { Typography } from 'antd';
import { IconArrowLeft } from '@tabler/icons-react';
import './styles.scss'
import Icon from '../icon';

const PageBackLink: FC<{ url: To, pageName: string, state?: unknown }> = ({ url, pageName, state }) => (
  <div className="page-back-link">
    <Link
      to={url}
      state={state}
    >
      <div className="page-back-link__content">
        <Icon
          component={IconArrowLeft}
          componentProps={{
            height: '1.3rem',
            width: '1.3rem'
          }}
        />
        <Typography.Text>Back to {pageName}</Typography.Text>
      </div>
    </Link>
  </div>
)

export default PageBackLink
