import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar';
import arraySupport from 'dayjs/plugin/arraySupport';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en-gb';

export const intializeDayJs = () => {
  dayjs.extend(calendar)
  dayjs.extend(arraySupport)
  dayjs.extend(localizedFormat)
  dayjs.extend(minMax)
  dayjs.extend(utc)
  dayjs.locale('en-gb')
}
