import { FC, ReactNode } from 'react'
import { Button, ButtonProps } from 'antd'
import './styles.scss'
import Icon from '../icon'
import classNames from 'classnames'
import { TablerIconsProps } from '@tabler/icons-react'

const IconButton: FC<Omit<ButtonProps, 'icon'> & {
  icon: FC,
  iconProps?: TablerIconsProps,
  className?: string,
  children?: ReactNode,
  }
> = ({ icon, iconProps, className, children, ...buttonProps }) => {
  const size = iconProps?.size || '1rem'
  return (
    <Button
      className={classNames('icon-button', className)}
      icon={(
        <Icon
          component={icon}
          componentProps={{
            size,
            ...iconProps
          }}
        />
      )}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}

export default IconButton
