import { useSelector } from 'react-redux'
import './styles.scss'
import Tabs from '../../atoms/tabs'
import UserProfileForm from './user-profile-form'
import UserPasswordForm from './user-password-form'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { BaseUser } from '../../../types/user'

const UserProfile = () => {
  const userDetails = useSelector<CombinedReducers, BaseUser>(state => state.user.userDetails)

  const tabsContent = [
    {
      title: 'User Profile',
      component: <UserProfileForm user={userDetails} />,
    },
    {
      title: 'Change Password',
      component: <UserPasswordForm />,
    },
  ]

  return (
    <div className="user-profile-wrapper">
      <Tabs tabsContent={tabsContent} />
    </div>
  )
}

export default UserProfile
