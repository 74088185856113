import voucherBatchStatusColors from '../styles/statusColors/_voucherBatch.module.scss'
import { KeyLabel, Status } from '../types/generic'
import { VoucherBatchRenewalTypes } from '../types/voucher'

export type VoucherBatchStatus = {
  active: Status,
  disabled: Status,
}

export const voucherBatchStatuses: VoucherBatchStatus = {
  active: {
    key: 'active',
    label: 'active',
    color: voucherBatchStatusColors.active,
  },
  disabled: {
    key: 'disabled',
    label: 'disabled',
    color: voucherBatchStatusColors.disabled,
  },
}

export type VoucherBatchRenewalType = {
  monthly: KeyLabel,
  topUp: KeyLabel,
}

export const voucherBatchRenewalTypes: VoucherBatchRenewalType = {
  monthly: {
    key: VoucherBatchRenewalTypes.MONTHLY,
    label: 'Reset',
  },
  topUp: {
    key: VoucherBatchRenewalTypes.TOPUP,
    label: 'Top-up',
  },
}
