import { useParams } from 'react-router-dom'

import PageBackLink from '../../atoms/page-back-link'
import Tabs from '../../atoms/tabs'
import CreateUsersForm from '../../organisms/create-users-form'

const CreateUsers = () => {
  const { viewContext } = useParams()

  const tabsContent = [
    {
      title: 'Create New User',
      component: <CreateUsersForm />,
    },
  ]

  return (
    <div className="create-users">
      <PageBackLink
        url={`/${viewContext}/users`}
        pageName="List"
      />

      <Tabs tabsContent={tabsContent} />
    </div>
  )
}

export default CreateUsers
