import dayjs from 'dayjs'
import { mapProductTransactions, mapTransactionType } from '../../components/molecules/transaction-table/transaction-table.config'
import { Transaction, TransactionRaw } from '../../types/transactions'
import { getFullName } from './helpers'

export const mapTransaction: (transaction: TransactionRaw) => Transaction = (transaction) => {
  return {
    id: transaction.id,
    date: dayjs(transaction.createdAt).format('L'),
    type: mapTransactionType(transaction.type),
    amount: `${transaction.value} ${transaction.currency}`,
    fieldworker: getFullName({
      firstName: transaction.fieldWorkerFirstName,
      lastName: transaction.fieldWorkerLastName,
    }),
    fieldworkerId: transaction.FieldWorkerId,
    merchant: transaction.merchantName,
    projectManager: getFullName({
      firstName: transaction.programmeManagerFirstName,
      lastName: transaction.programmeManagerLastName,
    }),
    programmeName: `${transaction.Programme.name}`,
    programmeIdentifier: `${transaction.Programme.id}`,
    products: mapProductTransactions(
      transaction.MerchantTransactions
    ),
    voucherIndex: transaction.voucherIndex,
    householdNumber: transaction.householdNumber,
  }
}
