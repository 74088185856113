import { useMemo, useState } from 'react'
import { Menu } from 'antd'
import { NavLink, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './styles.scss'
import Logo from '../../atoms/logo'
import UserDetails from './components/user-details'
import ProgrammeSelector from './components/programme-selector'
import SupportSection from './components/support-section'
import { getMenuItems } from './menu-items'
import { ViewModes } from '../../../constants/app.constants'
import Icon from '../../atoms/icon'
import { CombinedReducers } from '../../../redux/stores/reducers'

const Sidebar = () => {
  const [activeMenuItemKey, setActiveMenuItemKey] = useState(null)

  const { viewContext, } = useParams()
  const viewMode = useSelector<CombinedReducers, ViewModes>(state => state.application.viewMode)

  const menuItems = useMemo(() => {
    return getMenuItems(viewContext ? viewMode : ViewModes.noProgramme)
      .map(item => ({
        key: item.url,
        icon: item.icon && (
          <Icon
            component={item.icon}
            componentProps={{ stroke: 1.5 }}
          />
        ),
        label: (
          <NavLink
            to={`${viewContext || ''}${item.url}`}
            end={item.url === ''}
            key={item.url}
            className={({ isActive }) => {
              if (isActive) {
                setActiveMenuItemKey(item.url)
              }
              return ''
            }}
          >
            {item.title}
          </NavLink>
        )
      }))
  },
  [viewContext, viewMode])

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <Logo />
      </div>

      <ProgrammeSelector />

      <section className="sidebar-content">
        <Menu
          mode="inline"
          items={menuItems}
          selectedKeys={[activeMenuItemKey]}
        />
        <SupportSection />
      </section>

      <div className="bottom-elements">
        <UserDetails />
      </div>
    </div>
  )
}

export default Sidebar
