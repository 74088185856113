import { SET_VIEW_MODE, RESET_STATE } from './app.types.js'

export const setViewMode = payload => ({
  type: SET_VIEW_MODE,
  payload,
})

export const resetAppState = () => ({
  type: RESET_STATE,
  payload: null,
})
