import withIconWrapper from './with-icon-wrapper'

const NutritionalValueLowSvg = withIconWrapper(() => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66671 2L6.66671 14L9.33337 14L9.33337 2L6.66671 2Z"
      fill="#D73939"
    />
  </svg>
))

export default NutritionalValueLowSvg
