import { useState, useEffect, useCallback, useMemo, FC } from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { DatePicker, Button, message } from 'antd'
import { ColumnsType } from 'antd/es/table'
import './styles.scss'
import TransactionTable from '../../molecules/transaction-table'
import { serverDateToString } from '../../../services/helpers/date'
import {
  mapIsProcessed,
  mapActions,
  defaultQuery
} from '../../molecules/transaction-table/transaction-table.config'
import {
  getMerchantTransactions,
  setTransactionProcessedState,
  getMerchantTransactionsCsv,
  getMerchantPaymentFileCsv,
} from '../../../services/api/transaction.api'
import { useQuery } from '../../../hooks/query.hook'
import TransactionDetailsModal from '../../molecules/transaction-details-modal'
import InfoSvg from '../../atoms/icons/info-svg'
import { isAdmin } from '../../../services/helpers/user'
import { mapTransaction } from '../../../services/helpers/transaction'
import { handleSorting } from '../../../services/helpers/table'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'
import { UserRoles } from '../../../constants/userRoles.constants'
import { rangePickerDateFormat } from '../../../constants/general'

const { RangePicker } = DatePicker

const TransactionList: FC<{ merchantId: string, merchantName: string }> = ({ merchantId, merchantName }) => {
  const defaultFrom = dayjs.utc().subtract(7, 'days').startOf('day')
  const defaultTo = dayjs.utc().endOf('day')
  const { query, onPageChange, onFilters, onSort } = useQuery({
    ...defaultQuery,
    from: defaultFrom.valueOf(),
    to: defaultTo.valueOf(),
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [transactionsCount, setTransactionsCount] = useState(null)
  const [currentTransaction, setCurrentTransaction] = useState(null)
  const { activeProgramme, currentUserRoles } = useSelector<CombinedReducers, { activeProgramme: Programme, currentUserRoles: UserRoles[]}>(state => ({
    activeProgramme: state.programme.activeProgramme,
    currentUserRoles: state.user.userRoles,
  }))
  const navigate = useNavigate()

  const getTransactions = useCallback(async () => {
    const onProcessItem = async (transactionId, isProcessed) => {
      await setTransactionProcessedState(transactionId, { isProcessed })
      await getTransactions()
    }

    const transactionsResponse = await getMerchantTransactions(
      activeProgramme?.id,
      merchantId,
      query
    )
    if (!transactionsResponse.hasErrors && transactionsResponse.rows) {
      const mappedTransactions = transactionsResponse.rows.map(
        (transaction) => {
          const mappedTransaction = mapTransaction(transaction)

          if (isAdmin(currentUserRoles)) {
            mappedTransaction.processed = mapIsProcessed(
              transaction.isProcessed,
              transaction.id,
              onProcessItem
            )
          }

          mappedTransaction.details = mapActions([
            {
              onClick: () => {
                setCurrentTransaction({
                  ...mappedTransaction,
                  date: dayjs(transaction.createdAt).format('LLL'),
                })
                setIsModalOpen(true)
              },
              icon: <InfoSvg />,
            },
          ])

          return mappedTransaction
        }
      )
      setTransactions(mappedTransactions)

      if (transactionsResponse.count !== transactionsCount) {
        setTransactionsCount(transactionsResponse.count || null)
      }
    }
  }, [activeProgramme, currentUserRoles, merchantId, query, transactionsCount])

  const handleDownloadClick = useCallback(async () => {
    try {
      const {
        page: _page,
        pageSize: _pageSize,
        ...queryParams
      } = query

      const response = await getMerchantTransactionsCsv(
        activeProgramme?.id,
        merchantId,
        queryParams
      )

      const fromString = serverDateToString(query.from, 'DD.MM.YYYY')
      const toString = serverDateToString(query.to, 'DD.MM.YYYY')
      const fileName = `${merchantName} - Transactions ${fromString}-${toString}.csv`
      saveAs(response, fileName)
    } catch (err) {
      message.error('There was an error downloading the report.')
    }
  }, [activeProgramme, merchantId, merchantName, query])

  const handleDownloadPaymentFileClick = useCallback(async () => {
    try {
      const {
        page: _page,
        pageSize: _pageSize,
        ...queryParams
      } = query

      const response = await getMerchantPaymentFileCsv(
        activeProgramme?.id,
        merchantId,
        queryParams
      )
      const fromString = serverDateToString(query.from, 'DD.MM.YYYY')
      const toString = serverDateToString(query.to, 'DD.MM.YYYY')
      const fileName = `${merchantName} - Payment ${fromString}-${toString}.csv`
      saveAs(response, fileName)
    } catch (err) {
      message.error('There was an error downloading the payment file.')
    }
  }, [activeProgramme, merchantId, merchantName, query])

  const columns = useMemo(() => {
    const cols: ColumnsType = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'createdAt',
        sorter: true,
        defaultSortOrder: 'descend',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'value',
        sorter: true,
      },
      {
        dataIndex: 'details',
        key: 'details',
      },
    ]

    if (isAdmin(currentUserRoles)) {
      cols.push(
        {
          title: 'Processed',
          dataIndex: 'processed',
          key: 'processed',
        },
      )
    }

    return cols
  }, [currentUserRoles])

  useEffect(() => {
    if (!activeProgramme?.id) {
      return navigate('/')
    }

    getTransactions()
  }, [activeProgramme, getTransactions, navigate])

  const onTableChange = useCallback(
    (_, __, sorter, extra) => {
      handleSorting(onSort, extra.action, sorter, defaultQuery)
    },
    [onSort],
  )

  return (
    <div className="merchant-transactions-container">
      <TransactionTable
        presetQuery={query}
        transactions={transactions}
        transactionsCount={transactionsCount}
        onPageChange={onPageChange}
        columns={columns}
        onTableChange={onTableChange}
      >
        <div className="transactions-actions-container">
          <RangePicker
            format={rangePickerDateFormat}
            defaultValue={[defaultFrom, defaultTo]}
            disabledDate={date =>
              date < dayjs(activeProgramme.startDate)
              || date > dayjs(activeProgramme.endDate)
            }
            onChange={(dates) => {
              if (!dates) {
                return
              }

              const [newFrom, newTo] = dates
              onFilters([
                {
                  name: 'from',
                  value: newFrom.utc().startOf('day').valueOf(),
                },
                {
                  name: 'to',
                  value: newTo.utc().endOf('day').valueOf(),
                },
              ])
            }}
          />

          <div className="transactions-download-buttons">
            {isAdmin(currentUserRoles) && (
              <Button
                type="primary"
                onClick={handleDownloadPaymentFileClick}
                className="download-payment-details"
              >
                Download payment details
              </Button>
            )}
            <Button
              type="primary"
              onClick={handleDownloadClick}
            >
              Download
            </Button>
          </div>
        </div>
      </TransactionTable>

      <TransactionDetailsModal
        transaction={currentTransaction}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  )
}

export default TransactionList
