import { FC, useCallback, useEffect, useState } from 'react';
import Table from '../../atoms/table';
import EditableImageWithText from '../../molecules/editable-image-with-text';
import { PendingMerchantItem, PendingMerchantItemStatus } from '../../../types/item';
import { ApproveMerchantItemsPayload } from '../../../services/api/merchant.api';
import { Button, Modal, Radio, Space } from 'antd';
import { useSelector } from 'react-redux';
import { CombinedReducers } from '../../../redux/stores/reducers';
import { Programme } from '../../../types/programme';

const MerchantProductsReview: FC<{
  items: PendingMerchantItem[]
  onOk: (approvedItems: any) => Promise<void>
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
}> = ({
  items,
  isModalOpen,
  setIsModalOpen,
  onOk
}) => {
  const [itemsReview, setItemsReview] = useState<{ [itemId: string]: PendingMerchantItemStatus}>({})

  const { activeProgramme } = useSelector<CombinedReducers, { activeProgramme: Programme }>(state => ({ activeProgramme: state.programme.activeProgramme }))

  const onApprove = useCallback((approveAll?: boolean) => {
    const approvedItems: ApproveMerchantItemsPayload[] = approveAll
      ? items.map(item => ({
        id: item.id,
        status: PendingMerchantItemStatus.APPROVED
      }))
      : Object.keys(itemsReview).map(itemId => ({
        id: itemId,
        status: itemsReview[itemId]
      }))
    onOk(approvedItems)
  }, [itemsReview])

  useEffect(() => {
    if (!isModalOpen) {
      setItemsReview({})
    }
  }, [isModalOpen])

  const handleCancel = () => setIsModalOpen(false)

  return (
    <Modal
      title="Review changes"
      open={isModalOpen}
      okText="Submit review"
      onCancel={handleCancel}
      maskClosable={false}
      width="75%"
      destroyOnClose
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          onClick={() => onApprove(true)}
        >
          Approve all
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => onApprove()}
        >
          Submit review
        </Button>,
      ]}
    >
      <Table
        data={items}
        columns={[
          {
            title: 'Item',
            key: 'name',
            dataIndex: 'name',
            width: '35%',
            ellipsis: true,
            render: (text, record) => (
              <EditableImageWithText
                currentImage={record.image}
                title={{ value: text }}
              />
            )
          },
          {
            title: 'Action type',
            key: 'actionType',
            dataIndex: 'actionType',
            align: 'center'
          },
          {
            title: 'Old price',
            key: 'oldPrice',
            align: 'center',
            render: (_, record: PendingMerchantItem) => record.oldPrice ? `${record.oldPrice} ${activeProgramme.currency}` : '—'
          },
          {
            title: 'Price',
            key: 'price',
            dataIndex: 'price',
            align: 'center',
            render: (_, record: PendingMerchantItem) => record.price ? `${record.price} ${activeProgramme.currency}` : '—'
          },
          {
            title: 'Unit',
            key: 'unit',
            dataIndex: 'unit',
            align: 'center',
            render: (_, record: PendingMerchantItem) => `${record.quantity} ${record.unitType}`
          },
          {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record: PendingMerchantItem) => (
              <>
                <Radio.Group
                  onChange={(e) => {
                    setItemsReview({
                      ...itemsReview,
                      [record.id]: e.target.value
                    })
                  }}
                  value={itemsReview[record.id]}
                >
                  <Space
                    direction="vertical"
                    align="start"
                  >
                    <Radio value={PendingMerchantItemStatus.APPROVED}>Approve</Radio>
                    <Radio value={PendingMerchantItemStatus.REJECTED}>Reject</Radio>
                  </Space>
                </Radio.Group>
              </>
            )
          },
        ]}
      />
    </Modal>
  )
}

export default MerchantProductsReview
