import { useState, useEffect } from 'react'

export const useGlobalMessage = (defaultMessage = '') => {
  const [message, setMessage] = useState(defaultMessage)
  const [messageHideTimeout] = useState(5000)

  useEffect(() => {
    setTimeout(() => {
      setMessage('')
    }, messageHideTimeout)
  }, [message, messageHideTimeout])

  return [message, setMessage] as const
}
