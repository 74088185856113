import dayjs, { Dayjs } from 'dayjs';

export const serverDateToString = (date, dateFormat = 'L') => dayjs(date).utc().format(dateFormat)

export const getInvalidRangePickerDates = (date: Dayjs, startDate: string, endDate: string) =>
  date
  < dayjs.max([
    dayjs().startOf('day'),
    dayjs(startDate),
  ]) || date > dayjs(endDate)

