import { ChangeEventHandler, FC, useCallback, useState } from 'react'
import TextInput from '../text-input/index'
import PasswordStrength from './components/password-strength.component'
import './styles.scss'
import Icon from '../icon'
import { IconEye, IconEyeOff } from '@tabler/icons-react'

const PasswordInput: FC<{
  value?: string,
  label?: string,
  error?: string,
  placeholder?: string,
  strengthCheck?: boolean,
  strengthCheckAlign?: string,
  handleChange?: ChangeEventHandler<HTMLInputElement>
  onValidityChange?: (validity: boolean) => void,
}> = ({
  value,
  label,
  error,
  placeholder,
  strengthCheck,
  strengthCheckAlign = 'top',
  handleChange,
  onValidityChange,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const forwardValidity = useCallback(
    (validity) => {
      if (strengthCheck) {
        onValidityChange(validity)
      }
    },
    [onValidityChange, strengthCheck]
  )

  return (
    <div className="password-input-wrapper">
      {strengthCheck && strengthCheckAlign === 'top' && (
        <PasswordStrength
          value={value}
          onChange={forwardValidity}
        />
      )}
      <TextInput
        type={isVisible ? 'text' : 'password'}
        value={value || ''}
        label={label || 'Password'}
        className={`password-input check-${strengthCheckAlign}`}
        error={error || ''}
        icon={<Icon component={isVisible ? IconEye : IconEyeOff} />}
        iconAction={() => setIsVisible(!isVisible)}
        placeholder={placeholder}
        handleChange={handleChange}
      />
      {strengthCheck && strengthCheckAlign === 'bottom' && (
        <PasswordStrength
          value={value}
          onChange={forwardValidity}
        />
      )}
    </div>
  )
}

export default PasswordInput
