import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs, { Dayjs } from 'dayjs'
import {
  Button,
  Form,
  InputNumber,
  Input,
  Radio,
  DatePicker,
  Typography,
  message,
} from 'antd'
import './styles.scss'
import SelectUser from '../../atoms/select-user'
import { addVoucherBatch, AddVoucherBatchPayload } from '../../../services/api/voucher.api'
import { getVoucherNumberOfRenewals } from '../../../services/helpers/helpers'
import { getInvalidRangePickerDates } from '../../../services/helpers/date'
import { getFieldworkerPickerOptions } from '../../atoms/select-user/optionsMappers'
import { apiRequestDateFormat, rangePickerDateFormat } from '../../../constants/general'
import { getProgrammeDetails } from '../../../services/api/programme.api';
import { voucherBatchRenewalTypes } from '../../../constants/voucherBatch.constants'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { Programme } from '../../../types/programme'
import { BaseOptionType } from 'antd/es/select'
import { VoucherBatchRenewalTypes } from '../../../types/voucher'

const { Text } = Typography
const { RangePicker } = DatePicker
const { TextArea } = Input

const formValidators = {
  isPositive: (_, value) =>
    value > 0
      ? Promise.resolve()
      : Promise.reject(
        new Error('The certificate value should be greater than zero.')
      ),
  isMoreThanOne: (_, value) =>
    value >= 1
      ? Promise.resolve()
      : Promise.reject(
        new Error('The number of certificates should be at least one.')
      ),
  isInteger: (value, errorMessage) =>
    Number.isInteger(value)
      ? Promise.resolve()
      : Promise.reject(new Error(errorMessage)),
}

type FormValues = {
  fieldworker: BaseOptionType,
  fieldworkerMessage: string,
  isRenewable: boolean,
  merchantMessage: string,
  number: number,
  renewalType?: VoucherBatchRenewalTypes,
  totalValue: number
  validityPeriod: Dayjs[],
  voucherValue: number,
}

const CreateVouchersForm = () => {
  const [form] = Form.useForm()
  const isRenewable = Form.useWatch('isRenewable', form)
  const voucherValue = Form.useWatch('voucherValue', form)
  const numberOfCertificates = Form.useWatch('number', form)
  const validityPeriod = Form.useWatch('validityPeriod', form)
  const totalValue = Form.useWatch('totalValue', form)
  const monthlyValue = Form.useWatch('monthlyValue', form)

  const [programmeCurrency, setProgrammeCurrency] = useState('')
  const [programmeRemainingValue, setProgrammeRemainingValue] = useState(null)
  const activeProgramme = useSelector<CombinedReducers, Programme>(state => state.programme.activeProgramme)

  const { viewContext } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (activeProgramme) {
      const { currency } = activeProgramme
      setProgrammeCurrency(currency)

      getProgrammeDetails(activeProgramme.id)
        .then((programmeDetailsRes) => {
          if (programmeDetailsRes.hasErrors) {
            message.error('Failed to get programme data')
            return
          }
          setProgrammeRemainingValue(programmeDetailsRes.vouchersSummary.remainingValueAfterRenewals)
        })
    }
  }, [activeProgramme])

  useEffect(() => {
    const monthlyValue = voucherValue * numberOfCertificates
    let numberOfRenewals = 0
    if (isRenewable) {
      numberOfRenewals = validityPeriod
        ? getVoucherNumberOfRenewals(validityPeriod[1].toDate(), validityPeriod[0].toDate())
        : getVoucherNumberOfRenewals(new Date(activeProgramme.endDate))
    }

    form.setFieldValue('monthlyValue', monthlyValue)
    form.setFieldValue('totalValue', monthlyValue * (numberOfRenewals + 1))

    form.validateFields(['totalValue'])
  },
  [activeProgramme, form, isRenewable, numberOfCertificates, validityPeriod, voucherValue])

  const onSubmitForm = useCallback(async ({
    fieldworker,
    fieldworkerMessage,
    isRenewable,
    merchantMessage,
    number,
    renewalType,
    totalValue,
    validityPeriod,
    voucherValue,
  }: FormValues) => {
    let payload: AddVoucherBatchPayload = {
      fieldworkerId: fieldworker.value,
      isRenewable,
      number,
      fieldworkerMessage,
      merchantMessage,
      totalValue,
      fixedValue: voucherValue,
    }
    if (isRenewable) {
      payload.renewalType = renewalType
    }

    if (validityPeriod?.length > 0) {
      payload = {
        ...payload,
        startDate: dayjs(validityPeriod[0]).format(apiRequestDateFormat),
        endDate: dayjs(validityPeriod[1]).format(apiRequestDateFormat),
      }
    }

    const addVoucherBatchRes = await addVoucherBatch(activeProgramme.id, payload)
    if (addVoucherBatchRes.hasErrors) {
      message.error('Failed to create the certificates batch.')
      return
    }
    navigate(`/${viewContext}/certificates`, {
      state: {
        message: {
          type: 'success',
          text: 'Successfully created the certificates batch',
        },
      },
    })
  },
  [activeProgramme, navigate, viewContext]
  )

  const fetchFieldworkers = useCallback((searchText: string) =>
    activeProgramme?.id
    && getFieldworkerPickerOptions(searchText, activeProgramme.id),
  [activeProgramme])

  return (
    <div className="voucher-form__wrapper">
      <Form
        form={form}
        name="createVoucherBatch"
        autoComplete="on"
        initialValues={{
          isRenewable: false,
        }}
        onFinish={onSubmitForm}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        labelWrap
        size="large"
      >
        <Form.Item
          label="Certificates type"
          name="isRenewable"
          rules={[
            {
              required: true,
              message: 'Please select a certificate type.',
            },
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            options={[
              {
                label: 'Non-renewable',
                value: false,
              },
              {
                label: 'Renewable',
                value: true,
              },
            ]}
          />
        </Form.Item>
        {form.getFieldValue('isRenewable') && (
          <>
            <Form.Item
              label="Renewal type"
              name="renewalType"
              initialValue={voucherBatchRenewalTypes.monthly.key}
              rules={[
                {
                  required: true,
                  message: 'Please select a renewal type.',
                },
              ]}
              tooltip={(
                <div className="renewal-type__tooltip">
                  <p><strong>Reset</strong>: Reset the allocation on 1st of every month to the original value.</p>
                  <p><strong>Top-up</strong>: Add the original value on top of the remaining amount from previous month(s).</p>
                </div>
              )}
            >
              <Radio.Group
                buttonStyle="solid"
                optionType="button"
                options={[
                  {
                    label: voucherBatchRenewalTypes.monthly.label,
                    value: voucherBatchRenewalTypes.monthly.key,
                  },
                  {
                    label: voucherBatchRenewalTypes.topUp.label,
                    value: voucherBatchRenewalTypes.topUp.key,
                  },
                ]}
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          label="Assign to fieldworker"
          name="fieldworker"
          rules={[
            {
              required: true,
              message: 'Please select a fieldworker.',
            },
          ]}
        >
          <SelectUser
            fetchOptions={fetchFieldworkers}
          />
        </Form.Item>

        <Form.Item
          label="Validity period"
          name="validityPeriod"
        >
          <RangePicker
            format={rangePickerDateFormat}
            disabledDate={date => getInvalidRangePickerDates(date, activeProgramme.startDate, activeProgramme.endDate)}
          />
        </Form.Item>

        <Form.Item
          label="Certificate value"
          name="voucherValue"
          rules={[
            {
              required: true,
              message: 'Please specify the certificate value.',
            },
            {
              validator: formValidators.isPositive,
            },
            {
              validator: (_, value) =>
                formValidators.isInteger(
                  value,
                  'The certificate value should be an integer.'
                ),
            },
          ]}
        >
          <InputNumber
            min={0}
            addonAfter={
              <>
                <b>{programmeCurrency}</b> {isRenewable === true && 'per month'}
              </>
            }
          />
        </Form.Item>

        <Form.Item
          label="Number of Certificates"
          name="number"
          wrapperCol={{ span: 14 }}
          rules={[
            {
              required: true,
              message:
                'Please specify how many certificates the batch should contain.',
            },
            {
              validator: formValidators.isMoreThanOne,
            },
            {
              validator: (_, value) =>
                formValidators.isInteger(
                  value,
                  'The number of certificates should be an integer.'
                ),
            },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>

        {isRenewable === true && (
          <Form.Item
            label="Monthly value"
            name="monthlyValue"
          >
            <Text
              className="ant-form-text"
              strong
            >
              {monthlyValue || '-'} {programmeCurrency}
            </Text>
          </Form.Item>
        )}

        <Form.Item
          label="Total Batch Value"
          name="totalValue"
          rules={[
            {
              validator: (_, value) => value > programmeRemainingValue
                ? Promise.reject(new Error(`The total value of the certificate batch is larger than the available funds (${programmeRemainingValue} ${programmeCurrency}).`))
                : Promise.resolve()
            },
          ]}
        >
          <Text
            className="ant-form-text"
            strong
          >
            {totalValue || '-'} {programmeCurrency}
          </Text>
        </Form.Item>

        <Form.Item
          label="Message for Fieldworker (max. 120 characters)"
          name="fieldworkerMessage"
          rules={[
            {
              required: false,
              max: 120,
              message: 'The message can only be 120 characters long.',
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Message for Supplier (max. 120 characters)"
          name="merchantMessage"
          rules={[
            {
              required: false,
              max: 120,
              message: 'The message can only be 120 characters long.',
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <div className="form-buttons">
          <Form.Item
            wrapperCol={{
              offset: 10,
              span: 14,
            }}
          >
            <Button
              type="default"
              onClick={() => navigate(`/${viewContext}/certificates`)}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default CreateVouchersForm
