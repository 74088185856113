import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconSearch } from '@tabler/icons-react'
import { Button, message } from 'antd'
import './styles.scss'
import ProgrammeTable from '../../molecules/programme-table/index'
import Card from '../../atoms/card'
import TextInput from '../../atoms/text-input'
import Icon from '../../atoms/icon'

const ProgrammesList = () => {
  const [search, setSearch] = useState('')
  const [searchValue] = useDebounce(search, 500)
  const [searchQuery, setSearchQuery] = useState('')
  const messageData = useLocation().state?.message
  const navigate = useNavigate()

  useEffect(() => {
    if (messageData) {
      message[messageData.type](messageData.text)
    }
  }, [messageData])

  useEffect(() => {
    setSearchQuery(searchValue)
  }, [searchValue])

  return (
    <div className="programme-list">
      <div className="programmes-actions">
        <div className="search-wrapper">
          <TextInput
            type="text"
            label=""
            className="borderless"
            value={search}
            placeholder="search"
            icon={<Icon component={IconSearch} />}
            handleChange={e => setSearch(e.target.value)}
          />
        </div>
        <div className="create-programme-wrapper">
          <Button
            size="large"
            type="primary"
            onClick={() => navigate('create')}
          >
            Create New Programme
          </Button>
        </div>
      </div>
      <Card>
        <ProgrammeTable
          searchQuery={searchQuery}
        />
      </Card>
    </div>
  )
}

export default ProgrammesList
