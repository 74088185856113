import { FC, useEffect, useState } from 'react'
import { Alert, Table } from 'antd'
import { IconCheck } from '@tabler/icons-react'
import Spinner from '../../atoms/spinner'
import Icon from '../../atoms/icon'
import { merchantStatuses } from '../../../constants/merchant.constants'
import { getProgrammeMerchants } from '../../../services/api/merchant.api'
import { Merchant } from '../../../types/user'

const SelectMerchantTable: FC<{
  currentMerchant: Merchant
  programmeId: string
  selectedMerchantId: string
  setSelectedMerchantId: (value: string) => void
  noMerchantsMessage: string
}> = ({
  currentMerchant,
  programmeId,
  selectedMerchantId,
  setSelectedMerchantId,
  noMerchantsMessage
}) => {
  const [merchants, setMerchants] = useState([])
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    getProgrammeMerchants(programmeId, { status: merchantStatuses.approved.key })
      .then((programmeMerchantsRes) => {
        setMerchants(programmeMerchantsRes.merchants.filter(merchant => merchant.id !== currentMerchant?.id))
        setLoading(false)
      })
  }, [currentMerchant, programmeId])

  if (isLoading) {
    return (<Spinner />)
  }

  if (merchants.length === 0) {
    return (
      <Alert
        message={noMerchantsMessage}
        type="warning"
      />
    )
  }

  return (
    <Table
      className="select-merchant-table"
      pagination={false}
      dataSource={merchants}
      rowClassName={record => record.id === selectedMerchantId && 'selected-merchant'}
      rowKey="id"
      onRow={row => ({
        onClick: () => setSelectedMerchantId(row.id)
      })}
      columns={[
        {
          title: '',
          key: 'selected',
          align: 'center',
          render: (_, record) => (
            <Icon
              component={IconCheck}
              componentProps={{
                height: '1rem',
                visibility: record.id === selectedMerchantId ? 'visible' : 'hidden'
              }}
            />
          )

        },
        {
          title: 'Business name',
          key: 'businessName',
          dataIndex: 'name'
        },
        {
          title: 'Owner',
          key: 'owner',
          dataIndex: 'owner',
        },
        {
          title: 'Address',
          key: 'address',
          dataIndex: 'address',
        }
      ]}
    />
  )
}

export default SelectMerchantTable
