import { message } from 'antd'
import { FC, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { viewContexts } from '../../../constants/app.constants'
import { UserRoles } from '../../../constants/userRoles.constants'
import { CombinedReducers } from '../../../redux/stores/reducers'
import { getProgrammeDetails as getProgrammeDetailsReq } from '../../../services/api/programme.api'
import { isAdmin } from '../../../services/helpers/user'
import { Programme } from '../../../types/programme'
import PageBackLink from '../../atoms/page-back-link'
import Tabs from '../../atoms/tabs'
import ProgrammeDetailsForm from '../../organisms/programme-details-form'
import ItemsManagement from '../../organisms/programme-items-management'
import TransactionList from '../../organisms/programme-transaction-list'
import PaymentDetails from './payment-details'

const viewTypes = {
  admin: 'admin',
  manager: 'manager',
}

export const ProgrammeDetailsContext = createContext<{ programme: Programme, updateProgrammeData:() => Promise<void> }>(null)

const ProgrammeDetails: FC = () => {
  const [currentProgramme, setCurrentProgramme] = useState(null)
  const [viewType, setViewType] = useState(viewTypes.manager)
  const { activeProgramme, currentUserRoles } = useSelector<CombinedReducers, { activeProgramme: Programme, currentUserRoles: UserRoles[]}>(state => ({
    activeProgramme: state.programme.activeProgramme,
    currentUserRoles: state.user.userRoles,
  }))
  const { viewContext, programmeId } = useParams()
  const { state } = useLocation()

  const isBackButtonVisible = useMemo(() =>
    isAdmin(currentUserRoles) && viewContext === viewContexts.admin.key && !state?.hideBackButton,
  [currentUserRoles, state, viewContext])

  const getProgrammeDetails = useCallback(async (programmeId) => {
    const programmeDetailsRes = await getProgrammeDetailsReq(programmeId)

    if (programmeDetailsRes.hasErrors) {
      message.error({
        key: 'getProgrammeError',
        content: 'Failed to fetch the programme details',
        duration: 0,
        onClick: () => message.destroy()
      })
      return
    }
    setCurrentProgramme(programmeDetailsRes)
  }, [])

  useEffect(() => {
    if (programmeId || activeProgramme) {
      getProgrammeDetails(programmeId ?? activeProgramme.id).then(() => {
        setViewType(programmeId ? viewTypes.admin : viewTypes.manager)
      })
    }

    return () => {
      message.destroy('getProgrammeError')
    }
  }, [programmeId, activeProgramme, getProgrammeDetails])


  const programmeDetailsTab = {
    title: 'Programme Details',
    component: (
      <ProgrammeDetailsForm
        editable={viewType === viewTypes.admin}
      />
    ),
  }

  const transactionsTab = {
    title: 'Transactions',
    component: <TransactionList programme={currentProgramme} />,
  }

  const itemsTab = {
    title: 'Items',
    component: <ItemsManagement programme={currentProgramme} />,
  }

  const paymentDetailsTab = {
    title: 'Payment Details',
    component: <PaymentDetails programme={currentProgramme} />,
  }

  const adminTabsContent = [
    programmeDetailsTab,
    transactionsTab,
    itemsTab,
    paymentDetailsTab,
  ]
  const pmTabsContent = [programmeDetailsTab, transactionsTab]

  return (
    <div className="programme-details">
      <ProgrammeDetailsContext.Provider
        value={{
          programme: currentProgramme,
          updateProgrammeData: () => getProgrammeDetails(currentProgramme.id)
        }}
      >
        {isBackButtonVisible && (
          <PageBackLink
            url={`/${viewContext}/programmes`}
            pageName="List"
          />
        )}
        <Tabs tabsContent={isAdmin(currentUserRoles) ? adminTabsContent : pmTabsContent}/>
      </ProgrammeDetailsContext.Provider>
    </div>
  )
}

export default ProgrammeDetails
