import { useState, useEffect, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Typography, Button, message } from 'antd'
import './styles.scss'
import { setUserDetails } from '../../../../redux/stores/user/user.actions'
import Spinner from '../../../atoms/spinner'
import { updateUser } from '../../../../services/api/user.api'
import PhoneInput from '../../../atoms/phone-input'
import { CombinedReducers } from '../../../../redux/stores/reducers'
import { BaseUser } from '../../../../types/user'

const UserProfileFormComponent: FC<{ user: BaseUser & {phone?: string} }> = ({ user }) => {
  const userDetails = useSelector<CombinedReducers, BaseUser>(state => state.user.userDetails)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [showSpinner, setShowSpinner] = useState(true)
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      const { firstName, lastName, email, phone } = user
      setFirstName(firstName)
      setLastName(lastName)
      setEmail(email)
      setPhone(phone)
      setShowSpinner(false)
    }
  }, [user])

  const onSubmitForm = async ({ firstName, lastName, phone }) => {
    const payload = {
      firstName,
      lastName,
      phone,
    }
    const update = await updateUser(user.id, payload)
    if (update.hasErrors) {
      message.error('Failed to update the user data')
    }

    const updatedUserDetails = {
      ...userDetails,
      firstName,
      lastName,
      phone,
    }
    dispatch(setUserDetails(updatedUserDetails))

    message.success('Successfully updated the user data')
  }

  return (
    <div className="user-profile-form__wrapper">
      {showSpinner ? (
        <Spinner />
      ) : (
        <Form
          form={form}
          name="updateUserProfile"
          autoComplete="on"
          initialValues={{
            firstName,
            lastName,
            email,
            phone,
          }}
          onFinish={onSubmitForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelWrap
          size="large"
        >
          <Form.Item
            label="First name"
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Please specify the first name.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last name"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please specify the last name.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <PhoneInput />

          <Form.Item label="Email address">
            <Typography.Text
              className="ant-form-text"
              strong
            >
              {email}
            </Typography.Text>
          </Form.Item>

          <div className="form-buttons">
            <Form.Item
              wrapperCol={{
                offset: 10,
                span: 14,
              }}
            >
              <Button
                type="default"
                onClick={() => navigate('/')}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  )
}

export default UserProfileFormComponent
