import { ChangeEventHandler, FC, ReactNode, useMemo } from 'react'
import { Image, Input, Tooltip, Typography, UploadFile, UploadProps } from 'antd'
import './styles.scss'
import ImageUploader from '../image-uploader'
import FormItem from '../../atoms/ant-form-item'
import { useDebouncedCallback } from 'use-debounce'

export const ReadonlySubtitle: FC<{ subtitle: ReactNode }> = ({ subtitle }) =>
  subtitle ? (
    <Tooltip
      title={subtitle}
      overlayStyle={{
        maxWidth: '50vw',
      }}
      placement="topLeft"
    >
      <Typography.Paragraph
        type="secondary"
        ellipsis={{
          rows: 2,
        }}
        className="readonly-subtitle"
      >
        {subtitle}
      </Typography.Paragraph>
    </Tooltip>
  ) : null

export const ReadonlyTitleAndSubtitle: FC<{ title: string, subtitle: ReactNode }> = ({ title, subtitle }) => (
  <section className="text-wrapper">
    <Typography.Text
      ellipsis
      title={title}
    >
      {title}
    </Typography.Text>
    {subtitle && <ReadonlySubtitle subtitle={subtitle} />}
  </section>
)

type EditableImageText = {
  value?: ReactNode,
  formPropertyName?: string,
  formFieldTitle?: string,
  onInputChange?: ChangeEventHandler<HTMLInputElement>
}

const EditableImageWithText: FC<{
  isImageEditable?: boolean,
  isTextEditable?: boolean,
  imageFile?: UploadFile,
  currentImage: string,
  title: EditableImageText,
  subtitle?: EditableImageText,
  beforeUpload?: UploadProps['beforeUpload'],
}> = ({
  isImageEditable,
  isTextEditable,
  imageFile,
  currentImage,
  title,
  subtitle,
  beforeUpload,
}) => {

  const debounced = useDebouncedCallback(
    (e, onChange) => {
      onChange(e);
    },
    500
  );

  const text = useMemo(() => {
    if (!isTextEditable) {
      return (
        <ReadonlyTitleAndSubtitle
          title={title?.value as string}
          subtitle={subtitle?.value}
        />
      )
    }

    if (title?.value && typeof title.value !== 'string') {
      return (
        <div className="text-wrapper">
          {title.value}
        </div>
      )
    }

    return (
      <section className="form-wrapper">
        <FormItem
          propertyName={title.formPropertyName}
          fieldTitle={title.formFieldTitle}
        >
          <Input
            placeholder={title.formFieldTitle}
            onChange={e => debounced(e, title.onInputChange)}
          />
        </FormItem>
        {subtitle?.onInputChange && (
          <FormItem
            propertyName={subtitle.formPropertyName}
            fieldTitle={subtitle.formFieldTitle}
            required={false}
          >
            <Input.TextArea
              rows={3}
              maxLength={1000}
              autoSize={{
                minRows: 1,
                maxRows: 3,
              }}
              onChange={e => debounced(e, subtitle.onInputChange)}
              placeholder={subtitle.formFieldTitle}
            />
          </FormItem>
        )}
      </section>
    )
  }, [debounced, isTextEditable, subtitle, title])

  return (
    <div className="editable-image-with-text">
      {isImageEditable ? (
        <ImageUploader
          beforeUpload={beforeUpload}
          existingImage={currentImage}
          file={imageFile}
        />
      ) : (
        <Image src={currentImage} />
      )}
      {text}
    </div>
  )
}

export default EditableImageWithText
