import { FC, PropsWithChildren } from 'react'
import { Dropdown, Button, Space, MenuProps } from 'antd'
import './styles.scss'
import Icon from '../icon'
import { IconChevronDown } from '@tabler/icons-react'

const DropdownSelector: FC<PropsWithChildren<{
  items: MenuProps['items']
  placeholder: string
  onClick: (nutritionalValueKey: string) => void
}>> = ({ items, placeholder, onClick, children }) => {
  return (
    <Dropdown
      className="dropdown-selector"
      menu={{
        items,
        onClick: ({ key }) => onClick(key)
      }}
      overlayClassName="dropdown-selector__overlay"
    >
      <Button>
        <Space className="button__content" >
          {children || placeholder}
          <Icon
            component={IconChevronDown}
            componentProps={{ height: '1rem' }}
          />
        </Space>
      </Button>
    </Dropdown>
  )
}

export default DropdownSelector
