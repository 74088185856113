import { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { message } from 'antd'
import Tabs from '../../atoms/tabs'
import PageBackLink from '../../atoms/page-back-link'
import UserDetailsContent from '../../organisms/user-details'
import { getUserDetails } from '../../../services/api/user.api'
import { UserRaw } from '../../../types/user'

const UserDetails = () => {
  const [currentUser, setCurrentUser] = useState<UserRaw>(null)
  const { viewContext, userId } = useParams()
  const [query] = useLocation().state || []

  const getCurrentUser = async (userId) => {
    const userDetailsRes = await getUserDetails(userId)

    if (userDetailsRes.hasErrors) {
      message.error('Failed to get the user.')
      return null
    }
    return userDetailsRes
  }

  useEffect(() => {
    if (userId) {
      getCurrentUser(userId).then((res) => {
        setCurrentUser(res)
      })
    }
  }, [userId])

  const tabsContent = [
    {
      title: 'Details',
      component: (
        <UserDetailsContent
          user={currentUser}
        />
      ),
    },
  ]

  return (
    <div className="user-details">
      <PageBackLink
        url={`/${viewContext}/users`}
        pageName="List"
        state={[query]}
      />
      <Tabs tabsContent={tabsContent} />
    </div>
  )
}

export default UserDetails
