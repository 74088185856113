import { useState } from 'react'
import { Button, message } from 'antd'
import { Link, useSearchParams } from 'react-router-dom';
import './styles.scss'
import PasswordInput from '../../atoms/password-input'
import { savePassword } from '../../../services/api/user.api'

const SetNewPassword = () => {
  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isWebUser, setIsWebUser] = useState(false)
  const [searchParams] = useSearchParams()

  const saveNewPassword = async (e) => {
    e.preventDefault()
    if (isValid) {
      const response = await savePassword(searchParams.get('token'), { newPassword: password })
      if (response.hasErrors) {
        return message.error(response.message)
      }
      setIsSuccess(true)
      setIsWebUser(response.isWebUser);
    }
  }

  return (
    <div
      className="set-new-password-form"
      onSubmit={saveNewPassword}
    >
      {isSuccess
        ? (
          <div className="after-message">
            <p>Your new password was saved successfully.</p>
            {
              isWebUser
                ? (
                  <>
                    <p>You may now log into your account.</p>
                    <div className="after-redirect-link">
                      <Link to="/login">Go to Login Page</Link>
                    </div>
                  </>
                )
                : (
                  <p>You may now log into your mobile application account.</p>
                )
            }
          </div>
        )
        : (
          <>
            <p className="form-text">Choose a new password for your account</p>
            <form className="form-elements">
              <PasswordInput
                value={password}
                strengthCheck={true}
                placeholder="Password"
                handleChange={e => setPassword(e.target.value)}
                onValidityChange={setIsValid}
              />

              <Button
                onClick={saveNewPassword}
                disabled={!isValid}
                htmlType="submit"
                type="primary"
                size="large"
              >
                Set Password
              </Button>
            </form>
          </>
        )
      }
    </div>
  )
}

export default SetNewPassword
